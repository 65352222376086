<div class="modal-header">
    <div class="modal-title h4">{{ _config.title | translate }}</div>
    <button class="icon-button">
        <i class="flaticon2-cross" (click)="cancel()"></i>
    </button>
</div>
<div class="modal-body wrap-anywhere">
    <span [innerHTML]="_config.message | translate: _config.messageParams"></span>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-light btn-elevate mr-1" (click)="cancel()">
        {{ "GENERAL.BUTTONS.CANCEL" | translate }}
    </button>
    <button
        type="button"
        class="btn btn-elevate"
        (click)="confirm()"
        [ngClass]="{
            'btn-primary': _config.usage === 'CONFIRM',
            'btn-danger': _config.usage === 'DELETE'
        }"
    >
        {{ "GENERAL.BUTTONS." + (_config.usage || "DELETE") | translate }}
    </button>
</div>
