import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from './auth.service';
import jwt_decode from 'jwt-decode';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Subject, tap } from 'rxjs';
import { OrganizationService } from '../../user-management/_services/organization.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { PermissionNode } from '../../user-management/roles/_models/tree-permission.model';
import { UserEntityRolesModel } from '../_models/user-entity-roles.model';
import { DevelopmentAssistantService } from '../../development-assistant/_services/development-assistant.service';
import { GroupAccessModel } from '../../user-profile/_models/group-access.model';
import { OrganizationAccessModel } from '../../user-profile/_models/organization-access.model';
import { ListModel } from '../../user-management/_models/list.model';
import { ServiceAccessModel } from '../../user-profile/_models/service-access.model';
import { ServiceService } from '../../services/service.service';

const API_PERMISSIONS_URL = environment.apiUrl + '/permissions';
const API_ROLES_URL = environment.apiUrl + '/roles';

@Injectable({
    providedIn: 'root',
})
export class PermissionService implements OnDestroy {
    unsubscribe$ = new Subject<void>();
    _currentPermissions: Permission[] = [];
    currentPermissionsObservable = new BehaviorSubject<void>(undefined);
    organizations: { [id: string]: string } = {};

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * use for setting permissions when viewed entity (organization, group, service)
     * after viewing ends, change to empty array
     */
    set currentPermissions(permissions: Permission[]) {
        this._currentPermissions = permissions;
        this.currentPermissionsObservable.next();
    }

    get currentPermissions() {
        return this._currentPermissions;
    }

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private organizationService: OrganizationService,
        private developmentAssistant: DevelopmentAssistantService,
        private serviceService: ServiceService,
    ) {
        this.authService.currentUser.pipe(
            takeUntil(this.unsubscribe$),
            filter((u) => !!u),
        );
        this.developmentAssistant.loadFromLocalStorage();
    }

    refreshOrganizations() {
        return forkJoin([
            this.organizationService.getOrganizationsList(),
            this.authService.getPermissions(),
        ]).pipe(
            tap(([organizations, permissions]) => {
                organizations.data.forEach((organization) => {
                    this.organizations[organization.id] = organization.name;
                });
                this.authService.allPermissions.next(permissions);
                this.currentPermissionsObservable.next();
            }),
        );
    }

    /**
     * Check permissions for current user, avoid this function if possible, use *ifPerm and *disableLink directives instead
     * @param permission permissions to check, if array - logical OR is applied
     * @param organizationId organization to check permissions for
     * @param groupId group to check permissions for
     * @param ignoreCurrentPerms currentPerms are permissions that user has for current viewed entity (organization, group, service), this will ignore them. Used mainly for checking permissions for router link to organization in service view (user can have Admin role within service with View_Organization perm, but it does not apply for viewing organization, because it's in the service scope)
     */
    hasPermission(
        permission: Permission | Permission[],
        organizationId?: string,
        groupId?: string,
        ignoreCurrentPerms = false,
    ) {
        // fake permissions for development testing
        if (
            environment.ENVIRONMENT_IDENTIFIER &&
            this.developmentAssistant.activeFakePermissionFeature.value
        ) {
            if (permission instanceof Array) {
                return permission.some((p) =>
                    this.hasPermission(p, organizationId, groupId, ignoreCurrentPerms),
                );
            }
            return this.developmentAssistant.fakePermissions.value.includes(permission);
        }

        const currentUser = this.authService.currentUser.value;

        if (permission instanceof Array) {
            return permission.some((p) =>
                this.hasPermission(p, organizationId, groupId, ignoreCurrentPerms),
            );
        }

        // global permission
        let hasPerm = currentUser.permissions?.globalPermissions?.includes(permission);

        // organization permissions are fetch at the app start, so they should be always available
        if (organizationId) {
            hasPerm =
                hasPerm ||
                currentUser.permissions?.organizationsPermissions
                    ?.find((o) => o.organizationId === organizationId)
                    ?.permissions?.includes(permission);
        }
        // group permissions are fetch at the app start, so they should be always available
        if (groupId) {
            hasPerm =
                hasPerm ||
                currentUser.permissions?.groupsPermissions
                    ?.find((p) => p.groupId === groupId)
                    ?.permissions?.includes(permission);
        }
        // check against viewed entity
        if (!ignoreCurrentPerms) {
            hasPerm = hasPerm || this.currentPermissions?.includes(permission);
        }
        return hasPerm;
    }
    public hasAudience(audience: Audience | Audience[]) {
        // TODO: probably it must be checked differently
        // if some of audiences is not found it must return false
        if (Array.isArray(audience)) {
            return audience.some((a) => this.hasUserAudience(a));
        }
        if (typeof audience === 'string') {
            return this.hasUserAudience(audience);
        }
        return false;
    }
    hasUserAudience(audience: Audience) {
        if (!isModuleEnabled(audience)) return false;
        const token = this.authService.getToken();
        const decodedToken = jwt_decode(token);
        // @ts-ignore
        const audienceClaim = decodedToken?.aud;
        if (audienceClaim instanceof Array) {
            return audienceClaim.includes(audience);
        } else {
            return audienceClaim === audience;
        }
    }

    getUserPermissions(serviceId: string) {
        return this.http.get<{ permissions: Permission[] }>(
            `${API_PERMISSIONS_URL}/user/${this.authService.currentUser.value?.id}/${serviceId}`,
            {
                headers: this.authService.getDefaultAuthHeaders(),
            },
        );
    }

    getUserServices(userId: string) {
        return this.http
            .get<ListModel<ServiceAccessModel>>(`${API_PERMISSIONS_URL}/user/${userId}/services`)
            .pipe(
                map((res) => {
                    res.data = res.data.map((s) => {
                        return {
                            ...s,
                            type: this.serviceService.getServiceType(s.serviceId),
                        } as ServiceAccessModel;
                    });
                    return res;
                }),
            );
    }

    getUserGroups(userId: string) {
        return this.http.get<ListModel<GroupAccessModel>>(
            `${API_PERMISSIONS_URL}/user/${userId}/groups`,
        );
    }

    getUserOrganizations(userId: string) {
        return this.http.get<ListModel<OrganizationAccessModel>>(
            `${API_PERMISSIONS_URL}/user/${userId}/organizations`,
        );
    }

    getUserEntityRoles(
        userId: string,
        entityId: string,
        entityType: 'Organization' | 'Group' | 'Service',
    ) {
        return this.http.get<UserEntityRolesModel>(`${API_ROLES_URL}/user/${userId}/${entityId}`, {
            params: { entityType },
        });
    }
}

export type Audience =
    | 'subregConnector'
    | 'proxmoxConnector'
    | 'hostingConnector'
    | 'rmaConnector'
    | 'businessLogic'
    | 'mailhostingConnector'
    | 'dnsConnector'
    | 'billingConnector'
    | 'ticketSystemConnector';

export type Permission =
    | 'Identity_Users_Create'
    | 'Identity_Users_Read'
    | 'Identity_Users_Edit'
    | 'Identity_Users_Delete'
    | 'Identity_Organizations_Create'
    | 'Identity_Organizations_Read_Organization'
    | 'Identity_Organizations_Edit_Organization'
    | 'Identity_Organizations_Delete'
    | 'Identity_Groups_Create'
    | 'Identity_Groups_Read'
    | 'Identity_Groups_Edit_Group'
    | 'Identity_Groups_Delete'
    | 'Identity_Groups_Edit_Users'
    | 'Identity_Groups_Edit_Services'
    | 'Identity_Roles_Read'
    | 'Identity_Roles_Create'
    | 'Identity_Roles_Edit'
    | 'Identity_Roles_Delete'
    | 'Identity_UserRoles_Edit'
    | 'Identity_ShowAccess'
    | 'Identity_Organizations_Edit_Users'
    | 'Identity_Organizations_Read_Users'
    | 'Subreg_Domains_Read'
    | 'Subreg_Domains_Create'
    | 'Subreg_Domains_Edit'
    | 'Subreg_Domains_Transfer'
    | 'Subreg_Domains_SetAutoRenew'
    | 'Subreg_Domains_Renew'
    | 'Subreg_Domains_SendAuthId'
    | 'Subreg_Domains_GetAuthId'
    | 'Subreg_Domains_ChangeOrganization'
    | 'Subreg_Contacts_Read'
    | 'Subreg_Contacts_Create'
    | 'Subreg_Contacts_Edit'
    | 'Subreg_Contacts_Import'
    | 'Subreg_Contacts_Transfer'
    | 'Subreg_Contacts_ChangeOrganization'
    | 'Subreg_CatchDomains_Read'
    | 'Subreg_CatchDomains_Create'
    | 'Subreg_CatchDomains_Delete'
    | 'Subreg_Credit_Read'
    | 'Subreg_CreditAlertLimit_Read'
    | 'Subreg_CreditAlertLimit_Edit'
    | 'Subreg_Nssets_Import'
    | 'Subreg_Nssets_Transfer'
    | 'Subreg_Nssets_Create'
    | 'Subreg_Nssets_Edit'
    | 'Subreg_Orders_Read'
    | 'Hosting_Hostings_Read'
    | 'Hosting_Hostings_Create'
    | 'Hosting_Hostings_Edit'
    | 'Hosting_Logs_Read'
    | 'Hosting_Hostings_Renew'
    | 'Hosting_Hostings_ChangeOrganization'
    | 'Hosting_Backups_Read'
    | 'Hosting_Backups_Restore'
    | 'Hosting_Crontab_Read'
    | 'Hosting_Crontab_Create'
    | 'Hosting_Crontab_Edit'
    | 'Hosting_Crontab_Delete'
    | 'Hosting_Ftp_Read'
    | 'Hosting_Ftp_Create'
    | 'Hosting_Ftp_Edit'
    | 'Hosting_Ftp_Delete'
    | 'Hosting_Mysql_Read'
    | 'Hosting_Mysql_Create'
    | 'Hosting_Mysql_Edit'
    | 'Hosting_Mysql_Delete'
    | 'Hosting_Ssh_Read'
    | 'Hosting_Ssh_Create'
    | 'Hosting_Ssh_Delete'
    | 'Hosting_Ssl_Read'
    | 'Hosting_Ssl_Create'
    | 'Hosting_WebAccess_Read'
    | 'Hosting_WebAccess_Create'
    | 'Hosting_WebAccess_Delete'
    | 'Hosting_WebAccess_Users_Create'
    | 'Hosting_WebAccess_Users_Delete'
    | 'Mailhosting_Domains_Read'
    | 'Mailhosting_Domains_Create'
    | 'Mailhosting_Domains_Edit'
    | 'Mailhosting_Domains_Delete'
    | 'Mailhosting_Mailboxes_Read'
    | 'Mailhosting_Mailboxes_Create'
    | 'Mailhosting_Mailboxes_Edit'
    | 'Mailhosting_Mailboxes_Delete'
    | 'Mailhosting_Aliases_Read'
    | 'Mailhosting_Aliases_Create'
    | 'Mailhosting_Aliases_Delete'
    | 'Mailhosting_Forwards_Read'
    | 'Mailhosting_Forwards_Create'
    | 'Mailhosting_Forwards_Edit'
    | 'Mailhosting_Forwards_Delete'
    | 'Mailhosting_Domains_ChangeOrganization'
    | 'Proxmox_Vms_Read'
    | 'Proxmox_Vms_Create'
    | 'Proxmox_Vms_Edit'
    | 'Proxmox_Vms_Delete'
    | 'Proxmox_Vms_Power'
    | 'Proxmox_Vms_Console'
    | 'Proxmox_Backups_Read'
    | 'Proxmox_Backups_Create'
    | 'Proxmox_Backups_Restore'
    | 'Proxmox_Tasks_Read'
    | 'Proxmox_Vms_ChangeOrganization'
    | 'Proxmox_Vms_CreateAdmin'
    | 'BusinessLogic_Currencies_Create'
    | 'BusinessLogic_Currencies_Edit'
    | 'BusinessLogic_Currencies_Delete'
    | 'BusinessLogic_Countries_Create'
    | 'BusinessLogic_Countries_Edit'
    | 'BusinessLogic_Countries_Delete'
    | 'EmailSender_SendAnyEmail'
    | 'EmailSender_SendServiceRequest'
    | 'Dns_Zones_Read'
    | 'Dns_Zones_Create'
    | 'Dns_Zones_Delete'
    | 'Dns_Zones_ChangeOrganization'
    | 'Dns_Records_Read'
    | 'Dns_Records_Create'
    | 'Dns_Records_Edit'
    | 'Dns_Records_Delete'
    | 'Billing_Invoices_Read'
    | 'TicketSystem_Tickets_Read'
    | 'TicketSystem_Tickets_Create'
    | 'TicketSystem_DetailSpecifications_Create'
    | 'TicketSystem_DetailSpecifications_Read'
    | 'TicketSystem_Documents_Create'
    | 'TicketSystem_Documents_Read';

export const mergeDeep = (target, source) => {
    const isObject = (obj) => obj && typeof obj === 'object';

    if (!isObject(target) || !isObject(source)) {
        return source;
    }

    Object.keys(source).forEach((key) => {
        const targetValue = target[key];
        const sourceValue = source[key];

        if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
            target[key] = targetValue.concat(sourceValue);
        } else if (isObject(targetValue) && isObject(sourceValue)) {
            target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
        } else {
            target[key] = sourceValue;
        }
    });

    return target;
};

export const permissionsToGrid = (permissions: string[], filter?: string[]) => {
    let grid = {};

    permissions.forEach((permission) => {
        const subPermissions = permission.split('_');
        let result = {};
        if (filter) {
            filter.forEach((f) => {
                if (subPermissions[0] === f || permission === f) {
                    result = permissionToTree(subPermissions, permission);
                }
            });
        } else {
            result = permissionToTree(subPermissions, permission);
        }
        grid = mergeDeep(grid, result);
    });

    return grid;
};

const permissionToTree = (arr: string[], permission: string) =>
    arr.reverse().reduce((res, key) => ({ [key]: res }), permission ? permission : {});

const transformObjToArr = (object) => {
    return Object.entries(object).map(([key, value]) => {
        let tempObj = {
            name: key,
        };

        if (value && typeof value === 'string') {
            Object.assign(tempObj, {
                id: value,
            });
        }
        if (value && typeof value === 'object') {
            let child = transformObjToArr(value);
            if (child.length > 0) {
                Object.assign(tempObj, {
                    children: child,
                });
            } else {
                Object.assign(tempObj, { id: object });
            }
        }
        return tempObj;
    });
};

export const transformPermissionsToTree = (
    permissions: string[],
    filter?: string[],
): PermissionNode[] => {
    const grid = permissionsToGrid(permissions, filter);
    return transformObjToArr(grid);
};

function isModuleEnabled(audience: Audience) {
    /** Check if module is active */
    const enabledModules = environment.ENABLED_MODULES;
    return enabledModules && enabledModules.includes(audience);
}
