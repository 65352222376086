import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputWrapperService } from '../../_services/input-wrapper.service';

@Component({
    selector: 'app-input-wrapper',
    templateUrl: './input-wrapper.component.html',
    styleUrls: ['./input-wrapper.component.scss'],
})
export class InputWrapperComponent implements OnInit {
    /** Input for any FormControl*/
    @Input() inputControl: FormControl;
    /** Input label */
    @Input() label: string;
    /** Wide change input behavior on big screens */
    @Input() wide: boolean = false;
    /** Angular [style] input fot label */
    @Input() labelStyle: Record<string, string | undefined | null>;
    /**Class inputs */
    @Input() labelClass: string;
    /** Hide error message, remove input bottom margin */
    @Input() hideErrorMessages: boolean = false;

    constructor(public iws: InputWrapperService) {}

    ngOnInit(): void {}
}
