import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../loading-bar/loading.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private totalRequests = 0;

    constructor(private loadingService: LoadingService) {}

    /**
     * Returns if request should be ignored in loading
     */
    private bypass(request: HttpRequest<any>): boolean {
        if (
            (request.url.endsWith(environment.hostingConnector + '/hosting') &&
                request.method === 'POST') ||
            (request.url.startsWith(environment.vpsConnector + '/vmStats/getVmBasicSummary/') &&
                request.method === 'GET') ||
            request.url.startsWith(environment.vpsConnector + '/vm/getTasks') ||
            (request.url.startsWith(environment.vpsConnector + '/vm/getPendingValues') &&
                request.method === 'GET')
        ) {
            return true;
        }
        return false;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        // Check if request should be ignored
        if (this.bypass(request)) {
            return next.handle(request);
        }

        // Add request to loading
        this.totalRequests++;
        this.loadingService.setLoading(true);

        return next.handle(request).pipe(
            finalize(() => {
                this.totalRequests--;
                if (this.totalRequests === 0) {
                    this.loadingService.setLoading(false);
                }
            }),
        );
    }
}
