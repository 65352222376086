import { FormControl, ValidationErrors } from '@angular/forms';

export class CheckboxValidator {
    public static isChecked(control: FormControl): ValidationErrors {
        const isChecked = control.value;
        if (!isChecked) {
            return { isChecked: true };
        }
        return null;
    }
}
