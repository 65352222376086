import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, delayWhen, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpParamsEncoder } from './custom-http-encoder';
import { ListModel } from '../../user-management/_models/list.model';
import { RoleModel } from '../_models/role.model';
import { ServicePermissionsTableModel } from '../_models/service-permissions-table.model';
import { UserServiceModel } from '../../user-management/_models/user-service.model';
import { Permission, PermissionService } from './permission.service';
import { PermissionsModel } from '../_models/permissions.model';
import { SortLimitModel } from '../../user-management/_models/sort-limit.model';

const API_AUTH_URL = `${environment.apiUrl}/auth`;
const API_OIDC_URL = `${environment.apiUrl}/oidc`;
const API_SETTINGS_URL = `${environment.apiUrl}/userSettings`;
const API_ROLES_URL = environment.apiUrl + '/roles';
const API_PERMISSIONS_URL = environment.apiUrl + '/permissions';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    /** Name of local storage item for token */
    private authLocalStorageToken = `${environment.BUILD_ID}-${environment.USERDATA_KEY}`;
    /** Stores currently logged user */
    currentUser = new BehaviorSubject<UserModel>(undefined);
    allPermissions = new BehaviorSubject<PermissionsModel>(undefined);

    constructor(private router: Router, private http: HttpClient, private injector: Injector) {}

    /** Login user */
    login(email: string, password: string, isTokenRefresh: boolean = false): Observable<UserModel> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
        });
        let body;
        if (!isTokenRefresh) {
            body = new HttpParams({
                encoder: new CustomHttpParamsEncoder(),
                fromObject: {
                    grant_type: 'password',
                    username: email,
                    password,
                    scope: 'offline_access',
                },
            });
        } else {
            body = new HttpParams({
                encoder: new CustomHttpParamsEncoder(),
                fromObject: {
                    grant_type: 'refresh_token',
                    refresh_token: this.getAuthFromLocalStorage().refreshToken,
                },
            });
            // console.log(
            //     'refreshing token with body',
            //     body,
            //     this.getAuthFromLocalStorage().refreshToken,
            // );
        }
        return this.http.post<any>(`${API_OIDC_URL}/token`, body.toString(), { headers }).pipe(
            map((response) => {
                const token = response.access_token;
                const refreshToken = response.refresh_token;
                delete response.access_token;
                delete response.refresh_token;
                const auth = response as AuthModel;
                auth.token = token;
                auth.refreshToken = refreshToken;
                return this.setAuthFromLocalStorage(auth);
            }),
            switchMap(() => this.getCurrentlyLoggedUser()),
        );
    }

    /** Refreshes token */
    refreshToken(): Observable<any> {
        return this.login(undefined, undefined, true);
    }

    /** Logouts logged user, removes AuthModel from local storage and redirects to login page */
    logout(tokenExpired: boolean = false) {
        localStorage.removeItem(this.authLocalStorageToken);
        this.currentUser.next(undefined);
        let returnUrl = this.router.url;
        if (returnUrl === '/auth/logout') {
            returnUrl = undefined;
        }
        this.router.navigate(['/auth/login'], {
            queryParams: { returnUrl, tokenExpired: tokenExpired ? true : undefined },
        });
    }

    /** Returns currently logged user and set him to currentUser subject */
    getCurrentlyLoggedUser(): Observable<UserModel> {
        const auth = this.getAuthFromLocalStorage();
        if (!auth || !auth.token) {
            return of(undefined);
        }
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${auth.token}`,
        });
        return this.http
            .get<UserModel>(`${environment.apiUrl}/users/current`, { headers: httpHeaders })
            .pipe(
                delayWhen(() => this.injector.get(PermissionService).refreshOrganizations()),
                tap((user: UserModel) => {
                    if (user) {
                        this.currentUser.next(user);
                    } else {
                        this.logout();
                    }
                }),
                catchError((err) => {
                    localStorage.removeItem(this.authLocalStorageToken);
                    return throwError(err);
                }),
            );
    }

    register(user: UserModel): Observable<any> {
        const body = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password,
            confirmPassword: user.password,
        };
        return this.http.post<any>(`${API_AUTH_URL}/register`, body);
    }

    forgotPassword(email: string): Observable<any> {
        const body = {
            email,
        };
        return this.http.post<any>(`${API_AUTH_URL}/forgotPassword`, body);
    }

    resetPassword(
        userId: string,
        code: string,
        password: string,
        confirmPassword: string,
    ): Observable<any> {
        const body = {
            userId,
            code,
            password,
            confirmPassword,
        };
        return this.http.post<any>(`${API_AUTH_URL}/resetPassword`, body);
    }

    resendVerificationEmail(email: string): Observable<any> {
        const body = {
            email,
        };
        return this.http.post<any>(`${API_AUTH_URL}/resendVerificationEmail`, body);
    }

    confirmEmail(userId: string, code: string): Observable<any> {
        const body = {
            userId,
            code,
        };
        return this.http.post<any>(`${API_AUTH_URL}/confirmEmail`, body);
    }

    /** Writes AuthModel to local storage */
    private setAuthFromLocalStorage(auth: AuthModel): boolean {
        if (auth && auth.token) {
            localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
            return true;
        }
        return false;
    }

    /** Gets AuthModel from local storage */
    private getAuthFromLocalStorage(): AuthModel {
        try {
            return JSON.parse(localStorage.getItem(this.authLocalStorageToken));
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    /** Returns auth token of logged user */
    public getToken(): string {
        return this.getAuthFromLocalStorage()?.token;
    }

    getRoles(
        type: 'Organization' | 'Global' | null,
        organizationId?: string,
        sortLimitModel?: SortLimitModel,
    ) {
        const params = { type, organizationId, ...sortLimitModel };

        return this.http.get<ListModel<RoleModel>>(`${API_ROLES_URL}`, {
            params,
            headers: this.getDefaultAuthHeaders(),
        });
    }

    getPermissions() {
        return this.http
            .get<PermissionsModel>(API_PERMISSIONS_URL, {
                headers: this.getDefaultAuthHeaders(),
            })
            .pipe(
                tap((permissions) => {
                    this.allPermissions.next(permissions);
                }),
            );
    }

    getServicePermissions(serviceId: string) {
        return this.http.get<ListModel<ServicePermissionsTableModel>>(
            `${API_PERMISSIONS_URL}/services/${serviceId}`,
            {
                headers: this.getDefaultAuthHeaders(),
            },
        );
    }

    getUserServicePermissions(userId: string, serviceId: string) {
        return this.http.get<UserServiceModel>(
            `${API_PERMISSIONS_URL}/services/${serviceId}/${userId}`,
            {
                headers: this.getDefaultAuthHeaders(),
            },
        );
    }

    getUserPermissions(serviceId: string) {
        return this.http.get<{ permissions: Permission[] }>(
            `${API_PERMISSIONS_URL}/user/${this.currentUser.value?.id}/${serviceId}`,
            {
                headers: this.getDefaultAuthHeaders(),
            },
        );
    }

    createRole(role: RoleModel) {
        return this.http.post<RoleModel>(API_ROLES_URL, role, {
            headers: this.getDefaultAuthHeaders(),
        });
    }

    deleteRole(roleId: string) {
        return this.http.delete<RoleModel>(`${API_ROLES_URL}/${roleId}`, {
            headers: this.getDefaultAuthHeaders(),
        });
    }

    updateRole(role: RoleModel) {
        return this.http.put<RoleModel>(`${API_ROLES_URL}/${role.id}`, role, {
            headers: this.getDefaultAuthHeaders(),
        });
    }

    getRole(roleId: string) {
        return this.http.get<RoleModel>(`${API_ROLES_URL}/${roleId}`);
    }

    /**
     * Returns default HTTP headers with authorization
     */
    public getDefaultAuthHeaders(): HttpHeaders {
        return new HttpHeaders({
            accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.getToken(),
        });
    }
    public getAuthHeader() {
        return {
            key: 'Authorization',
            value: 'Bearer ' + this.getToken(),
        };
    }
}
