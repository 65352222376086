import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IfPermDirective } from './if-perm.directive';

@NgModule({
    declarations: [IfPermDirective],
    exports: [IfPermDirective],
    imports: [CommonModule],
})
export class DirectivesModule {}
