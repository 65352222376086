import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements OnInit {
    /**Input variant */
    @Input() variant: 'default' | 'solid' = 'default';
    /** Placeholder changes input placeholder*/
    @Input() placeholder: string = '';
    /** wide change input behavior on big screens */
    @Input() wide: boolean = false;
    /** inputControl */
    @Input() inputControl: FormControl;
    /** Label for input */
    @Input() label: string = '';
    /** Disable input */
    @Input() disabled: boolean = false;
    /** Disable whitespace validator */
    @Input() disableWhitespaceValidator: boolean = false;
    /**Show password on default  */
    @Input() showPassword: boolean = false;

    /** Disable Autocompletion
     * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
     */
    @Input() autocomplete: string | null | 'off' | 'new-password' = null;
    /**Styling input using angular [style] */
    @Input() inputStyle: Record<string, string | undefined | null>;
    @Input() labelStyle: Record<string, string | undefined | null>;
    /**Class inputs for customization  */
    @Input() inputClass: 'string';
    @Input() labelClass: 'string';

    /** Optional bottom content */
    @ContentChild('bottomContent', { read: TemplateRef }) bottomContentPassword: TemplateRef<any>;

    type: HTMLInputElement['type'] = 'password';
    constructor() {}

    ngOnInit(): void {
        if (this.showPassword) {
            this.type = 'text';
        }
    }

    togglePasswordVisibility(): void {
        this.type = this.type === 'password' ? 'text' : 'password';
    }
}
