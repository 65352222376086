import { Directive, HostBinding, Input } from '@angular/core';
import { Permission, PermissionService } from '../auth/_services/permission.service';

/**
 * Directive to disable a router link if the user does not have the required permission.
 */
@Directive({
    selector: '[appDisableLink]',
    standalone: true,
})
export class DisableLinkDirective {
    constructor(private permissionService: PermissionService) {}

    @Input() appDisableLinkOrganizationId: string;
    @Input() appDisableLinkIgnoreCurrentPermissions: boolean;
    @Input() appDisableLinkGroupId: string;
    permission: Permission | Permission[];

    @HostBinding('class')
    get elementClass() {
        if (!this.permission) {
            return;
        }
        return this.permissionService.hasPermission(
            this.permission,
            this.appDisableLinkOrganizationId,
            this.appDisableLinkGroupId,
            this.appDisableLinkIgnoreCurrentPermissions,
        )
            ? ''
            : 'disabled-router-link';
    }
    @Input()
    set appDisableLink(val: Permission | Permission[]) {
        this.permission = val;
    }
}
