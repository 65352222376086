<div class="separator separator-solid mb-4"></div>
<app-text-input
    [inputControl]="searchControl"
    [placeholder]="'COMPONENTS.MODALS.SELECT_ROLES.SEARCHING'"
    class="w-100"
    [hideErrorMessages]="true"
    [disableWhitespaceValidator]="true"
>
    <ng-template appInnerIcon>
        <i class="flaticon2-search-1 icon-md"></i>
    </ng-template>
</app-text-input>

<div *ngFor="let selection of selectionRoles | searchFilter: searchControl.value; let i = index">
    <div
        class="btn btn-hover-light-primary mr-2 btn-lg d-inline-flex align-items-center p-0 w-100 justify-content-between"
    >
        <label class="pl-4 pr-6 py-3 m-0 cursor-pointer">
            <mat-checkbox
                color="primary"
                [disabled]="selection.disabled"
                [checked]="selection.selected"
                (change)="toggleRole(selection.role)"
            >
            </mat-checkbox>
            <span
                class="label label-inline label-xl ml-4"
                [ngStyle]="{
                    'background-color': '#' + selection.role.color,
                    color: getContrastColor(selection.role.color)
                }"
            >
                {{ selection.role.name }}
            </span>
        </label>
        <button class="btn btn-lg btn-link-primary" (click)="showRole(selection.role)">
            <i class="flaticon-info icon-2x icon-md-lg"></i>
        </button>
    </div>
</div>
