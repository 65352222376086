import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationErrorsService } from '../../validation-errors/validation-errors.service';

@Injectable({
    providedIn: 'root',
})
export class InputWrapperService {
    constructor(private validationErrorsService: ValidationErrorsService) {}

    getControlValue(inputControl: FormControl): any {
        return inputControl.value;
    }
    /** Checks if FormControl is invalid and was touched */
    isControlInvalidAndTouched(inputControl: FormControl): boolean {
        return inputControl.invalid && inputControl.touched;
    }
    /** GetControlErrorMessages return nice errors */
    getControlErrorMessages(inputControl: FormControl): string[] {
        return this.validationErrorsService.getGenericErrorMessages(inputControl);
    }
    /** Return input validator Required */
    isRequired(inputControl: FormControl): boolean {
        return (
            inputControl.validator &&
            inputControl.validator({} as FormControl) &&
            inputControl.validator({} as FormControl)['required']
        );
    }
    /** Return control name*/
    getControlName(inputControl: FormControl): string | null {
        const formGroup = inputControl.parent.controls;
        return Object.keys(formGroup).find((name) => inputControl === formGroup[name]) || null;
    }
}
