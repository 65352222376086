import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Permission, PermissionService } from '../../auth/_services/permission.service';

@Injectable({
    providedIn: 'root',
})
export class DevelopmentAssistantService {
    activeFakePermissionFeature = new BehaviorSubject<boolean>(false);
    fakePermissions = new BehaviorSubject<Permission[]>([]);
    permissionService: PermissionService;

    constructor(private injector: Injector) {}

    setActiveFakePermissionFeature(value: boolean) {
        if (!this.permissionService) {
            this.permissionService = this.injector.get(PermissionService);
        }
        this.activeFakePermissionFeature.next(value);
        this.permissionService.currentPermissionsObservable.next();
    }
    getActiveFakePermissionFeature() {
        return this.activeFakePermissionFeature.asObservable();
    }

    loadFromLocalStorage() {
        const fakePermissions = localStorage.getItem('fakePermissions') || '[]';
        const fakePermissionsActive = localStorage.getItem('fakePermissionsActive') || 'false';
        this.fakePermissions.next(JSON.parse(fakePermissions));
        this.activeFakePermissionFeature.next(JSON.parse(fakePermissionsActive));
    }
}
