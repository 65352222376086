<!-- begin: Registration -->
<div class="login-form login-signup">
    <!-- begin: Form -->
    <form
        class="form"
        novalidate="novalidate"
        id="kt_login_signup_form"
        [formGroup]="registrationForm"
        (ngSubmit)="submit()"
    >
        <!-- begin: Title -->
        <div class="pb-13 pt-lg-0 pt-5">
            <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                {{ "AUTH.REGISTRATION.TITLE" | translate }}
            </h3>
            <p class="text-muted font-weight-bold font-size-h4">
                {{ "AUTH.REGISTRATION.DESC" | translate }}
            </p>
        </div>
        <!-- end: Title -->

        <!-- begin: Alert error -->
        <ng-container *ngIf="errorType !== undefined">
            <div class="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div *ngIf="errorType === 0" class="alert-text">
                    {{ "AUTH.REGISTRATION.VALIDATION.EMAIL_ALREADY_REGISTERED" | translate }}
                </div>
                <div *ngIf="errorType === 1" class="alert-text">
                    {{ "AUTH.REGISTRATION.VALIDATION.REGISTRATION_ERROR" | translate }}
                </div>
            </div>
        </ng-container>
        <!-- end: Alert error -->

        <!-- begin: First name -->
        <app-text-input
            [inputControl]="registrationForm.controls.firstName"
            label="GENERAL.INPUT.FIRST_NAME"
            placeholder="{{ 'GENERAL.INPUT.FIRST_NAME' | translate }}"
            autocomplete="off"
            labelClass="font-size-h6 font-weight-bolder text-dark"
            inputClass="h-auto py-7 px-6 rounded-lg"
            variant="solid"
        >
        </app-text-input>
        <!-- end: First name -->

        <!-- begin: Last name -->
        <app-text-input
            [inputControl]="registrationForm.controls.lastName"
            label="GENERAL.INPUT.LAST_NAME"
            placeholder="{{ 'GENERAL.INPUT.LAST_NAME' | translate }}"
            autocomplete="off"
            labelClass="font-size-h6 font-weight-bolder text-dark"
            inputClass="h-auto py-7 px-6 rounded-lg"
            variant="solid"
        >
        </app-text-input>

        <!-- end: Last name -->

        <!-- begin: Email -->
        <app-text-input
            [inputControl]="registrationForm.controls.email"
            label="GENERAL.INPUT.EMAIL"
            placeholder="{{ 'GENERAL.INPUT.EMAIL' | translate }}"
            autocomplete="off"
            labelClass="font-size-h6 font-weight-bolder text-dark"
            inputClass="h-auto py-7 px-6 rounded-lg"
            variant="solid"
        >
        </app-text-input>
        <!-- end: Email -->

        <!-- begin: Password -->
        <app-text-input
            [inputControl]="registrationForm.controls.password"
            label="GENERAL.INPUT.PASSWORD"
            placeholder="{{ 'GENERAL.INPUT.PASSWORD' | translate }}"
            autocomplete="off"
            type="password"
            labelClass="font-size-h6 font-weight-bolder text-dark"
            inputClass="h-auto py-7 px-6 rounded-lg"
            variant="solid"
        >
        </app-text-input>
        <!-- end: Password -->

        <!-- begin: Confirm Password -->
        <app-text-input
            [inputControl]="registrationForm.controls.cPassword"
            label="GENERAL.INPUT.C_PASSWORD"
            placeholder="{{ 'GENERAL.INPUT.C_PASSWORD' | translate }}"
            autocomplete="off"
            type="password"
            labelClass="font-size-h6 font-weight-bolder text-dark"
            inputClass="h-auto py-7 px-6 rounded-lg"
            variant="solid"
        >
        </app-text-input>
        <!-- end: Confirm Password -->

        <!-- begin: Agree terms and conditions -->

        <app-checkbox-input [inputControl]="registrationForm.controls.agree">
            <ng-template #label>
                {{ "AUTH.REGISTRATION.AGREE_WITH" | translate }}&nbsp;
                <a href="{{ 'GENERAL.LINKS.TERMS_AND_CONDITIONS' | translate }}" target="_blank">{{
                    "AUTH.REGISTRATION.TERMS_AND_CONDITIONS" | translate
                }}</a
                >.
            </ng-template>
        </app-checkbox-input>
        <!-- end: Agree terms and conditions -->

        <!-- begin: Actions -->
        <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
            <div (mouseenter)="checkValid()">
                <button
                    type="submit"
                    id="kt_login_signup_submit"
                    [disabled]="registrationForm.invalid"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                    {{ "AUTH.REGISTRATION.REGISTER_BUTTON" | translate }}
                </button>
            </div>
            <a
                routerLink="/auth/login"
                type="button"
                id="kt_login_signup_cancel"
                class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
            >
                {{ "GENERAL.BUTTONS.CANCEL" | translate }}
            </a>

            <span *ngIf="isLoading | async" class="spinner spinner-primary ml-5"></span>
        </div>
        <!-- end: Actions -->
    </form>
    <!-- end: Form -->
</div>
<!-- end: Registration -->
