import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-checkbox-input',
    templateUrl: './checkbox-input.component.html',
    styleUrls: ['./checkbox-input.component.scss'],
})
export class CheckboxInputComponent implements OnInit {
    /** inputControl */
    @Input() inputControl: FormControl;
    /** Label for input */
    @Input() label: string = '';
    /** Disable input */
    @Input() disabled: boolean = false;
    /** Make inputs more responsive */
    @Input() wide: boolean = false;
    /**Styling input using angular [style] */
    @Input() labelStyle: Record<string, string | undefined | null>;
    /**Class inputs for customization  */
    @Input() labelClass: 'string';
    /**Label template */
    @ContentChild('label', { read: TemplateRef }) labelTemplate: TemplateRef<any>;

    constructor() {}

    ngOnInit(): void {}
}
