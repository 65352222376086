<app-filtering [dataSource]="dataSource" [filteredColumns]="filteredColumns"></app-filtering>
<app-table [dataSource]="dataSource" [columns]="columns">
    <ng-template appCustomCell="name" let-row="row">
        <a
            [appDisableLink]="'Identity_Groups_Read'"
            [appDisableLinkOrganizationId]="row.organizationId"
            [appDisableLinkGroupId]="row.id"
            [routerLink]="'/user-management/edit-group/' + row.id"
            >{{ row.name }}</a
        >
    </ng-template>
    <ng-template appCustomCell="actions" let-row="row">
        <button
            *appIfPerm="'Identity_Groups_Read'"
            class="btn btn-light btn-hover-warning btn-sm btn-icon mr-3"
            [routerLink]="'/user-management/edit-group/' + row.id"
            [ngbTooltip]="'GENERAL.BUTTONS.UPDATE' | translate"
        >
            <i class="flaticon2-edit text-warning"></i>
        </button>
    </ng-template>
</app-table>
