import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmailValidator } from '../../validators/email.validator';
import { AuthService } from '../_services/auth.service';

enum ErrorStates {
    BadRequest,
    CommunicationError,
    BadToken,
    NoError,
    ResendTrue,
    NoEmail,
    Confirmed,
}

@Component({
    selector: 'app-confirm-email',
    templateUrl: './confirm-email.component.html',
    styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements OnInit {
    /** Is something loading, used for loading bar */
    isLoading = new BehaviorSubject(false);
    /** Controls which alert should be shown */
    errorState: ErrorStates = undefined;
    /** Holds enum ErrorStates for use in confirm-email html */
    errorStates = ErrorStates;
    /** Form for sending confirm email */
    confirmEmailForm = new FormGroup({
        email: new FormControl('', EmailValidator.isValid(true)),
    });

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        const userId = this.route.snapshot.queryParamMap.get('uid');
        const emailConfirmToken = this.route.snapshot.queryParamMap.get('code');
        if (userId != null && emailConfirmToken != null) {
            // If userId and code is given in queryParams confirm email
            this.confirmEmail(userId, emailConfirmToken);
        }
    }

    /** Confirms email with confirm token */
    confirmEmail(userId: string, confirmToken: string) {
        this.errorState = ErrorStates.BadRequest;
        this.isLoading.next(true);
        this.authService
            .confirmEmail(userId, confirmToken)
            .pipe(finalize(() => this.isLoading.next(false)))
            .subscribe({
                next: () =>
                    this.router.navigate(['/auth/login'], {
                        queryParams: { emailConfirmed: true },
                    }),
                error: () => (this.errorState = this.errorStates.BadToken),
            });
    }

    /** Submit function, resents verification email */
    resendVerificationEmailSubmit() {
        //TODO: [ ] missing translation
        //TODO: [ ] old subscribe syntax
        const fv = this.confirmEmailForm.value;
        this.errorState = undefined;
        this.isLoading.next(true);
        this.authService
            .resendVerificationEmail(fv.email)
            .pipe(finalize(() => this.isLoading.next(false)))
            .subscribe({
                next: () => (this.errorState = this.errorStates.ResendTrue),
                error: (error) => {
                    switch (error.error[0]) {
                        case 'Uzivatel s timto emailem neexsituje': {
                            this.errorState = this.errorStates.NoEmail;
                            break;
                        }
                        case 'Adress confirmed': {
                            this.errorState = this.errorStates.Confirmed;
                            break;
                        }
                        default: {
                            this.errorState = this.errorStates.CommunicationError;
                        }
                    }
                },
            });
    }
}
