<div class="role-label-group" *ngIf="roles">
    <span
        *ngFor="let role of roles"
        (click)="showRole(role)"
        class="label label-inline label-xl role-label cursor-pointer"
        [ngStyle]="{
            'background-color': '#' + (role.color || '3699FF'),
            color: getContrastColor(role.color)
        }"
    >
        {{ role.name }}
    </span>
</div>
