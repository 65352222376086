import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/_services/auth.service';
import cs from '@angular/common/locales/cs';
// Highlight JS
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import xml from 'highlight.js/lib/languages/xml';
import json from 'highlight.js/lib/languages/json';
import scss from 'highlight.js/lib/languages/scss';
import typescript from 'highlight.js/lib/languages/typescript';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingInterceptor } from './modules/http-interceptor/loading-http-interceptor';
import { ErrorLoggerHttpInterceptor } from './modules/http-interceptor/error-logger-http-interceptor';
import { CancelHttpInterceptor } from './modules/http-interceptor/cancel-http-interceptor';
import { HttpCancelService } from './modules/http-interceptor/http-cancel.service';
import { ModalsModule } from './modules/modals/modals.module';
import { ForbiddenHttpInterceptor } from './modules/http-interceptor/forbidden-http-interceptor';
import { InputWrappersModule } from './modules/forms/input-wrappers.module';
import { DefaultHeadersInterceptor } from './modules/http-interceptor/default-headers.interceptor';
import { BackNavigationService } from './modules/back-button/_services/back-navigation.service';
import { HotToastModule } from '@ngneat/hot-toast';
import { initializeYbug } from './modules/_helpers/ybug';
import { environment } from '../environments/environment';
import { DNS_BASE_URL } from './modules/dns/_services/dns.nswag.service';
import { TICKET_SYSTEM_BASE_URL } from './modules/ticket-system/_services/ticket-system.nswag.service';

registerLocaleData(cs);

function appInitializer(authService: AuthService) {
    return () => {
        return new Promise((resolve: any) => {
            authService
                .getCurrentlyLoggedUser()
                .subscribe((user) => {
                    //Ybug (user feedback) initialization
                    initializeYbug({
                        id: user?.id,
                        email: user?.email ?? '',
                        name:
                            user?.firstName && user?.lastName
                                ? `${user.firstName}' '${user.lastName}`
                                : '',
                    });
                })
                .add(resolve);
        });
    };
}

function backNavigationInitializer(backNavigationService: BackNavigationService) {
    return () => {
        return new Promise((resolve: any) => {
            backNavigationService.init().subscribe().add(resolve);
        });
    };
}

/**
 * Import specific languages to avoid importing everything
 * The following will lazy load highlight.js core script (~9.6KB) + the selected languages bundle (each lang. ~1kb)
 */
export function getHighlightLanguages() {
    return [
        { name: 'typescript', func: typescript },
        { name: 'scss', func: scss },
        { name: 'xml', func: xml },
        { name: 'json', func: json },
    ];
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        SplashScreenModule,
        TranslateModule.forRoot(),
        HttpClientModule,
        HighlightModule,
        ClipboardModule,
        AppRoutingModule,
        InlineSVGModule.forRoot(),
        NgbModule,
        MatSnackBarModule,
        ReactiveFormsModule,
        ModalsModule,
        InputWrappersModule,
        HotToastModule.forRoot(),
    ],
    providers: [
        { provide: DNS_BASE_URL, useValue: environment.basePath },
        // TODO temporary
        { provide: TICKET_SYSTEM_BASE_URL, useValue: 'http://localhost:6006' },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: backNavigationInitializer,
            deps: [BackNavigationService],
            multi: true,
        },
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                languages: getHighlightLanguages,
            },
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorLoggerHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CancelHttpInterceptor,
            multi: true,
            deps: [HttpCancelService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ForbiddenHttpInterceptor,
            multi: true,
            deps: [Router],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DefaultHeadersInterceptor,
            multi: true,
            deps: [AuthService],
        },
        {
            provide: LOCALE_ID,
            useValue: 'cs',
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
