<!-- begin: Rest password -->
<div class="login-form login-signup">
    <!-- begin: Form -->
    <form
        class="form"
        novalidate="novalidate"
        id="kt_login_signup_form"
        [formGroup]="resetPasswordForm"
        (ngSubmit)="resetPasswordSubmit()"
    >
        <!-- begin: Title -->
        <div class="pb-13 pt-lg-0 pt-5">
            <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                {{ "AUTH.RESET_PASSWORD.TITLE" | translate }}
            </h3>
            <p class="text-muted font-weight-bold font-size-h4">
                {{ "AUTH.RESET_PASSWORD.DESC" | translate }}
            </p>
        </div>
        <!-- end: Title -->

        <!-- begin: Alert error -->
        <div
            *ngIf="errorState !== undefined"
            class="mb-10 alert alert-custom alert-light-danger alert-dismissible"
        >
            <div *ngIf="errorState === errorStates.BadToken" class="alert-text">
                {{ "AUTH.RESET_PASSWORD.MESSAGES.BAD_TOKEN" | translate }}
            </div>
            <div *ngIf="errorState === errorStates.CommunicationError" class="alert-text">
                {{ "AUTH.MESSAGES.COMMUNICATION_ERROR" | translate }}
            </div>
        </div>
        <!-- end: Alert error -->

        <!-- begin: Password -->
        <app-text-input
            [inputControl]="resetPasswordForm.controls.password"
            label="GENERAL.INPUT.PASSWORD"
            placeholder="{{ 'GENERAL.INPUT.PASSWORD' | translate }}"
            autocomplete="off"
            type="password"
            labelClass="font-size-h6 font-weight-bolder text-dark"
            inputClass="h-auto py-7 px-6 rounded-lg"
            variant="solid"
        >
        </app-text-input>
        <!-- end: Password -->

        <!-- begin: Confirm password -->
        <app-text-input
            [inputControl]="resetPasswordForm.controls.cPassword"
            label="GENERAL.INPUT.C_PASSWORD"
            placeholder="{{ 'GENERAL.INPUT.C_PASSWORD' | translate }}"
            autocomplete="off"
            type="password"
            labelClass="font-size-h6 font-weight-bolder text-dark"
            inputClass="h-auto py-7 px-6 rounded-lg"
            variant="solid"
        >
        </app-text-input>
        <!-- end: Confirm password -->

        <!-- begin: Actions -->
        <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
            <button
                type="submit"
                id="kt_login_signup_submit"
                [disabled]="resetPasswordForm.invalid"
                class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
            >
                {{ "GENERAL.BUTTONS.SUBMIT" | translate }}
            </button>
            <a
                routerLink="/auth/login"
                type="button"
                id="kt_login_signup_cancel"
                class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
            >
                {{ "GENERAL.BUTTONS.CANCEL" | translate }}
            </a>

            <span *ngIf="isLoading | async" class="spinner spinner-primary ml-5"></span>
        </div>
        <!-- end: Actions -->
    </form>
    <!-- end: Form -->
</div>
<!-- end: Reset password -->
