import { AbstractControl, ValidatorFn } from '@angular/forms';
import { GeneralValidator } from './general.validator';

export class PasswordValidator {
    /** Validates password */
    public static isValid(): ValidatorFn {
        const validateFunctions = [
            (control: AbstractControl) => {
                // Does contain any number
                const hasNumber = /\d/.test(control.value);
                if (!hasNumber) {
                    return { hasNumber: true };
                }
            },
            (control) => {
                // Does contain uppercase letter
                const hasUpper = /[A-Z]/.test(control.value);
                if (!hasUpper) {
                    return { hasUpper: true };
                }
            },
        ];
        return GeneralValidator.isValid(true, 100, 6, validateFunctions);
    }

    /**
     * Check matching password with confirm password
     * @param control AbstractControl
     */
    static MatchPassword(control: AbstractControl) {
        const password = control.get('password').value;

        const confirmPassword = control.get('cPassword').value;

        if (password !== confirmPassword) {
            control.get('cPassword').setErrors({ ConfirmPassword: true });
        } else {
            return null;
        }
    }
}
