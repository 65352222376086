import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputWrapperService } from '../../_services/input-wrapper.service';

@Component({
    selector: 'app-textarea-input',
    templateUrl: './textarea-input.component.html',
    styleUrls: ['./textarea-input.component.scss'],
})
export class TextareaInputComponent implements OnInit {
    /** inputControl */
    @Input() inputControl: FormControl;
    /** Label for input */
    @Input() label: string = '';
    /** Placeholder changes input placeholder*/
    @Input() placeholder: string = '';
    /** Disable input */
    @Input() disabled: boolean = false;
    /** wide change input behavior on big screens */
    @Input() wide: boolean = false;
    /**number of textarea rows */
    @Input() rows: number = 6;
    /**Styling input using angular [style] */
    @Input() inputStyle: Record<string, string | undefined | null>;
    @Input() labelStyle: Record<string, string | undefined | null>;
    /**Class inputs for customization  */
    @Input() inputClass: 'string';
    @Input() labelClass: 'string';

    /**Optional bottom Content */
    @ContentChild('bottomContent', { read: TemplateRef }) bottomContent: TemplateRef<any>;

    constructor(public iws: InputWrapperService) {}

    ngOnInit(): void {}
}
