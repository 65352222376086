<app-input-wrapper
    [inputControl]="inputControl"
    [label]="label"
    [wide]="wide"
    [labelStyle]="labelStyle"
    [labelClass]="labelClass"
    [hideErrorMessages]="hideErrorMessages"
>
    <div class="input-group" [ngClass]="{ 'input-group-solid': variant === 'solid' }">
        <div class="input-group-prepend" *ngIf="leftContent">
            <ng-container *ngTemplateOutlet="leftContent"></ng-container>
        </div>
        <div
            *ngIf="innerIcon; else input"
            class="input-icon w-100"
            [ngClass]="{ 'input-icon-right': innerIcon.direction === 'right' }"
        >
            <ng-container *ngTemplateOutlet="input"></ng-container>
            <div>
                <span>
                    <ng-container *ngTemplateOutlet="innerIcon.template"></ng-container>
                </span>
            </div>
        </div>
        <div class="input-group-append" *ngIf="rightContent">
            <ng-container *ngTemplateOutlet="rightContent"></ng-container>
        </div>
    </div>
    <div *ngIf="bottomContent">
        <ng-container *ngTemplateOutlet="bottomContent"></ng-container>
    </div>
</app-input-wrapper>
<ng-template #input>
    <input
        class="form-control"
        [attr.disabled]="disabled ? '' : null"
        [placeholder]="placeholder | translate"
        [type]="type"
        [formControl]="inputControl"
        [attr.step]="step"
        [attr.min]="min"
        [attr.max]="max"
        [attr.autocomplete]="autocomplete"
        [ngClass]="{ 'is-invalid': iws.isControlInvalidAndTouched(inputControl) }"
        [style]="inputStyle"
        [class]="inputClass"
        (focus)="focusEvent.emit()"
    />
</ng-template>
