import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export enum AlertModalTypes {
    SUCCESS,
    ERROR,
}

@Component({
    selector: 'app-alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit {
    @Input() message;
    @Input() type: AlertModalTypes;

    constructor(public modal: NgbActiveModal) {}

    ngOnInit(): void {}

    get classes() {
        switch (this.type) {
            case AlertModalTypes.SUCCESS:
                return 'fa-check-circle text-success';
            case AlertModalTypes.ERROR:
                return 'fa-exclamation-circle text-danger';
            default:
                return 'fa-check-circle text-success';
        }
    }
}
