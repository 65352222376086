export const NSSETS = {
    LIST: {
        TITLE: 'Seznam Nssetů',
        SEARCHING: 'Vyhledávání',
        IN_ALL_COLUMNS: 've všech sloupcích',
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst Nssety',
        },
    },
    TRANSFER_IMPORT: {
        TITLE: 'Převod nebo import Nssetu',
        MESSAGES: {
            SUCCESS_IMPORT: 'Objednávka na import Nssetu byla úspěšně vytvořena',
            ERROR_IMPORT: 'Nepodařilo se vytvořit objednávku na importování Nssetu',
            SUCCESS_TRANSFER: 'Objednávka na převod Nssetu byla úspěšně vytvořena',
            ERROR_TRANSFER: 'Nepodařilo se vytvořit objednávku na převod Nssetu',
        },
    },
};
