<div class="card scrollable-card-in-modal">
    <div class="card-body">
        <h6 class="mt-4">
            {{ "USER_MANAGEMENT.ORGANIZATIONS.EDIT.ROLES.TREE.PERMISSIONS" | translate }}
        </h6>
        <app-switch-input
            [inputControl]="toggleAllPermissions"
            *ngIf="serviceService.servicePermissionGroupNames.value"
            label="USER_MANAGEMENT.ORGANIZATIONS.EDIT.ROLES.TREE.SHOW_PERMISSIONS_RELATED_TO_SERVICE"
        >
        </app-switch-input>

        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="mat-tree">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle class="d-inline">
                <label
                    class="btn btn-text-primary btn-hover-light-secondary mr-2 btn-lg d-inline-flex align-items-center pl-4 d-inline cursor-default"
                    [ngClass]="{ 'font-weight-bold': node.selected }"
                >
                    <span class="px-2 text-dark">
                        <i class="fa fa-check-circle text-primary"></i>
                        {{ "PERMISSIONS." + node.name | translate }}
                    </span>
                </label>
            </mat-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <div class="row pl-4">
                    <div class="my-1 align-items-center d-inline-flex">
                        <span
                            class="btn btn-text-primary btn-hover-light-primary py-0 pr-0 pl-4 btn-lg d-flex align-items-center"
                            [ngClass]="{ '': treeControl.isExpanded(node) }"
                        >
                            <div class="py-2 pr-2" matTreeNodeToggle>
                                <span class="pl-2 pr-2 text-dark">
                                    {{ "PERMISSIONS." + node.name | translate }}
                                </span>
                                <i
                                    class=" icon-sm fas {{
                                        treeControl.isExpanded(node)
                                            ? 'fa-chevron-up'
                                            : 'fa-chevron-down'
                                    }}"
                                >
                                </i>
                            </div>
                        </span>
                    </div>
                </div>
                <div
                    [class.tree-invisible]="!treeControl.isExpanded(node)"
                    class="permission-wrapper"
                >
                    <ng-container matTreeNodeOutlet></ng-container>
                </div>
            </mat-nested-tree-node>
        </mat-tree>
    </div>
</div>
