import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: ';app-skeleton-loading',
    templateUrl: './skeleton-loading.component.html',
    styleUrls: ['./skeleton-loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonLoadingComponent implements OnInit {
    // Number of bones to render
    @Input() count = 1;
    // Custom styles prop passed to ngStyle
    @Input() theme: any = {};

    bones: Array<null>;
    constructor() {}

    ngOnInit(): void {
        this.bones = this.countToBones(this.count);
    }
    // Makes iterable array
    countToBones(i: number) {
        return new Array(i);
    }
}
