<app-input-wrapper
    [inputControl]="inputControl"
    [labelStyle]="labelStyle"
    [labelClass]="labelClass"
>
    <div class="radio-inline">
        <label class="radio w-100">
            <input
                type="radio"
                [value]="value"
                [formControl]="inputControl"
                [attr.disabled]="disabled ? '' : null"
                [name]="radioGroupName"
            />
            <span></span>
            <ng-container *ngIf="labelTemplate; else textLabel">
                <div class="label-container w-100">
                    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
                </div>
            </ng-container>
        </label>
    </div>
</app-input-wrapper>

<ng-template #textLabel> {{ label | translate }}</ng-template>
