<!-- begin: Login -->
<div class="login-form login-signin">
    <!--begin: Form-->
    <form
        class="form"
        [formGroup]="loginForm"
        novalidate="novalidate"
        id="kt_login_signin_form"
        (ngSubmit)="submit()"
    >
        <!-- begin: Title -->
        <div class="pb-13 pt-lg-0 pt-5">
            <app-environment-identifier></app-environment-identifier>
            <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                {{ "AUTH.LAYOUT.INVITATION" | translate }}
            </h3>
            <span class="text-muted font-weight-bold font-size-h4">
                {{ "AUTH.LOGIN.NO_ACCOUNT" | translate }}
                <a
                    routerLink="/auth/registration"
                    id="kt_login_signup"
                    class="text-primary font-weight-bolder"
                >
                    {{ "AUTH.LOGIN.BUTTONS.REGISTER" | translate }}
                </a>
            </span>
        </div>
        <!-- end: Title -->

        <!-- begin: Alert success - good registration -->
        <ng-container *ngIf="registeredParam === 'true'">
            <div class="alert alert-success" role="alert">
                <strong>{{ "AUTH.REGISTRATION.SUCCESS" | translate }}</strong> <br />
                {{
                    "AUTH.LOGIN.MESSAGES.CONFIRM_EMAIL_SENT"
                        | translate: { email: emailParam ?? "" }
                }}
            </div>
            <a
                (click)="sendConfirmEmail(emailParam)"
                class="text-primary font-weight-bolder cursor-pointer"
            >
                {{ "AUTH.LOGIN.MESSAGES.SEND_CONFIRM_EMAIL_AGAIN" | translate }}
            </a></ng-container
        >
        <!-- end: Alert success - good registration -->

        <!-- begin: Alert success - email confirmation -->
        <div *ngIf="confirmEmailParam === 'true'" class="alert alert-success" role="alert">
            <strong>{{ "AUTH.LOGIN.MESSAGES.CONFIRM_EMAIL_SUCCESS" | translate }}</strong> <br />
            {{ "AUTH.LOGIN.MESSAGES.CAN_LOGIN" | translate }}
        </div>
        <!-- end: Alert success - email confirmation -->

        <!-- begin: Alert success - password reset -->
        <div *ngIf="passwordResetParam === 'true'" class="alert alert-success" role="alert">
            <strong>{{ "AUTH.LOGIN.MESSAGES.PASSWORD_CHANGED" | translate }}</strong> <br />
            {{ "AUTH.LOGIN.MESSAGES.CAN_LOGIN" | translate }}
        </div>
        <!-- end: Alert success - password reset -->

        <!-- begin: Alert info - token expired -->
        <div *ngIf="tokenExpiredParam === 'true'" class="alert alert-info" role="alert">
            {{ "GENERAL.MESSAGES.TOKEN_EXPIRED" | translate }}
        </div>
        <!-- end: Alert info - token expired -->

        <!-- begin: Alert error -->
        <ng-container *ngIf="errorType !== undefined">
            <div class="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div *ngIf="errorType === 0" class="alert-text">
                    {{ "AUTH.LOGIN.MESSAGES.NO_USER" | translate }}
                </div>
                <div *ngIf="errorType === 1" class="alert-text">
                    {{ "AUTH.LOGIN.MESSAGES.BLOCKED" | translate }}
                </div>
                <div *ngIf="errorType === 2" class="alert-text">
                    {{ "AUTH.LOGIN.MESSAGES.WRONG_CREDENTIALS" | translate }}
                </div>
                <div *ngIf="errorType === 3" class="alert-text">
                    {{ "AUTH.LOGIN.MESSAGES.CONFIRM_EMAIL_REQ" | translate }} <br />
                    {{ "AUTH.LOGIN.MESSAGES.CONFIRM_EMAIL_GUIDE" | translate }}
                </div>
                <div *ngIf="errorType === 4" class="alert-text">
                    {{ "AUTH.LOGIN.MESSAGES.GENERAL_ERROR" | translate }}
                </div>
            </div>

            <div class="pb-13 pt-lg-0 pt-5" *ngIf="errorType === 3">
                <span class="text-muted font-weight-bold font-size-h4"
                    >{{ "AUTH.LOGIN.MESSAGES.CONFIRM_EMAIL_NOT_SEND" | translate }}
                    <a
                        (click)="sendConfirmEmail()"
                        class="text-primary font-weight-bolder cursor-pointer"
                    >
                        {{ "AUTH.LOGIN.MESSAGES.GENERATE_NEW_EMAIL" | translate }}
                    </a>
                </span>
            </div>
        </ng-container>
        <!-- end: Alert error -->

        <!-- begin: Email -->
        <app-text-input
            [inputControl]="loginForm.controls.email"
            label="GENERAL.INPUT.EMAIL"
            type="email"
            placeholder="{{ 'GENERAL.INPUT.EMAIL' | translate }}"
            autocomplete="off"
            [labelClass]="'font-size-h6 font-weight-bolder text-dark'"
            inputClass="h-auto py-7 px-6 rounded-lg"
            variant="solid"
        >
        </app-text-input>
        <!-- end: Email -->

        <!-- begin: Password -->
        <app-text-input
            [inputControl]="loginForm.controls.password"
            label="GENERAL.INPUT.PASSWORD"
            type="password"
            placeholder="{{ 'GENERAL.INPUT.PASSWORD' | translate }}"
            autocomplete="off"
            labelClass="font-size-h6 font-weight-bolder text-dark"
            inputClass="h-auto py-7 px-6 rounded-lg"
            variant="solid"
        >
            <ng-template #bottomContent>
                <div class="w-100 text-right position-absolute" style="z-index: 10">
                    <a
                        routerLink="/auth/forgot-password"
                        [queryParams]="{ email: loginForm.controls.email.value }"
                        class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-2"
                        id="kt_login_forgot"
                    >
                        {{ "AUTH.LOGIN.FORGOT_PASSWORD" | translate }}
                    </a>
                </div>
            </ng-template>
        </app-text-input>
        <!-- end: Password -->

        <!-- begin: Action -->
        <div class="pb-lg-0 pb-5 mt-4">
            <button
                type="submit"
                id="kt_login_signin_submit"
                class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                [disabled]="loginForm.invalid"
            >
                {{ "AUTH.LOGIN.BUTTONS.LOGIN" | translate }}
            </button>

            <span *ngIf="isLoading | async" class="spinner spinner-primary ml-5"></span>
        </div>
        <!-- end: Action -->
    </form>
    <!-- end: Form -->
</div>
<!-- end: Login -->
