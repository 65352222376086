interface YBugSettings {
    id?: string;
    email?: string;
    name?: string;
}

export const initializeYbug = ({ id, email, name }: YBugSettings) => {
    (function () {
        // @ts-ignore
        window.ybug_settings = {
            id: 'a3hmd7a9cms3v0rcq8s8',
            launcher_position: 'bottom-left',
            feedback: {
                id: id,
                email: email,
                name: name,
            },
        };
        var ybug = document.createElement('script');
        ybug.type = 'text/javascript';
        ybug.async = true;
        // @ts-ignore
        ybug.src = 'https://widget.ybug.io/button/' + window.ybug_settings.id + '.js';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(ybug, s);
    })();
};
