<div class="login-form login-forgot">
    <!--begin::Form-->
    <form
        *ngIf="errorState !== errorStates.ResendTrue"
        class="form fv-plugins-bootstrap fv-plugins-framework"
        novalidate="novalidate"
        [formGroup]="confirmEmailForm"
        (ngSubmit)="resendVerificationEmailSubmit()"
        id="kt_login_forgot_form"
        [ngStyle]="{ display: errorState === errorStates.NoError ? 'none' : 'block' }"
    >
        <!-- begin: Title -->
        <div class="pb-13 pt-lg-0 pt-5">
            <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                {{ "AUTH.CONFIRM_EMAIL.TITLE" | translate }}
            </h3>
            <p class="text-muted font-weight-bold font-size-h4">
                {{ "AUTH.CONFIRM_EMAIL.DESC" | translate }}
            </p>
        </div>
        <!-- end: Title -->

        <!-- begin: Alert error -->
        <ng-container *ngIf="errorState !== undefined">
            <div class="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div *ngIf="errorState === errorStates.NoEmail" class="alert-text">
                    {{ "AUTH.MESSAGES.USER_NOT_FOUND" | translate }}
                </div>
                <div *ngIf="errorState === errorStates.Confirmed" class="alert-text">
                    {{ "AUTH.CONFIRM_EMAIL.MESSAGES.ALREADY_CONFIRMED" | translate }}
                </div>
                <div *ngIf="errorState === errorStates.CommunicationError" class="alert-text">
                    {{ "AUTH.MESSAGES.COMMUNICATION_ERROR" | translate }}
                </div>
            </div>
        </ng-container>
        <!-- end: Alert error -->

        <!-- begin: Email -->
        <app-text-input
            [inputControl]="confirmEmailForm.controls.email"
            label="GENERAL.INPUT.EMAIL"
            type="email"
            placeholder="{{ 'GENERAL.INPUT.EMAIL' | translate }}"
            autocomplete="off"
            labelClass="font-size-h6 font-weight-bolder text-dark"
            inputClass="h-auto py-7 px-6 rounded-lg"
            variant="solid"
        >
        </app-text-input>
        <!-- end: Email -->
        <!-- begin: Actions -->
        <div class="form-group d-flex flex-wrap pb-lg-0">
            <button
                type="submit"
                id="kt_login_forgot_submit"
                class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                [disabled]="confirmEmailForm.invalid"
            >
                {{ "GENERAL.BUTTONS.SUBMIT" | translate }}
            </button>
            <a
                routerLink="/auth/login"
                id="kt_login_forgot_cancel"
                class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
            >
                {{ "GENERAL.BUTTONS.CANCEL" | translate }}
            </a>

            <span *ngIf="isLoading | async" class="spinner spinner-primary ml-5"></span>
        </div>
        <!-- end: Actions -->
    </form>
    <!-- end: Form -->

    <!-- begin: Email resent success -->
    <div
        *ngIf="errorState === errorStates.ResendTrue"
        class="card card-custom bgi-no-repeat gutter-b"
        style="
            height: 175px;
            background-color: #4ab58e;
            background-position: calc(100% + 1rem) bottom;
            background-size: 25% auto;
            background-image: url(assets/media/svg/humans/custom-1.svg);
        "
    >
        <div class="card-body d-flex align-items-center">
            <div class="py-2">
                <h3 class="text-white font-weight-bolder mb-3">{{ "AUTH.SUPER" | translate }}</h3>
                <p class="text-white font-size-lg">
                    {{ "AUTH.CONFIRM_EMAIL.MESSAGES.CONFIRMATION_SENT" | translate }}
                </p>
                <a
                    routerLink="/auth/login"
                    class="swal2-confirm btn font-weight-bold btn-light-primary"
                >
                    {{ "GENERAL.BUTTONS.OK" | translate }}
                </a>
            </div>
        </div>
    </div>
    <!-- end: Email resent success -->
</div>
