import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationModule } from '../translation/translation.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InformationModalComponent } from './information-modal/information-modal.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { DeleteConfirmModalComponent } from './delete-modal/delete-confirm-modal.component';
import { ConfirmChangesModalComponent } from './confirm-changes-modal/confirm-changes-modal.component';
import { SkeletonLoadingModule } from '../skeleton-loading/skeleton-loading.module';
import { AuthModule } from '../auth/auth.module';
import { AddWhateverWithNameModalComponent } from './add-whatever-with-name-modal/add-whatever-with-name-modal.component';
import { InputWrappersModule } from '../forms/input-wrappers.module';
import { AddServiceToGroupModalComponent } from './add-service-to-group.modal/add-service-to-group.modal.component';

@NgModule({
    declarations: [
        InformationModalComponent,
        AlertModalComponent,
        DeleteConfirmModalComponent,
        ConfirmChangesModalComponent,
        AddWhateverWithNameModalComponent,
        AddServiceToGroupModalComponent,
    ],
    imports: [
        CommonModule,
        TranslationModule,
        FormsModule,
        SkeletonLoadingModule,
        AuthModule,
        ReactiveFormsModule,
        InputWrappersModule,
    ],
})
export class ModalsModule {}
