import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-add-service-to-group.modal',
    templateUrl: './add-service-to-group.modal.component.html',
    styleUrls: ['./add-service-to-group.modal.component.scss'],
})
export class AddServiceToGroupModalComponent implements OnInit {
    constructor(public modal: NgbActiveModal) {}

    ngOnInit(): void {}
}
