<div class="block table-scroll">
    <table
        mat-table
        #dataTable
        [dataSource]="dataSource"
        matSort
        multiTemplateDataRows
        matTableExporter
        #exporter="matTableExporter"
        class="table ans-table w-100"
    >
        <ng-container [matColumnDef]="column.id" *ngFor="let column of visibleColumns">
            <mat-header-cell
                *matHeaderCellDef
                [fxFlex]="column.width + 'px'"
                mat-sort-header
                [disabled]="column.disableSort"
            >
                <ng-container
                    *ngIf="this.headerCellTemplates.get(column.id); else headerCell"
                    [ngTemplateOutlet]="this.headerCellTemplates.get(column.id)"
                    [ngTemplateOutletContext]="{ column: column }"
                >
                </ng-container>
                <ng-template #headerCell>
                    {{ column.label | translate }}
                </ng-template>
            </mat-header-cell>

            <mat-cell *matCellDef="let row" [fxFlex]="column.width + 'px'">
                <ng-container
                    *ngIf="this.cellTemplates.get(column.id); else cell"
                    [ngTemplateOutlet]="this.cellTemplates.get(column.id)"
                    [ngTemplateOutletContext]="{ row: row, column: column }"
                >
                </ng-container>
                <ng-template #cell>
                    {{ row[column.id] }}
                </ng-template>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="trigger">
            <mat-header-cell *matHeaderCellDef fxFlex="70px">
                {{ "GENERAL.TABLE.EXPAND" | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = dataIndex" fxFlex="70px">
                <button
                    (click)="expandedElement[i] = !expandedElement[i]"
                    class="btn btn-icon btn-light"
                >
                    <i
                        class="fas icon  {{
                            expandedElement[i] ? 'fa-chevron-up' : 'fa-chevron-down'
                        }}"
                    >
                    </i>
                </button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="hidden">
            <mat-cell
                *matCellDef="let row; let i = dataIndex"
                class="matCell py-4"
                fxLayout="column"
                fxLayoutAlign="center start"
            >
                <div
                    *ngFor="let hiddenColumn of hiddenColumns"
                    fxLayout="row"
                    class="max-w-mobile w-100 py-2"
                    fxLayoutAlign="space-between center"
                >
                    <div class="text-muted">
                        <span> {{ hiddenColumn.label | translate }}: </span>
                    </div>
                    <div class="d-flex text-right">
                        <ng-container
                            *ngIf="this.cellTemplates.get(hiddenColumn.id); else cell"
                            [ngTemplateOutlet]="this.cellTemplates.get(hiddenColumn.id)"
                            [ngTemplateOutletContext]="{ row: row, column: hiddenColumn }"
                        >
                        </ng-container>
                    </div>
                    <ng-template #cell class="text-right">
                        {{ row[hiddenColumn.id] }}
                    </ng-template>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row
            *matHeaderRowDef="visibleColumnsIds"
            class="matHeaderRow"
            [class.rowGap]="!noCellPadding"
        >
        </mat-header-row>

        <mat-row
            *matRowDef="let row; let i = dataIndex; columns: visibleColumnsIds"
            class="matRowVisible visible-row-{{ i }}"
            [class.rowGap]="!noCellPadding"
        ></mat-row>

        <!-- Body row definition for hidden columns -->
        <mat-row
            *matRowDef="let row; let i = dataIndex; columns: ['hidden']"
            [@detailExpand]="expandedElement && expandedElement[i] ? 'expanded' : 'collapsed'"
            style="overflow: hidden"
            class="matRowHidden will-change hidden-row-{{ i }} hidColLength-{{
                hiddenColumns.length
            }}"
            [ngClass]="expandedElement[i] ? 'expanded' : 'collapsed'"
        ></mat-row>

        <!-- NO ROW SECTION -->
        <div *matNoDataRow class="p-8" [hidden]="dataSource.loading$ | async">
            <span *ngIf="(dataSource.state | async) !== 'error'; else error">
                {{ "GENERAL.INPUT.NO_ROWS" | translate }}
            </span>
            <ng-template #error>
                <span class="text-danger">
                    <i class="fas fa-exclamation-circle text-danger"></i>
                    {{ "GENERAL.INPUT.TABLE_LOAD_ERROR" | translate }}
                </span>
            </ng-template>
        </div>
    </table>
    <app-skeleton-loading [count]="skeltonBonesCount | async" *ngIf="dataSource.loading$ | async">
    </app-skeleton-loading>
</div>
<mat-paginator
    [hidden]="
        disablePagination ||
        (dataSource.state | async) === 'error' ||
        (dataSource.state | async) === 'empty'
    "
    [length]="dataSource.totalCount"
    [pageSize]="defaultPageSize"
    [pageSizeOptions]="[10, 20, 50, 100]"
>
</mat-paginator>
