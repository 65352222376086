import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { PermissionService } from './permission.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router,
        private permissionEnforcingService: PermissionService,
    ) {}

    /** Returns if user is logged and has permissions */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const audience = route.data.audience;
        if (!audience) {
            // TODO: always respect audience
            if (this.authService.currentUser.value) {
                // User is logged
                return true;
            } else {
                // User is not logged, logout and redirect to login page
                this.authService.logout();
                return false;
            }
        }
        const hasAudience = this.permissionEnforcingService.hasUserAudience(audience);
        if (!hasAudience) {
            this.router.navigate(['/']);
        }
        return hasAudience;
    }
}
