import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './_components/table/table.component';
import { MatTableModule } from '@angular/material/table';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSortModule } from '@angular/material/sort';
import { CustomCellDirective } from './_directives/custom-cell.directive';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SkeletonLoadingModule } from '../skeleton-loading/skeleton-loading.module';
import { CustomHeaderCellDirective } from './_directives/custom-header-cell.directive';
import { MatTableExporterModule } from 'mat-table-exporter';
import { TranslationModule } from '../translation/translation.module';
import { FilteringComponent } from './_components/filtering/filtering.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { InputWrappersModule } from '../forms/input-wrappers.module';

@NgModule({
    declarations: [
        TableComponent,
        CustomCellDirective,
        CustomHeaderCellDirective,
        FilteringComponent,
    ],
    imports: [
        CommonModule,
        MatTableModule,
        FlexLayoutModule,
        MatSortModule,
        MatPaginatorModule,
        MatTableExporterModule,
        SkeletonLoadingModule,
        TranslationModule,
        InlineSVGModule,
        InputWrappersModule,
    ],
    exports: [TableComponent, CustomCellDirective, CustomHeaderCellDirective, FilteringComponent],
})
export class AnseaTableModule {}
