export const AUTH = {
    SUPER: 'Super',
    MESSAGES: {
        COMMUNICATION_ERROR: 'Chyba komunikace se serverem pro potvrzení autority',
        USER_NOT_FOUND: 'Takový uživatel neexsituje',
        VERIFICATION_EMAIL_RESENT: 'Na email {{email}} byl odeslán nový potvrzovací email',
        VERIFICATION_EMAIL_RESENT_ERROR:
            'Nepodařilo se odeslat potvrzovací email na email {{email}}',
    },
    LAYOUT: {
        INVITATION: 'Vítejte na portálu Ansea',
        TERMS_AND_CONDITIONS: 'Obchodní podmínky',
        CONTACT: 'Kontakt',
    },
    LOGIN: {
        NO_ACCOUNT: 'Ještě nemáte účet?',
        FORGOT_PASSWORD: 'Zapomněli jste heslo?',
        BUTTONS: {
            LOGIN: 'Přihlásit se',
            REGISTER: 'Registrace',
        },
        MESSAGES: {
            GENERAL_ERROR: 'Někde nastala chyba, prosím, kontaktujte nás',
            CAN_LOGIN: 'Můžete se přihlásit.',
            PASSWORD_CHANGED: 'Vaše heslo bylo úspěšně změněno',
            CONFIRM_EMAIL_REQ: 'Prosím, nejdříve ověřte svou emailovou adresu.',
            CONFIRM_EMAIL_SUCCESS: 'Emailová adresa byla úspěšně ověřena.',
            CONFIRM_EMAIL_GUIDE: 'Prosím klikněte na odkaz, který Vám byl zaslán do emailu.',
            CONFIRM_EMAIL_NOT_SEND: 'Nepřišel Vám žádný email?',
            GENERATE_NEW_EMAIL: 'Vygenerovat nový',
            NO_USER: 'Takový uživatel neexsituje',
            BLOCKED: 'Tento účet byl zablokován administrátorem',
            WRONG_CREDENTIALS: 'Špatné přihlašovací údaje',
            CONFIRM_EMAIL_SENT:
                'Na email {{email}} byl odeslán ověřovací email, po ověření se budete moct příhlásit.',
            SEND_CONFIRM_EMAIL_AGAIN: 'Znovu zaslat ověřovací email',
        },
    },
    FORGOT_PASSWORD: {
        TITLE: 'Zapomněli jste heslo?',
        DESC: 'Zadejte svůj registrační email',
        ACCOUNT_NOT_CONFIRMED: 'Tento účet ještě nebyl ani potvrzen',
        PASSWORD_RESET_SENT:
            'Právě jsme Vám zaslali instrukce k resetování hesla na Vaši emailovou adresu',
    },
    REGISTRATION: {
        TITLE: 'Registrace',
        DESC: 'Prosím, vyplňte požadované údaje',
        AGREE_WITH: 'Souhlasím s',
        TERMS_AND_CONDITIONS: 'obchodními podmínkami',
        REGISTER_BUTTON: 'Registrovat',
        SUCCESS: 'Jste zaregistrován',
    },
    RESET_PASSWORD: {
        TITLE: 'Nastavení nového hesla',
        DESC: 'Prosím, zadejte nové heslo, které chcete používat k přihlašování',
        MESSAGES: {
            BAD_TOKEN: 'Chybný token. Prosím, vygenerujte si novou žádost o resetování hesla.',
        },
    },
    CONFIRM_EMAIL: {
        TITLE: 'Znovu zaslání ověřovacího emailu',
        DESC: 'Prosím, zadejte svůj email',
        MESSAGES: {
            CONFIRMATION_SENT: 'Právě jsme Vám zaslali instrukce k potvrzení Vaši emailové adresy',
            ALREADY_CONFIRMED: 'Tento účet již byl potvrzen',
        },
    },
    SERVICE_PERMISSIONS: {
        USER_FIRST_NAME: 'Jméno',
        USER_LAST_NAME: 'Příjmení',
        ACCESS_SOURCE: 'Zdroj přístupu',
        ROLE_NAMES: 'Role',
        TAB: 'Přístupy',
        DELETE: {
            TITLE: 'Smazat přístup',
            MESSAGE:
                'Opravdu chcete odebrat přímý přístup uživatele "{{firstName}} {{lastName}}" ke službě "{{entityName}}"?',
            SUCCESS: 'Přístup byl odebrán',
            ERROR: 'Přístup se nepodařilo odebrat',
        },
    },
    SERVICE_GROUPS: {
        GROUP_NAME: 'Název skupiny',
    },
};
