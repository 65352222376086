export const CONTACTS = {
    LIST: {
        TITLE: 'Kontakty',
        BUTTONS: {
            ADD: 'Přidat kontakt',
            CREATE: 'Vytvořit kontakt',
            TRANSFER_IMPORT: 'Převést nebo importovat kontakt',
        },
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst kontakty',
        },
    },
    CREATE: {
        TITLE: 'Vytvořit kontakt',
        BUTTONS: {
            CREATE_CONTACT: 'Vytvořit kontakt',
            CREATE_CZ_CONTACT: 'Vytvořit kontakt i u CZ-NIC',
        },
        MESSAGES: {
            CONTACT_CREATED: 'Nový kontakt byl úspěšně vytvořen',
            CONTACT_NOT_CREATED: 'Nepodařilo se vytvořit nový kontakt',
            ERROR_INVALID_BIRTHDAY: 'Neplatný datum narození',
        },
    },
    EDIT: {
        TITLE: 'Upravit kontakt',
        HEADER_NIC_INFORMATION: 'Informace pro CZ-NIC',
        BUTTONS: {
            EDIT_CONTACT: 'Upravit kontakt',
        },
        MESSAGES: {
            ERROR_LOAD_CONTACT: 'Nepodařilo se načíst kontakt',
            ERROR_SAVE_CHANGES: 'Nepodařilo se uložit kontakt',
            SUCCESS_SAVE_CHANGES: 'Kontakt byl úspěšně upraven',
        },
    },
    VIEW: {
        TITLE: 'Detail kontaktu',
        DOMAINS: 'Domény kontaktu',
    },
    TRANSFER_IMPORT: {
        TITLE: 'Převod nebo import kontaktu',
        MESSAGES: {
            SUCCESS_IMPORT: 'Objednávka na import kontaktu byla úspěšně vytvořena',
            ERROR_IMPORT: 'Nepodařilo se vytvořit objednávku na importování kontaktu',
            SUCCESS_TRANSFER: 'Objednávka na převod kontaktu byla úspěšně vytvořena',
            ERROR_TRANSFER: 'Nepodařilo se vytvořit objednávku na převod kontaktu',
        },
    },
};
