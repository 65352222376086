export const DNS = {
    INPUT: {
        DOMAIN: {
            NAME: 'Název domény',
            OWNER: 'Vlastník',
        },
        RECORD: {
            NAME: 'Název záznamu',
            TYPE: 'Typ',
            CONTENT: 'Obsah',
        },
    },
    DOMAINS: {
        TITLE: 'Seznam domén - DNS',
        SEARCHING: 'Vyhledávání',
        IN_DOMAIN_NAMES: 'podle názvu domény',
        BUTTONS: {
            ADD: 'Přidat doménu',
            VIEW: 'Zobrazit záznamy',
            DELETE: 'Smazat doménu',
        },
    },
    ADD_DOMAIN: {
        TITLE: 'Přidat doménu',
        HEADER_RECORDS: 'Záznamy',
        BUTTONS: {
            ADD: 'Přidat doménu',
            SHOW_DNS: 'Zobrazit doménu',
            CREATE_NEXT_DNS: 'Přidat další DNS',
        },
        MESSAGES: {
            GENERAL_ERROR: 'Nepodařilo se přidat doménu!',
            LOADING: 'Probíhá přidávání domény',
            DOMAIN_EXISTS: 'Tato doména již existuje',
            SUCCESS: 'Doména byla přidána',
            SUCCESS_DESC: '{{name }} byla přidána',
            ERROR: 'Nepodařilo se přidat doménu',
            ERROR_DESC: 'Nepodařilo se přidat doménu {{name}}',
            ERROR_ADD_RECORD: 'Doména byla přidána, ale některé záznamy byly zadány špatně',
        },
        WIZARD: {
            DOMAIN: 'Doména',
            STEPS: {
                1: 'Název domény',
                2: 'Výběr organizace',
                3: 'Shrnutí',
            },
        },
    },
    DELETE_DOMAIN: {
        TITLE: 'Smazat doménu',
        ARE_YOU_SURE: 'Opravdu chcete smazat doménu: "{{name}}"?',
        DELETING: 'Mazání domény...',
        MESSAGES: {
            ERROR: 'Nepodařilo se smazat doménu',
            SUCCESS: 'Doména "{{name}}" byla smazána',
        },
    },
    EDIT_DOMAIN: {
        TITLE: 'Upravit doménu',
        TITLE_WITH_PARAM: 'Upravit doménu {{domainName}}',
        HEADER_ADD_RECORDS: 'Přidat záznamy',
        BUTTONS: {
            EDIT_RECORD: 'Upravit záznam',
            DELETE_RECORD: 'Smazat záznam',
            ADD_RECORDS: 'Přidat záznamy',
            BACK_TO_DOMAINS: 'Zpět na přehled',
        },
        MESSAGES: {
            SUCCESS_RECORD_SAVE: 'Záznam byl změněn',
            ERROR_RECORD_SAVE: 'Nepodařilo se uložit záznam',
            ERROR_DELETE_RECORD: 'Nepodařilo se smazat záznam',
            ERROR_ADD_RECORDS: 'Nepodařilo se přidat všechny záznamy',
            SUCCESS_ADD_RECORDS: 'Záznamy byly přidány',
            SUCCESS_DELETE_RECORD: 'Záznam byl smazán',
        },
        DELETE_RECORD: {
            ARE_YOU_SURE: 'Opravdu si přejete smazat záznam "{{name}}"?',
            TITLE: 'Smazat DNS záznam',
        },
        CONFIRM_DUPLICATES: {
            ARE_YOU_SURE: 'Opravdu chcete přidat duplicitní záznam "{{name}}"?',
            ARE_YOU_SURE_PLURAL: 'Opravdu chcete přidat duplicitní záznamy "{{name}}"?',
            TITLE: 'Přidat duplicitní "A" záznam',
            TITLE_PLURAL: 'Přidat duplicitní "A" záznamy',
        },
    },
    RECORDS_COMPONENT: {
        BUTTONS: {
            ADD_RECORD: 'Přidat řádek záznamu',
        },
    },
    RECORD_VALIDATION: {
        NAME: {
            NOT_VALID_RECORD_NAME: 'Neplatný název záznamu',
            EXIST: 'Záznam již existuje',
        },
        TYPE: {
            TYPE_REQUIRED: 'Vyberte typ záznamu',
        },
    },
    ACCESS: {
        USERS_TITLE: 'Uživatelé s přístupem k DNS',
        GROUPS_TITLE: 'Skupiny, ve kterých je DNS',
    },
    ADD_USER: {
        TITLE: 'Přidat uživatele k DNS',
    },
    EDIT_USER: {
        TITLE: 'Uživatel s přístupem k DNS',
    },
};
