import { CollectionViewer, DataSource, SelectionModel } from '@angular/cdk/collections';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, Observable } from 'rxjs';
import { ListModel } from '../user-management/_models/list.model';
import { SortLimitModel } from '../user-management/_models/sort-limit.model';

interface ITableFiltration {
    [column: string]: string;
}

type Fetcher<T> = (
    sortLimit?: SortLimitModel,
    filtration?: ITableFiltration,
) => Observable<ListModel<T>>;

export class CustomDataSource<T> implements DataSource<T> {
    private fetcher: Fetcher<T>; /**  Function for fetching data  */
    public data = new BehaviorSubject<T[]>([]);
    public allData = new BehaviorSubject<T[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private clientSideMode = false;

    public totalCount: number = 0;
    public selection = new SelectionModel<T>(true, []);
    public state: BehaviorSubject<'initial' | 'loaded' | 'empty' | 'error'> = new BehaviorSubject(
        'initial',
    );
    public loading$ = this.loadingSubject.asObservable();

    filtration$: BehaviorSubject<ITableFiltration> = new BehaviorSubject<ITableFiltration>({});
    sortLimit$: BehaviorSubject<SortLimitModel> = new BehaviorSubject<SortLimitModel>({
        limit: 20, // default page size
        offset: 0,
        orderBy: '',
        order: '',
    });

    constructor(args?: {
        clientSideMode?: boolean;
        defaultPageSize?: number;
        defaultFiltration?: ITableFiltration;
        disablePagination?: boolean;
    }) {
        if (args?.clientSideMode) {
            this.clientSideMode = args.clientSideMode;
        }
        if (args?.defaultPageSize) {
            this.sortLimit$.next({
                ...this.sortLimit$.value,
                limit: args.defaultPageSize,
            });
        }
        if (args?.defaultFiltration) {
            this.filtration$.next(args.defaultFiltration);
        }
        if (args?.disablePagination) {
            this.disablePagination();
        }
    }

    connect(collectionViewer: CollectionViewer): Observable<T[]> {
        return this.data.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.data.complete();
        this.loadingSubject.complete();
        this.filtration$.complete();
        this.sortLimit$.complete();
    }
    createFetcher(f: Fetcher<T>) {
        this.fetcher = f;
    }

    disablePagination() {
        this.sortLimit$.next({
            ...this.sortLimit$.value,
            limit: undefined,
            offset: undefined,
        });
    }
    isPaginationDisabled(): boolean {
        return this.sortLimit$.value.limit === undefined;
    }

    loadData() {
        this.data.next([]);
        this.loadingSubject.next(true);

        if (!this.fetcher) {
            throw new Error('Fetcher is not defined');
        }
        this.fetcher(this.sortLimit$.value, this.filtration$.value).subscribe({
            next: (data) => {
                this.totalCount = data.totalCount;
                if (!this.clientSideMode) {
                    this.data.next(data.data);
                } else {
                    this.allData.next(data.data);
                    this.data.next(
                        this.clientSideModeFns(
                            this.allData.value,
                            this.sortLimit$.value,
                            this.filtration$.value,
                        ),
                    );
                }

                if (data.totalCount > 0) {
                    this.state.next('loaded');
                }
                if (this.state.value === 'initial' && data.totalCount === 0 && this.isFilterEmpty) {
                    this.state.next('empty');
                }
                this.loadingSubject.next(false);
            },
            error: (err) => {
                this.loadingSubject.next(false);
                this.state.next('error');
            },
        });
    }

    changePage(page: PageEvent) {
        const newPageChange = {
            ...this.sortLimit$.value,
            limit: page.pageSize,
            offset: page.pageIndex * page.pageSize,
        };
        this.sortLimit$.next(newPageChange);

        if (this.clientSideMode) {
            this.data.next(this.clientSidePaginate(this.allData.value, newPageChange));
        } else {
            this.loadData();
        }
    }

    changeSort(sort: string, order: string) {
        const newSortLimit = {
            ...this.sortLimit$.value,
            orderBy: sort,
            order: order,
        };
        this.sortLimit$.next(newSortLimit);
        if (this.clientSideMode) {
            this.data.next(this.clientSideSort(this.allData.value, newSortLimit));
        } else {
            this.loadData();
        }
    }

    public isEmpty() {
        return this.state.value === 'empty' && this.loadingSubject.value === false;
    }
    applyFilter(filtration: ITableFiltration) {
        // TODO: Add debounce
        const newFilter = { ...this.filtration$.value, ...filtration };
        this.filtration$.next(newFilter);
        if (this.clientSideMode) {
            this.data.next(this.clientSideFilter(this.allData.value, newFilter));
        } else {
            this.loadData();
        }
    }

    /**
     * Returns if current value of filtration$ property has all fields empty
     */
    get isFilterEmpty(): boolean {
        if (this.filtration$?.value) {
            return Object.values(this.filtration$?.value).find((value) => value) === undefined;
        }
        return true;
    }

    /** Selection logic */

    /**
     * Returns indexes of currently selected rows
     */
    getSelectedRowsIndexes(): number[] {
        return this.selection.selected.map((item) => this.data.value.indexOf(item));
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.data.value.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.selection.select(...this.data.value);
        }
    }

    /** Client side pagination and sorting logic */
    private clientSideModeFns(data: T[], sort: SortLimitModel, filtration: ITableFiltration) {
        const filtered = this.clientSideFilter(data, filtration);
        const sorted = this.clientSideSort(filtered, sort);
        const paginated = this.clientSidePaginate(sorted, sort);
        return paginated;
    }
    private clientSidePaginate(data: T[], sort: SortLimitModel): T[] {
        const limit = sort.limit;
        const offset = sort.offset;
        return data.slice(offset, offset + limit);
    }
    private clientSideSort(data: T[], sort: SortLimitModel): T[] {
        if (sort.orderBy) {
            return data.sort((a, b) => {
                if (sort.order === 'asc') {
                    return a[sort.orderBy] > b[sort.orderBy] ? 1 : -1;
                } else {
                    return a[sort.orderBy] < b[sort.orderBy] ? 1 : -1;
                }
            });
        }
        return data;
    }
    private clientSideFilter(data: T[], filtration: ITableFiltration): T[] {
        if (Object.keys(filtration).length === 0) {
            return data;
        }
        return data.filter((item) => {
            return Object.keys(filtration).some((key) => {
                if (filtration[key]) {
                    return item[key]
                        ?.toString()
                        ?.toLowerCase()
                        .includes(filtration[key].toLowerCase());
                }
                return true;
            });
        });
    }
}
