export const ORDERS = {
    LIST: {
        TITLE: 'Přehled objednávek',
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst objednávky',
        },
    },
    VIEW: {
        TITLE: 'Zobrazení objednávky',
        TITLE_WITH_PARAM: 'Zobrazení objednávky {{orderId}}',
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst údaje o objednávce',
        },
    },
};
