import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';
import { TranslationService } from './modules/translation/translation.service';

// language list
import { locale as csLang } from './modules/translation/vocabs/cs/cs';

import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { NavigationEnd, NavigationError, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { TableExtendedService } from './_metronic/shared/crud-table';
import { HttpCancelService } from './modules/http-interceptor/http-cancel.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'body[root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    constructor(
        private translationService: TranslationService,
        private splashScreenService: SplashScreenService,
        private router: Router,
        private tableService: TableExtendedService,
        private renderer: Renderer2,
        private httpCancelService: HttpCancelService,
        private titleService: Title,
        private cdr: ChangeDetectorRef,
    ) {
        // register translations
        this.translationService.loadTranslations(csLang);
    }

    ngOnInit() {
        const routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // clear filtration paginations and others
                this.tableService.setDefaults();
                // hide splash screen
                this.splashScreenService.hide();

                // scroll to top on every route change
                window.scrollTo(0, 0);

                // to display back the body content
                setTimeout(() => {
                    document.body.classList.add('page-loaded');
                }, 500);
            }
            if (event instanceof NavigationEnd) {
                this.httpCancelService.cancelPendingRequests();
            }
            this.navigationInterceptor(event as RouterEvent);
        });
        this.unsubscribe.push(routerSubscription);
        this.setTitle();
    }

    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationError) {
            this.router.navigate(['/dashboard'], { replaceUrl: true });
            this.cdr.detectChanges();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }

    ngAfterViewInit() {}

    setTitle() {
        let title = 'ANSEA';
        switch (environment.ENVIRONMENT_IDENTIFIER) {
            case 'development':
                title += ' Development';
                break;
            case 'stage':
                title += ' Stage';
                break;
        }
        this.titleService.setTitle(title);
    }
}
