<app-filtering [filteredColumns]="filteredColumns" [dataSource]="dataSource"></app-filtering>
<app-table [columns]="columns" [dataSource]="dataSource">
    <ng-template appCustomCell="userFirstName" let-row="row">
        <a
            [routerLink]="'/user-management/edit-user/' + row.userId"
            [appDisableLink]="['Identity_Organizations_Read_Users', 'Identity_Users_Read']"
            [appDisableLinkIgnoreCurrentPermissions]="true"
            [appDisableLinkOrganizationId]="organizationId"
        >
            {{ row.userFirstName }}
        </a>
    </ng-template>
    <ng-template appCustomCell="userLastName" let-row="row">
        <a
            [routerLink]="'/user-management/edit-user/' + row.userId"
            [appDisableLink]="['Identity_Organizations_Read_Users', 'Identity_Users_Read']"
            [appDisableLinkIgnoreCurrentPermissions]="true"
            [appDisableLinkOrganizationId]="organizationId"
        >
            {{ row.userLastName }}
        </a>
    </ng-template>
    <ng-template appCustomCell="userEmail" let-row="row">
        {{ row.userEmail }}
    </ng-template>
    <ng-template appCustomCell="roleNames" let-row="row">
        <app-roles-labels [roles]="row.roles"></app-roles-labels>
    </ng-template>
    <ng-template appCustomCell="actions" let-row="row">
        <button
            *appIfPerm="
                ['Identity_ShowAccess', 'Identity_Users_Read'];
                organizationId: organizationId;
                ignoreCurrentPermissions: true
            "
            class="btn btn-light btn-hover-primary btn-icon btn-sm mr-3"
            [routerLink]="'/user-management/edit-user/' + row.userId + '/access/organizations'"
            [ngbTooltip]="'GENERAL.BUTTONS.VIEW' | translate"
        >
            <i class="flaticon-eye text-primary"></i>
        </button>
        <button
            *appIfPerm="'Identity_Groups_Edit_Users'"
            class="btn btn-light btn-hover-warning btn-sm btn-icon mr-3"
            [ngbTooltip]="'GENERAL.BUTTONS.UPDATE' | translate"
            (click)="editConnection(row)"
        >
            <i class="flaticon2-edit text-warning"></i>
        </button>
    </ng-template>
</app-table>
