<app-input-wrapper
    [inputControl]="inputControl"
    [label]="label"
    [wide]="wide"
    [labelStyle]="labelStyle"
    [labelClass]="labelClass"
>
    <span class="switch">
        <label>
            <input type="checkbox" [formControl]="inputControl" />
            <span></span>
        </label>
    </span>
</app-input-wrapper>
