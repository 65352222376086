export const CATCH_DOMAINS = {
    MINUTES_SUFFIX: 'min',
    BUTTONS: {
        ADD_DOMAIN: 'Přidat doménu',
    },
    LIST: {
        TITLE: 'Zachytávání domén',
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst zachytávané domény',
            SUCCESS_STOP: 'Zachytávání domény bylo úspěšně zrušeno',
            ERROR_STOP: 'Nepodařilo se zrušit zachytávání domény',
        },
        WELCOME: {
            TITLE: 'Vítejte ve správě zachytávání domén',
            DESC: 'Zatím nemáte nastavené žádné zachytávání domény.<br>Přidejte si svoje první zachytávání domény právě teď.',
        },
    },
    ADD: {
        TITLE: 'Přidat doménu k zachytávání',
        MESSAGES: {
            SUCCESS: 'Doména byla úspěšně přidána k zachycení',
            ERROR: 'Nepodařilo se přidat doménu k zachycení',
        },
    },
};
