<app-input-wrapper
    [inputControl]="inputControl"
    [label]="label"
    [wide]="wide"
    [labelClass]="labelClass"
    [labelStyle]="labelStyle"
>
    <textarea
        class="form-control"
        [rows]="rows"
        [formControl]="inputControl"
        [ngClass]="{ 'is-invalid': iws.isControlInvalidAndTouched(inputControl) }"
        [placeholder]="placeholder"
        [style]="inputStyle"
        [class]="inputClass"
    ></textarea>
    <div *ngIf="bottomContent">
        <ng-container *ngTemplateOutlet="bottomContent"></ng-container>
    </div>
</app-input-wrapper>
