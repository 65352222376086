import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthService } from '../_services/auth.service';
import { NameValidator } from '../../validators/name.validator';
import { PasswordValidator } from '../../validators/password.validator';
import { CheckboxValidator } from '../../validators/checkbox.validator';
import { EmailValidator } from '../../validators/email.validator';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
    /** Is something loading, used for loading bar */
    isLoading = new BehaviorSubject(false);
    /** Registration form */
    registrationForm = new FormGroup(
        {
            firstName: new FormControl('', NameValidator.isFirstNameValid(true)),
            lastName: new FormControl('', NameValidator.isLastNameValid(true)),
            email: new FormControl('', EmailValidator.isValid(true)),
            password: new FormControl('', PasswordValidator.isValid()),
            cPassword: new FormControl('', Validators.required),
            agree: new FormControl(false, CheckboxValidator.isChecked),
        },
        PasswordValidator.MatchPassword,
    );
    /** Controls which alert should be shown */
    errorType: number = undefined;
    buttonClick: boolean;

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        // redirect to home if already logged in
        if (this.authService.currentUser.value) {
            this.router.navigate(['/']);
        }
    }

    checkValid() {
        this.buttonClick = true;
        this.registrationForm.controls.agree.updateValueAndValidity();
    }

    ngOnInit(): void {
        this.loadForm();
    }

    loadForm() {
        const email = this.route.snapshot.queryParams.email;
        if (email) {
            this.registrationForm.controls.email.setValue(email);
        }
    }

    submit() {
        this.errorType = undefined;
        const newUser = Object.assign({}, this.registrationForm.value) as UserModel;
        this.isLoading.next(true);
        this.authService
            .register(newUser)
            .pipe(finalize(() => this.isLoading.next(false)))
            .subscribe({
                next: () => {
                    this.router.navigate(['/auth/login'], {
                        queryParams: {
                            registered: true,
                            email: this.registrationForm.controls.email.value,
                        },
                    });
                },
                error: (err) => {
                    switch (err.error[0]) {
                        case "User name '" + newUser.email + "' is already taken.": {
                            this.errorType = 0;
                            break;
                        }
                        default: {
                            this.errorType = 1;
                        }
                    }
                },
            });
    }
}
