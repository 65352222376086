export class UpperCaseConverter {
    static convertToUppercase(toConvert: string): string {
        if (toConvert === null || toConvert === undefined) {
            return undefined;
        }
        const letters = toConvert.split('');
        let result = '';
        letters.forEach((c, i) => {
            if (c !== '_') {
                if (c === c.toUpperCase() && isNaN(+c) && i !== 0) {
                    result += '_';
                }
                result += c.toUpperCase();
            }
        });
        return result;
    }
}
