<app-input-wrapper
    [inputControl]="inputControl"
    [labelStyle]="labelStyle"
    [labelClass]="labelClass"
>
    <div class="checkbox-inline">
        <label class="checkbox">
            <input
                type="checkbox"
                [formControl]="inputControl"
                [attr.disabled]="disabled ? '' : null"
            />
            <span></span>
            <ng-container *ngIf="labelTemplate; else textLabel">
                <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
            </ng-container>
        </label>
    </div>
</app-input-wrapper>

<ng-template #textLabel> {{ label | translate }}</ng-template>
