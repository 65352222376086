export const CREDIT_ALERT_LIMIT = {
    CHANGE: {
        TITLE: 'Změna limitu pro upozornění',
        LIMIT: 'Limit',
        MESSAGES: {
            ERROR_LOAD: 'Napodařilo se načíst aktuální limit',
            ERROR_CHANGE: 'Nepodařilo se změnit limit',
            SUCCESS_CHANGE: 'Limit byl úspěšně změněn',
        },
    },
};
