import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-environment-identifier',
    templateUrl: './environment-identifier.component.html',
    styleUrls: ['./environment-identifier.component.scss'],
})
export class EnvironmentIdentifierComponent implements OnInit {
    environmentIdentifierName: string = '';
    constructor() {}

    ngOnInit(): void {
        switch (environment.ENVIRONMENT_IDENTIFIER) {
            case 'development':
                this.environmentIdentifierName = 'Development verze';
                break;
            case 'stage':
                this.environmentIdentifierName = 'Interní verze';
                break;
            default:
                this.environmentIdentifierName = '';
                break;
        }
    }
}
