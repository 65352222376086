// Localization is based on '@ngx-translate/core';
// Please be familiar with official documentations first => https://github.com/ngx-translate/core

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface Locale {
    lang: string;
    data: any;
}

const LOCALIZATION_LOCAL_STORAGE_KEY = 'language';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    // Private properties
    private langIds: any = [];

    constructor(private translate: TranslateService) {
        // add new langIds to the list
        this.translate.addLangs(['cs']);

        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('cs');
    }

    loadTranslations(...args: Locale[]): void {
        const locales = [...args];

        locales.forEach((locale) => {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            this.translate.setTranslation(locale.lang, locale.data, true);

            this.langIds.push(locale.lang);
        });

        // add new languages to the list
        this.translate.addLangs(this.langIds);
    }

    setLanguage(lang) {
        if (lang) {
            this.translate.use(this.translate.getDefaultLang());
            this.translate.use(lang);
            localStorage.setItem(LOCALIZATION_LOCAL_STORAGE_KEY, lang);
        }
    }

    /**
     * Returns selected language
     */
    getSelectedLanguage(): any {
        const storedLang = localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY);
        if (storedLang) {
            return storedLang;
        } else {
            this.setLanguage(this.translate.getDefaultLang());
            return this.translate.getDefaultLang();
        }
    }
}
