import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { NotificationService } from '../../notification/_services/notification.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    /** Is something loading, used for loading bar */
    isLoading = new BehaviorSubject(false);
    /** Main form */
    loginForm = new FormGroup({
        email: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
    });
    /** Controls which error alert should be showed */
    errorType: number;

    /** Url from query params for redirecting after successful login */
    returnUrlParam: string;
    /** Controls if registered alert should be shown */
    registeredParam: string;
    /** Controls if email confirmed alert should be shown */
    confirmEmailParam: string;
    /** Controls if password changed alert should be shown */
    passwordResetParam: string;
    /** Controls if token expired alert should be shown */
    tokenExpiredParam: string;
    emailParam: string;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private notificationService: NotificationService,
    ) {
        // redirect to home if already logged in
        if (this.authService.currentUser.value) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit(): void {
        // Get return url from route parameters or default to '/'
        this.returnUrlParam = this.route.snapshot.queryParamMap.get('returnUrl');
        this.registeredParam = this.route.snapshot.queryParamMap.get('registered');
        this.confirmEmailParam = this.route.snapshot.queryParamMap.get('emailConfirmed');
        this.passwordResetParam = this.route.snapshot.queryParamMap.get('resetPassword');
        this.tokenExpiredParam = this.route.snapshot.queryParamMap.get('tokenExpired');
        this.emailParam = this.route.snapshot.queryParamMap.get('email');
    }

    sendConfirmEmail(email: string = undefined) {
        this.authService.resendVerificationEmail(email ?? this.loginForm.value.email).subscribe({
            next: () => {
                this.notificationService.createNotification({
                    type: 'success',
                    autoClose: false,
                    dismissible: true,
                    message: 'AUTH.MESSAGES.VERIFICATION_EMAIL_RESENT',
                    messageParams: { email: this.loginForm.value.email },
                });
            },
            error: () => {
                this.notificationService.createNotification({
                    type: 'error',
                    autoClose: false,
                    dismissible: true,
                    message: 'AUTH.MESSAGES.VERIFICATION_EMAIL_RESENT_ERROR',
                    messageParams: { email: this.loginForm.value.email },
                });
            },
        });
    }

    /** Login button submit */
    submit() {
        this.errorType = undefined;
        const fv = this.loginForm.value;
        this.isLoading.next(true);
        this.authService
            .login(fv.email, fv.password)
            .pipe(finalize(() => this.isLoading.next(false)))
            .subscribe({
                next: () => {
                    this.router.navigate([this.returnUrlParam || '/']);
                },
                error: (error) => {
                    // If error_description is null return general error
                    if (!error.error?.error_description) {
                        this.errorType = 4;
                    } else {
                        // TODO - refactor this, check error based on number not string
                        switch (error.error.error_description) {
                            case "The user's account is blocked": {
                                this.errorType = 1;
                                break;
                            }
                            case 'The username/password couple is invalid.': {
                                this.errorType = 2;
                                break;
                            }
                            case "The user's email is not confirmed": {
                                this.errorType = 3;
                                break;
                            }
                            default: {
                                this.errorType = 4;
                                break;
                            }
                        }
                    }
                },
            });
    }
}
