import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthComponent } from './auth.component';
import { TranslationModule } from '../translation/translation.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EnvironmentIndentifierModule } from '../environment-identifier/environment-indentifier.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoadingModule } from '../loading/loading.module';
import { ServicePermissionsTableComponent } from './_components/service-permissions-table/service-permissions-table.component';
import { MatTableModule } from '@angular/material/table';
import { SkeletonLoadingModule } from '../skeleton-loading/skeleton-loading.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceGroupsTableComponent } from './_components/service-groups-table/service-groups-table.component';
import { DisableLinkDirective } from '../directives/disable-link.directive';
import { DirectivesModule } from '../directives/directives.module';
import { AnseaTableModule } from '../ansea-table/ansea-table.module';
import { InputWrappersModule } from '../forms/input-wrappers.module';
import { RolesLabelsStandaloneComponent } from '../user-management/roles/_components/roles-labels/roles-labels.component';

@NgModule({
    declarations: [
        LoginComponent,
        RegistrationComponent,
        ForgotPasswordComponent,
        LogoutComponent,
        AuthComponent,
        ConfirmEmailComponent,
        ResetPasswordComponent,
        ServicePermissionsTableComponent,
        ServiceGroupsTableComponent,
    ],
    imports: [
        CommonModule,
        TranslationModule,
        AuthRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        EnvironmentIndentifierModule,
        MatCheckboxModule,
        LoadingModule,
        MatTableModule,
        SkeletonLoadingModule,
        MatPaginatorModule,
        MatSortModule,
        NgbTooltipModule,
        DisableLinkDirective,
        DirectivesModule,
        AnseaTableModule,
        InputWrappersModule,
        RolesLabelsStandaloneComponent,
    ],
    exports: [ServicePermissionsTableComponent, ServiceGroupsTableComponent],
})
export class AuthModule {}
