<div class="modal-header">
    <div class="modal-title" [innerHtml]="title"></div>
    <button class="icon-button">
        <i class="flaticon2-cross" (click)="modal.dismiss()"></i>
    </button>
</div>

<div class="modal-body">
    <div [innerHtml]="message"></div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-light btn-elevate mr-1" (click)="this.modal.dismiss()">
        {{ "GENERAL.BUTTONS.OK" | translate }}
    </button>
</div>
