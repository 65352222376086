import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { getContrastColor } from '../../../_helpers/getContrastColor';
@Component({
    selector: 'app-colorpicker-input',
    templateUrl: './colorpicker-input.component.html',
    styleUrls: ['./colorpicker-input.component.scss'],
})
export class ColorpickerInputComponent implements OnInit {
    @Input() label: string = '';
    @Input() inputControl: FormControl;
    /** wide change input behavior on big screens */
    @Input() wide: boolean = false;
    /**Styling input using angular [style] */
    @Input() inputStyle: Record<string, string | undefined | null>;
    @Input() labelStyle: Record<string, string | undefined | null>;
    /**Class inputs for customization  */
    @Input() inputClass: 'string';
    @Input() labelClass: 'string';

    getContrastColor = getContrastColor;
    color: string;

    constructor() {}

    ngOnInit(): void {
        this.color = this.inputControl.value;
        // getContrastColor(this.color);
    }
    setColorInput(color: string) {
        this.inputControl.setValue(color);
    }
}
