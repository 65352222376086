import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputWrapperComponent } from './_components/input-wrapper/input-wrapper.component';
import { SelectInputComponent } from './_components/select-input/select-input.component';
import { TextInputComponent } from './_components/text-input/text-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatepickerInputComponent } from './_components/datepicker-input/datepicker-input.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorpickerInputComponent } from './_components/colorpicker-input/colorpicker-input.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { PasswordInputComponent } from './_components/password-input/password-input.component';
import { CheckboxInputComponent } from './_components/checkbox-input/checkbox-input.component';
import { SwitchInputComponent } from './_components/switch-input/switch-input.component';
import { TextareaInputComponent } from './_components/textarea-input/textarea-input.component';
import { InnerIconDirective } from './_directives/inner-icon.directive';
import { RadioInputComponent } from './_components/radio-input/radio-input.component';
@NgModule({
    declarations: [
        InputWrapperComponent,
        SelectInputComponent,
        TextInputComponent,
        DatepickerInputComponent,
        ColorpickerInputComponent,
        PasswordInputComponent,
        CheckboxInputComponent,
        SwitchInputComponent,
        InnerIconDirective,
        TextareaInputComponent,
        RadioInputComponent,
    ],
    imports: [
        TranslateModule,
        ReactiveFormsModule,
        CommonModule,
        NgSelectModule,
        FormsModule,
        NgbDatepickerModule,
        ColorPickerModule,
    ],
    exports: [
        InputWrapperComponent,
        SelectInputComponent,
        TextInputComponent,
        DatepickerInputComponent,
        ColorpickerInputComponent,
        PasswordInputComponent,
        CheckboxInputComponent,
        SwitchInputComponent,
        InnerIconDirective,
        TextareaInputComponent,
        RadioInputComponent,
    ],
})
export class InputWrappersModule {}
