import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UpperCaseConverter } from '../../upper-case-converter/upper-case-converter';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-confirm-changes-modal',
    templateUrl: './confirm-changes-modal.component.html',
    styleUrls: ['./confirm-changes-modal.component.scss'],
})
export class ConfirmChangesModalComponent implements OnInit {
    /** Values to be displayed, only non-equivalent will be shown */
    @Input() values: { controlName: string; oldValue: string; newValue: string }[] = [];
    /** Base path for `FormControl` names */
    @Input() translateBasePath: string = '';
    upperCaseConverter = UpperCaseConverter;

    constructor(public modal: NgbActiveModal) {}

    ngOnInit(): void {}

    confirm() {
        this.modal.close();
    }

    /**
     * @param initialValues Object with formControlNames as key, initial values as value
     * @param controls Form controls with new values
     * @description Parses and sets data to be displayed
     */
    setData(initialValues: Record<string, string>, controls: AbstractControl[]) {
        Object.keys(controls).forEach((control) => {
            this.values.push({
                controlName: control,
                oldValue: initialValues[control],
                newValue: controls[control]?.value,
            });
        });
    }
}
