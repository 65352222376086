import { animate, state, style, transition, trigger } from '@angular/animations';
import { ViewportRuler } from '@angular/cdk/scrolling';
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    QueryList,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Exporter, MatTableExporterDirective } from 'mat-table-exporter';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CustomDataSource } from 'src/app/modules/table/data-source';
import { CustomCellDirective } from '../../_directives/custom-cell.directive';
import { CustomHeaderCellDirective } from '../../_directives/custom-header-cell.directive';
import { TableColumn } from '../../_models/table.model';

/**
 * Resources:
 * https://stackblitz.com/edit/angular-datatable-responsive
 * https://benjamin-maisonneuve1.medium.com/smart-mat-table-part-1-reusable-customizable-52ef080f3586
 * https://stackblitz.com/edit/mat-table-with-injectable-column
 * https://www.c-sharpcorner.com/article/dynamically-loading-the-ng-template-from-its-name-in-angular-9/
 * https://benjamin-maisonneuve1.medium.com/multiple-content-projections-in-angular-cc65f72ba519
 */
@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class TableComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
    public MIN_COLUMN_WIDTH: number = 200;

    @Input() dataSource: CustomDataSource<unknown>;
    @Input() columns: TableColumn[];
    @Input() defaultPageSize: number = 20;
    @Input() initSkeletonBones: number;
    @Input() noCellPadding = false;

    disablePagination: boolean = false;
    visibleColumns: TableColumn[];
    hiddenColumns: TableColumn[];

    headerCellTemplates = new Map<string, TemplateRef<any>>();
    cellTemplates = new Map<string, TemplateRef<any>>();

    expandedElement = {};
    skeltonBonesCount = new BehaviorSubject<number>(5);

    @ViewChild(MatTableExporterDirective) exporter: Exporter<any>;
    @ViewChild(MatTable, { static: true }) dataTable: MatTable<Element>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    @ContentChildren(CustomCellDirective, { emitDistinctChangesOnly: false })
    customCellRefs!: QueryList<CustomCellDirective>;
    @ContentChildren(CustomHeaderCellDirective, { emitDistinctChangesOnly: false })
    customHeaderCellRefs!: QueryList<CustomHeaderCellDirective>;

    get visibleColumnsIds() {
        const visibleColumnsIds = this.visibleColumns.map((column) => column.id);
        return this.hiddenColumns.length ? [...visibleColumnsIds, 'trigger'] : visibleColumnsIds;
    }

    get hiddenColumnsIds() {
        return this.hiddenColumns.map((column) => column.id);
    }

    private rulerSubscription: Subscription;
    constructor(
        private ruler: ViewportRuler,
        private zone: NgZone,
        private cdr: ChangeDetectorRef,
    ) {
        this.rulerSubscription = this.ruler.change(100).subscribe((data) => {
            // accessing clientWidth cause browser layout, cache it!
            // const tableWidth = this.table.nativeElement.clientWidth;
            this.toggleColumns(this.dataTable['_elementRef'].nativeElement.clientWidth);
        });
    }

    ngAfterViewInit() {
        /** transform the custom cell templates into a map for better access */
        this.customCellRefs
            ?.toArray()
            .forEach((template) => this.cellTemplates.set(template.name, template.template));
        this.customHeaderCellRefs
            ?.toArray()
            .forEach((template) => this.headerCellTemplates.set(template.name, template.template));
    }

    ngOnInit(): void {
        this.disablePagination = this.dataSource.isPaginationDisabled();
        if (this.initSkeletonBones) {
            this.skeltonBonesCount.next(this.initSkeletonBones);
        } else {
            this.skeltonBonesCount.next(this.defaultPageSize);
        }
    }
    ngOnDestroy(): void {
        this.rulerSubscription.unsubscribe();
    }

    ngAfterContentInit() {
        this.toggleColumns(this.dataTable['_elementRef'].nativeElement.clientWidth);

        this.sort.sortChange.subscribe((event) => {
            /** Fire when sort is changed */
            this.dataSource.changeSort(event.active, event.direction);
            this.expandedElement = {};
            /**This is for nicer skeleton loading */
            if (
                this.dataSource &&
                this.dataSource.totalCount > 0 &&
                this.dataSource.totalCount < this.defaultPageSize
            ) {
                this.skeltonBonesCount.next(this.dataSource.totalCount);
            }
            this.cdr.detectChanges();
        });
        this.paginator.page.subscribe((event) => {
            if (this.disablePagination) {
                return;
            }
            /** Fire when paginator is changed */
            this.dataSource.changePage(event);
            const skeletonCount = event.length < event.pageSize ? event.length : event.pageSize;

            this.skeltonBonesCount.next(skeletonCount);
            this.expandedElement = {};

            this.cdr.detectChanges();
        });
    }

    toggleColumns(tableWidth: number) {
        /**Fire on screen resize */
        this.zone.runOutsideAngular(() => {
            const sortedColumns = this.columns
                .slice()
                .map((column, index) => ({ ...column, order: index }))
                .sort((a, b) => a.hideOrder - b.hideOrder)
                .filter((column) => !column.hideColumn);

            for (const column of sortedColumns) {
                const columnWidth = column.width ? column.width : this.MIN_COLUMN_WIDTH;

                if (column.hideOrder && tableWidth < columnWidth) {
                    column.visible = false;

                    continue;
                }

                tableWidth -= columnWidth;
                column.visible = true;
            }

            this.columns = sortedColumns.sort((a, b) => a.order - b.order);
            this.visibleColumns = this.columns.filter((column) => column.visible);
            this.hiddenColumns = this.columns.filter((column) => !column.visible);
        });

        this.cdr.detectChanges();
    }
}
