<div class="modal-header">
    <div class="modal-title h4">
        {{ "COMPONENTS.MODALS.CONFIRM_CHANGES.TITLE" | translate }}
    </div>
    <button class="icon-button">
        <i class="flaticon2-cross" (click)="modal.dismiss()"></i>
    </button>
</div>
<div class="modal-body overflow-auto">
    <table class="table">
        <thead>
            <tr>
                <th>{{ "COMPONENTS.MODALS.CONFIRM_CHANGES.FIELD" | translate }}</th>
                <th>{{ "COMPONENTS.MODALS.CONFIRM_CHANGES.OLD_VALUE" | translate }}</th>
                <th>{{ "COMPONENTS.MODALS.CONFIRM_CHANGES.NEW_VALUE" | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let value of values">
                <tr *ngIf="value.oldValue !== value.newValue">
                    <td>
                        {{
                            translateBasePath +
                                "." +
                                upperCaseConverter.convertToUppercase(value.controlName) | translate
                        }}
                    </td>
                    <td>
                        {{ value.oldValue }}
                    </td>
                    <td>
                        {{ value.newValue }}
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-light btn-elevate mr-1" (click)="modal.dismiss()">
        {{ "GENERAL.BUTTONS.CANCEL" | translate }}</button
    >&nbsp;
    <button type="button" class="btn btn-success btn-elevate" (click)="confirm()">
        {{ "GENERAL.BUTTONS.CONFIRM" | translate }}
    </button>
</div>
