import { Injectable } from '@angular/core';
import { CreateHotToastRef, HotToastService, ToastOptions } from '@ngneat/hot-toast';
import { TranslateService } from '@ngx-translate/core';
import {
    CreateNotificationModel,
    CreateOrUpdateNotificationModel,
    UpdateNotificationModel,
} from '../_models/notification.model';
@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private _notificationRefs: Map<string, CreateHotToastRef<unknown>> = new Map();

    constructor(private toastService: HotToastService, private translate: TranslateService) {}

    public success(message: string, messageParams?: any) {
        this.createNotification({
            message,
            messageParams,
            type: 'success',
            dismissible: true,
            duration: 5000,
        });
    }
    public error(message: string, messageParams?: any) {
        this.createNotification({
            message,
            messageParams,
            type: 'error',
            dismissible: true,
            duration: 5000,
        });
    }
    public createNotification(options: CreateNotificationModel) {
        const id = options.id || Math.random().toString(36);
        const defaultCreateOptions: ToastOptions<unknown> = {
            position: 'bottom-right',
            autoClose: options.autoClose ?? true,
            dismissible: options.dismissible ?? false,
        };
        if (!options.message && options.type === 'loading') {
            options.message = 'GENERAL.NOTIFICATION.LOADING';
        }
        const message = options.message
            ? this.translate.instant(options.message, options.messageParams)
            : undefined;

        if (!this._notificationRefs.has(id)) {
            let ref;
            switch (options.type) {
                case 'loading':
                    ref = this.toastService.loading(message, {
                        ...defaultCreateOptions,
                        autoClose: false,
                        role: 'status',
                    });
                    break;
                case 'error':
                    ref = this.toastService.error(message, defaultCreateOptions);
                    break;
                case 'info':
                    ref = this.toastService.info(message, defaultCreateOptions);
                    break;
                case 'success':
                    ref = this.toastService.success(message, defaultCreateOptions);
                    break;
                case 'warning':
                    ref = this.toastService.warning(message, defaultCreateOptions);
                    break;
                default:
                    ref = this.toastService.info(message, defaultCreateOptions);
                    break;
            }

            const _ref = ref.afterClosed.subscribe(() => {
                // TODO - is needed to unsubscribe?
                // TODO - check this
                this._notificationRefs.delete(id);
                _ref.unsubscribe();
            });

            this._notificationRefs.set(id, ref);
        }
    }
    public updateNotification(options: UpdateNotificationModel) {
        const ref = this._notificationRefs.get(options.id);
        const message = this.translate.instant(options.message, options?.messageParams);
        if (ref) {
            ref.updateMessage(message);
            ref.updateToast({
                type: options.type,
                dismissible: options.dismissible,
            });
            if (options.duration) {
                setTimeout(() => {
                    ref.close();
                }, options.duration);
            }
        }
    }
    public createOrUpdateNotification(options: CreateOrUpdateNotificationModel) {
        const ref = this._notificationRefs.get(options.id);
        if (ref) {
            this.updateNotification(options);
        } else {
            this.createNotification(options);
        }
    }
    public removeNotification(id: string) {
        const ref = this._notificationRefs.get(id);
        if (ref) {
            this._notificationRefs.delete(id);
        }
    }
}
