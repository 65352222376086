import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchFilter',
    standalone: true,
})
export class SearchFilterPipe implements PipeTransform {
    transform(value: any, query: string): any {
        if (!value) return null;
        if (!query) return value;
        query = query.toLowerCase();
        // TODO: Check how bad is performance
        return value.filter((data) => {
            return JSON.stringify(data).toLowerCase().includes(query);
        });

        return null;
    }
}
