export const MENU = {
    DASHBOARD: 'Přehled',
    USER_MANAGEMENT: 'Správa účtů',
    USER_MANAGEMENT_CH: {
        USERS: 'Uživatelé',
        ORGANIZATIONS: 'Organizace',
        EDIT_GROUP: 'Upravit skupinu',
        GROUPS: 'Skupiny',
    },
    DOMAIN_MANAGEMENT: 'Správa domén',
    DOMAIN_MANAGEMENT_CH: {
        DOMAINS: 'Domény',
        DOMAINS_CH: {
            LIST: 'Přehled domén',
            REGISTER: 'Registrovat doménu',
            TRANSFER: 'Převod domény',
            GENERATE_AUTH_ID: 'Vygenerovat AUTH-ID',
        },
        CONTACTS: 'Kontakty',
        CONTACTS_CH: {
            LIST: 'Přehled kontaktů',
            CREATE: 'Vytvořit kontakt',
            TRANSFER_IMPORT: 'Převést nebo importovat',
        },
        ORDERS: 'Objednávky',
        NSSETS: 'Nssety',
        CATCH_DOMAINS: 'Zachytávání domén',
    },
    DNS: 'Správa DNS',
    HOSTING: 'Hosting',
    HOSTING_CH: {
        BACK: 'Zpět na seznam',
        DETAIL: 'Detail hostingu',
    },
    VPS: 'Virtuální servery',
    VPS_CH: {
        LIST: 'Seznam serverů',
        CREATE: 'Vytvořit server',
        VIEW: 'Detail serveru',
    },
    MAILHOSTING: 'Mailhosting',
    TICKET_SYSTEM: 'Tikety',
    TICKET_SYSTEM_CH: {
        LIST: 'Seznam tiketů',
        CREATE: 'Vytvořit tiket',
        VIEW: 'Detail tiketu',
    },
};
