import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { Permission, PermissionService } from '../auth/_services/permission.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Hides element if user does not have the required permission.
 */
@Directive({
    selector: '[appIfPerm]',
})
export class IfPermDirective implements OnInit, OnDestroy {
    unsubscribe$ = new Subject<void>();
    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private permissionService: PermissionService,
        private cdr: ChangeDetectorRef,
    ) {}

    organizationId: string;
    groupId: string;
    ignoreCurrentPermissions: boolean = false;
    permission: Permission | Permission[];
    @Input()
    set appIfPermOrganizationId(val: string) {
        this.organizationId = val;
        this.run();
    }

    @Input()
    set appIfPermIgnoreCurrentPermissions(val: boolean) {
        this.ignoreCurrentPermissions = val;
        this.run();
    }
    @Input()
    set appIfPermGroupId(val: string) {
        this.groupId = val;
        this.run();
    }
    ngOnInit(): void {
        this.permissionService.currentPermissionsObservable
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.run();
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /** if multiple values, perform logical or */
    run() {
        if (!this.permission) {
            !this.viewContainer.get(0) && this.viewContainer.createEmbeddedView(this.templateRef);
            return;
        }
        if (
            this.permissionService.hasPermission(
                this.permission,
                this.organizationId,
                this.groupId,
                this.ignoreCurrentPermissions,
            )
        ) {
            if (!this.viewContainer.get(0)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        } else {
            this.viewContainer.clear();
        }
        this.cdr.detectChanges();
    }

    @Input()
    set appIfPerm(val: Permission[] | Permission) {
        this.permission = val;
        this.run();
    }
}
