export const GENERAL = {
    TRUE: 'Ano',
    FALSE: 'Ne',
    SELECT: {
        NO_ITEMS_FOUND: 'Žádné výsledky',
    },
    LINKS: {
        CONTACT_US: 'https://anafra.cz/kontaktujte-nas',
        ABOUT_US: 'https://anafra.cz/o-nas',
        TERMS_AND_CONDITIONS:
            'https://anafra.cz/o-spolecnosti/vseobecne-obchodni-podminky-pro-poskytovani-sluzeb',
    },
    INPUT: {
        PASSWORD: 'Heslo',
        LOGIN: 'Login',
        C_PASSWORD: 'Potvrzení hesla',
        ACTIONS: 'Akce',
        NO_ROWS: 'Žádné záznamy',
        BILLING: {
            MONTHLY: 'Měsíčně',
            QUARTERLY: 'Kvartálně',
            YEARLY: 'Ročně',
            MONTHLY_PRICE: 'Měsíční cena',
            ONE_TIME_PRICE: 'Jednorázová cena',
            PRICE: 'Cena',
            PRICE_SUMMARY: 'Celkem k zaplacení',
        },
        YEAR: 'Rok',
        MONTH: 'Měsíc',
        SEARCHING: 'Vyhledávání',
        YES: 'Ano',
        NO: 'Ne',
        SAVING: 'Ukládání',
        EMAIL: 'Email',
        NAME: 'Název',
        FIRST_NAME: 'Jméno',
        LAST_NAME: 'Příjmení',
        SERVICE: 'Služba',
        ORGANIZATION: 'Organizace',
        NOT_FOUND: 'Nenalezeno',
        ORGANIZATIONS: 'Organizace',
        SEARCH: 'Vyhledat',
        GROUP: 'Skupina',
        TABLE_LOAD_ERROR: 'Nepodařilo se načíst data',
    },
    LOADING: {
        MESSAGES: {
            SEND: 'Odesílání',
            CREATE: 'Vytváření',
            SAVE: 'Ukládání',
            UPDATE: 'Aktualizování',
        },
    },
    NOTIFICATION: {
        LOADING: 'Načítání',
    },
    COPIED_TO_CLIPBOARD: 'Zkopírováno do schránky',
    BUTTONS: {
        ADD: 'Přidat',
        CANCEL: 'Zrušit',
        UPDATE: 'Upravit',
        CLOSE: 'Zavřít',
        DELETE: 'Smazat',
        CREATE: 'Vytvořit',
        VIEW: 'Zobrazit',
        BACK: 'Zpět',
        PREVIOUS: 'Předchozí',
        NEXT: 'Další',
        SUBMIT: 'Odeslat',
        CONFIRM: 'Potvrdit',
        SAVE: 'Uložit',
        SAVE_CHANGES: 'Uložit změny',
        DISCARD_CHANGES: 'Zrušit změny',
        EXPORT: 'Export',
        ORDER: 'Objednat',
        OK: 'OK',
        TRY_AGAIN: 'Zkusit znovu',
        CONTINUE: 'Pokračovat',
        CHANGE_PASSWORD: 'Změnit heslo',
        CHANGE: 'Změnit',
    },
    MESSAGES: {
        TOKEN_EXPIRED: 'Přihlášení vypršelo, přihlaste se prosím znovu',
        LOAD_ERROR: 'Nepodařilo se načíst data',
        ACTION_SUCCESS: 'Akce se úspěšně zdařila',
        ACTION_ERROR: 'Akce se nezdařila',
    },
    PAGINATOR: {
        ITEMS_PER_PAGE_LABEL: 'Počet řádků:',
        NEXT_PAGE_LABEL: 'Další stránka',
        PREVIOUS_PAGE_LABEL: 'Předchozí stránka',
        FIRST_PAGE_LABEL: 'První stránka',
        LAST_PAGE_LABEL: 'Poslední stránka',
        OF: 'z',
    },
    TABLE: {
        COLUMNS: {
            ID: 'ID',
        },
        EXPAND: 'Rozbalit',
        EXPORT_TABLE: 'Exportovat tabulku',
        ROWS_SELECTED: 'Vybrané řádky ({{count}})',
    },
    ENTITY_CONNECTION: {
        GO_HOSTING: 'Přejít na hosting',
        GO_DNS: 'Přejít na DNS',
        GO_DOMAIN: 'Přejít na doménu',
        GO_MAILHOSTING: 'Přejít na mailhosting',
    },
    SERVICE_REQUEST: {
        TOOLTIP:
            'Tato služba se nespustí automaticky. Po objednání Vám bude služba vytvořena <b>do 1 pracovního dne</b>.',
        MODAL_SUCCESS: {
            TITLE: 'Objednávka byla úspěšně vytvořena.',
            MESSAGE: '<b>Do 1 pracovního dne</b> Vám bude služba zpřístupněna.',
        },
        MESSAGES: {
            SUCCESS: 'Objednávka vytvořena',
            ERROR: 'Nepodařilo se vytvořit objednávku',
        },
    },
    STATUS: 'Stav',
    USERS: 'Uživatelé',
    ADDRESS: {
        CITY: 'Město',
        ZIP: 'PSČ',
        STREET: 'Ulice',
        HOUSE_NUMBER: 'Číslo popisné',
        STREET_NUMBER: 'Číslo orientační',
    },
    SERVICES: {
        UNKNOWN: 'Neznámé',
        DOMAIN: 'Doména',
        DNS: 'DNS',
        HOSTING: 'Hosting',
        MAILHOSTING: 'Mailhosting',
        DOMAIN_CONTACT: 'Kontakt domény',
        VPS: 'VPS',
        TICKET: 'Tiket',
        ERROR: 'Nepodařilo se načíst tento typ služeb',
        DO: 'Doména',
        DN: 'DNS',
        HO: 'Hosting',
        MA: 'Mailhosting',
        DC: 'Kontakt domény',
        VM: 'VPS',
        TI: 'Tiket',
    },
};
