import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { NotificationService } from 'src/app/modules/notification/_services/notification.service';
import { TableColumn } from '../../../ansea-table/_models/table.model';
import { DeleteConfirmModalComponent } from '../../../modals/delete-modal/delete-confirm-modal.component';
import { DeleteModalConfig } from '../../../modals/delete-modal/_models/delete-modal-config.model';
import { CustomDataSource } from '../../../table/data-source';
import { EditUserRolesModalStandaloneComponent } from '../../../user-management/roles/_components/edit-user-roles-modal-standalone/edit-user-roles-modal-standalone.component';
import { ListModel } from '../../../user-management/_models/list.model';
import { GroupService } from '../../../user-management/_services/group.service';
import { RoleModel } from '../../_models/role.model';
import { ServicePermissionsTableModel } from '../../_models/service-permissions-table.model';
import { AuthService } from '../../_services/auth.service';
import { Permission, PermissionService } from '../../_services/permission.service';

@Component({
    selector: 'app-service-permissions-table',
    templateUrl: './service-permissions-table.component.html',
    styleUrls: ['./service-permissions-table.component.scss'],
})
export class ServicePermissionsTableComponent implements OnInit {
    @Input() serviceId: string;
    @Input() organizationId: string;
    fullData: ServicePermissionsTableModel[];
    columns: TableColumn[] = [
        {
            id: 'userFirstName',
            label: 'AUTH.SERVICE_PERMISSIONS.USER_FIRST_NAME',
            hideOrder: 0,
            width: 150,
        },
        {
            id: 'userLastName',
            label: 'AUTH.SERVICE_PERMISSIONS.USER_LAST_NAME',
            hideOrder: 0,
            width: 150,
        },
        { id: 'userEmail', label: 'USER_MANAGEMENT.INPUT.EMAIL', hideOrder: 2 },
        {
            id: 'roleNames',
            label: 'AUTH.SERVICE_PERMISSIONS.ROLE_NAMES',
            hideOrder: 1,
            disableSort: true,
        },
        {
            id: 'actions',
            label: 'GENERAL.INPUT.ACTIONS',
            hideOrder: 0,
            disableSort: true,
            width: 120,
        },
    ];
    filteredColumns = ['userFirstName', 'userLastName', 'userEmail'];
    dataSource = new CustomDataSource<ServicePermissionsTableModel>({ clientSideMode: true });

    constructor(
        private authService: AuthService,
        private groupService: GroupService,
        private modalService: NgbModal,
        private notificationService: NotificationService,
        public permissionEnforcingService: PermissionService,
    ) {}

    ngOnInit(): void {
        this.dataSource.createFetcher((f) =>
            this.authService.getServicePermissions(this.serviceId).pipe(
                map((sp) => {
                    this.fullData = sp.data;
                    return this.getTableData(sp);
                }),
            ),
        );
        this.dataSource.loadData();
    }

    deleteConnection(connection: ServicePermissionsTableModel) {
        if (connection.accessSource !== 'Direct') {
            return;
        }
        const config = {
            title: 'AUTH.SERVICE_PERMISSIONS.DELETE.TITLE',
            message: 'AUTH.SERVICE_PERMISSIONS.DELETE.MESSAGE',
            messageParams: {
                entityName: connection.accessEntityName ?? '',
                firstName: connection.userFirstName ?? '',
                lastName: connection.userLastName ?? '',
            },
            usage: 'DELETE',
        } as DeleteModalConfig;
        const modalRef = this.modalService.open(DeleteConfirmModalComponent);
        const component = modalRef.componentInstance as DeleteConfirmModalComponent;
        component.config = config;
        component.delete$.subscribe(() => {
            this.groupService
                .deleteUserFromGroup(connection.userId, connection.accessEntityId)
                .subscribe({
                    next: () => {
                        this.notificationService.success('AUTH.SERVICE_PERMISSIONS.DELETE.SUCCESS');
                    },
                    error: () => {
                        this.notificationService.error('AUTH.SERVICE_PERMISSIONS.DELETE.ERROR');
                    },
                });
        });
    }

    editConnection(connection: ServicePermissionsTableModel) {
        const modalRef = this.modalService.open(EditUserRolesModalStandaloneComponent);
        const component = modalRef.componentInstance as EditUserRolesModalStandaloneComponent;
        component.user = {
            id: connection.userId,
            firstName: connection.userFirstName,
            lastName: connection.userLastName,
            email: connection.userEmail,
        };
        component.entityId = this.serviceId;
        component.entityType = 'Service';
        component.saveUserRoles.subscribe({
            next: (roles: RoleModel[]) => {
                modalRef.dismiss();
                this.updateUserRoles(connection, roles);
            },
        });
    }

    updateUserRoles(connection: ServicePermissionsTableModel, roles: RoleModel[]) {
        this.groupService
            .changeUserGroupRoles(
                connection.userId,
                this.serviceId,
                roles.map((role) => role.id),
            )
            .subscribe({
                next: () => {
                    this.notificationService.success(
                        'USER_MANAGEMENT.ORGANIZATIONS.EDIT.GROUP_USERS.EDIT_USER_ROLES.SUCCESS',
                    );
                    this.dataSource.loadData();
                },
                error: () => {
                    this.notificationService.error(
                        'USER_MANAGEMENT.ORGANIZATIONS.EDIT.GROUP_USERS.EDIT_USER_ROLES.ERROR',
                    );
                },
            });
    }

    getTableData(allData: ListModel<ServicePermissionsTableModel>) {
        allData.data = allData.data.reduce((arr, item) => {
            const existing = arr.find((innerItem) => innerItem.userId === item.userId);
            if (existing) {
                const joinedRoles = [...existing.roles, ...item.roles];
                const uniqueRoles = [
                    ...new Map(joinedRoles.map((item) => [item.id, item])).values(),
                ];
                existing.roles = uniqueRoles;
            } else {
                arr.push(item);
            }
            return arr;
        }, []);
        allData.totalCount = allData.data.length;
        return allData;
    }

    getEntityLink(entity: ServicePermissionsTableModel) {
        if (entity.accessSource === 'Organization') {
            return '/user-management/organizations/edit/' + entity.accessEntityId;
        }
        if (entity.accessSource === 'Group') {
            return '/user-management/edit-group/' + entity.accessEntityId;
        }
        return null;
    }

    getEntityPermission(entity: ServicePermissionsTableModel): Permission {
        if (entity.accessSource === 'Organization') {
            return 'Identity_Organizations_Read_Organization';
        }
        if (entity.accessSource === 'Group') {
            return 'Identity_Groups_Read';
        }
        return null;
    }
}
