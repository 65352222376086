export const HOSTING_INFO = {
    TITLE: 'VPS pro Hosting',
    DETAILS: `<div class='font-size-lg'>
<h3 class='text-primary'><strong>Poskytuje bezpečný, profesionální, vyhrazený prostor pro Vaše webové projekty.</strong></h3>
Součástí VPS je speciálně navržený operační systém Cloud Linux pro správu sdíleného hostingu a administrátorské rozhraní.
<ul class='marginClass'>
<li>Cloud Linux - Bezpečná architektura oddělených kontejnerů pro každou doménu</li>
<li>Uživatelský SSH přístup do kontejneru s doménou</li>
<li>Pravidelné bezpečnostní aktualizace (opravy chyb v deprecated verzích PHP, které nejsou řešeny PHPnet vývojáři)</li>
<li>Neomezený přenos dat</li>
<li>Konfigurace služeb pomocí webové administrace
<ul class='marginUL'>
<li>Výběr verze PHP (5.6 – 7.3) a volba použitých PHP modulů</li>
<li>Generování Let's encrypt certifikátů</li>
<li>Snadná správa DNS, FTP, MySQL, e-mailů</li>
</ul>
</li>
<li>Součástí VPS je také mailhosting a vedení DNS záznamů na samostatných serverech</li>
</ul>
<h4 class='text-primary'><b>Důvody proč si vybrat VPS pro hosting u ANAFRA:</b></h4>
<ul class='marginClass'>
<li>Webová administrace je napojena na další služby, které jsou poskytovány na infrastruktuře ANAFRA odděleně od VPS, avšak jsou součástí ceny za službu
<ul class='marginUL'>
<li>DNS servery</li><li>Mailhosting</li><li>Monitoring</li>
</ul>
</li>
<li>Technická podpora administrátory ANAFRA</li>
</ul>
</div>`,
};
