<div class="overlay" *ngIf="loadingService.isLoading$ | async">
    <div class="spinner-wrapper">
        <div class="circle-wrapper">
            <app-loading-circle></app-loading-circle>
        </div>
    </div>
</div>
<div class="loaded-content">
    <ng-content></ng-content>
</div>
