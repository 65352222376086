import { ValidatorFn } from '@angular/forms';
import { GeneralValidator } from './general.validator';

export class NameValidator {
    /**
     * Validates users first name
     * @param required Will be first name required
     */
    public static isFirstNameValid(required: boolean): ValidatorFn {
        return GeneralValidator.isValid(required, 100);
    }

    /**
     * Validates users last name
     * @param required Will be last name required
     */
    public static isLastNameValid(required: boolean): ValidatorFn {
        return GeneralValidator.isValid(required, 100);
    }
}
