export const HYPER_V_INFO = {
    TITLE: 'VPS Hyper-V',
    DETAILS: `
    <div class='font-size-lg'>
    <ul style="width: 20em">
    <li>Primárně určeno pro běh Microsoft aplikací</li>
    <li>technologie Hyper-V</li>
    <li>webové rozhraní pro správu s možností konzole</li>
    <li>NVMe disky</li>
    <li>zálohování 1x denně do jiné lokality</li>
    <li>možnost online navýšení výkonu</li>
    <li>ke každé VPS 1x IPv4</li>
    <li>IPv6 rozsah na zákazníka /48</li>
    <li>internetová konektivita až 10Gbps</li>
    <li>vnitřní síť mezi jednotlivými VPS s konektivitou 10Gbps</li>
    <li>technologie umístěna v datových sálech se zálohovaným napájením, chlazením i konektivitou</li>
    <li>virtuální jádra jsou garantovaná</li>
    <li>CPU Xeon Gold 3,6GHz</li>
    <li>operační paměť je vyhrazená</li>
    <li>Anti DDoS ochrana</li>
    </ul>
    </div>`,
};
