import {
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';
import { SelectOption } from '../../_models/input-wrapper.model';
import { InputWrapperService } from '../../_services/input-wrapper.service';

@Component({
    selector: 'app-select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./select-input.component.scss'],
})
export class SelectInputComponent implements OnInit {
    /** Placeholder changes input placeholder*/
    @Input() placeholder: string = '';
    /** Input control */
    @Input() inputControl: FormControl;
    /** Label for input */
    @Input() label: string = '';
    /** wide change input behavior on big screens */
    @Input() wide: boolean = false;
    /** Options for select input */
    @Input() selectOptions: SelectOption[] = [];
    /** clearable directive of ng-select */
    @Input() clearable = true;
    /** toggle searchable option of ng-select */
    @Input() searchable = true;
    /**Styling input using angular [style] */
    @Input() inputStyle: Record<string, string | undefined | null>;
    @Input() labelStyle: Record<string, string | undefined | null>;
    /**Class inputs for customization  */
    @Input() inputClass: 'string';
    @Input() labelClass: 'string';
    @Input() disabled: boolean = false;
    /** (focus) event */
    @Output() focusEvent = new EventEmitter<void>();

    @ContentChild('rightContent', { read: TemplateRef }) rightContent: TemplateRef<any>;
    @ContentChild('optionTemplate', { read: TemplateRef }) optionTemplate: TemplateRef<any>;

    constructor(public iws: InputWrapperService, private config: NgSelectConfig) {
        this.config.appendTo = 'body';
    }

    ngOnInit(): void {}
}
