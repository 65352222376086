import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmailValidator } from '../../validators/email.validator';
import { AuthService } from '../_services/auth.service';
import { ActivatedRoute } from '@angular/router';

enum ErrorStates {
    NoError,
    NoUser,
    NoConfirm,
    BadRequest,
    CommunicationError,
}

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    /** Is something loading, used for loading bar */
    isLoading = new BehaviorSubject(false);
    /** Controls which alert should be shown */
    errorState: ErrorStates = undefined;
    /** Holds enum ErrorStates for use in confirm-email html */
    errorStates = ErrorStates;
    /** Forgot password form */
    forgotPasswordForm = new FormGroup({
        email: new FormControl('', EmailValidator.isValid(true)),
    });

    constructor(private authService: AuthService, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        const email = this.activatedRoute.snapshot.queryParams.email;
        if (email) {
            this.forgotPasswordForm.controls.email.setValue(email);
        }
    }

    /** Submit function, sends password reset email */
    submit() {
        const fv = this.forgotPasswordForm.value;
        this.errorState = undefined;
        this.isLoading.next(true);
        this.authService
            .forgotPassword(fv.email)
            .pipe(finalize(() => this.isLoading.next(false)))
            .subscribe({
                next: () => {
                    this.errorState = this.errorStates.NoError;
                },
                error: (error) => {
                    switch (error.error[0]) {
                        case 'No user': {
                            this.errorState = this.errorStates.NoUser;
                            break;
                        }
                        case 'No confirm': {
                            this.errorState = this.errorStates.NoError;
                            break;
                        }
                        default: {
                            this.errorState = this.errorStates.CommunicationError;
                            break;
                        }
                    }
                },
            });
    }
}
