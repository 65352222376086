import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/_services/auth.service';

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const authHeader = this.authService.getAuthHeader();
        request = request.clone({ headers: request.headers.set(authHeader.key, authHeader.value) });
        return next.handle(request);
    }
}
