import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalConfig } from './_models/delete-modal-config.model';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-delete-confirm-modal',
    templateUrl: './delete-confirm-modal.component.html',
    styleUrls: ['./delete-confirm-modal.component.scss'],
})
export class DeleteConfirmModalComponent {
    _config: DeleteModalConfig = {} as DeleteModalConfig;
    @Input()
    set config(value: DeleteModalConfig) {
        const temp = {};
        Object.keys(value).forEach((key) => {
            temp[key] = value[key] || '';
        });
        this._config = temp as DeleteModalConfig;
    }
    get config() {
        return this._config;
    }
    /**@deprecated use modal.result instead */
    delete$ = new Subject<void>();
    /**@deprecated use modal.result instead */
    cancel$ = new Subject<void>();

    constructor(public modal: NgbActiveModal) {}

    confirm() {
        this.delete$.next();
        this.delete$.complete();
        this.modal.close(true);
    }

    cancel() {
        this.cancel$.next();
        this.cancel$.complete();
        this.modal.close(false);
    }
}
