<label
    *ngIf="inputControl"
    class="form-group position-relative w-100"
    [style]="labelStyle"
    [class]="labelClass"
    [class.narrow-input]="hideErrorMessages"
    [ngClass]="{ validated: iws.isControlInvalidAndTouched(inputControl), row: wide }"
>
    <span
        *ngIf="label"
        class="form-label text-nowrap"
        [ngClass]="{ 'col-xl-3 col-lg-3 col-form-label': wide }"
    >
        {{ label | translate }}
        <span class="text-danger" *ngIf="iws.isRequired(inputControl)">*</span>
    </span>
    <div [ngClass]="{ 'col-lg-9 col-xl-6 ': wide, 'mt-2': !wide && label }">
        <ng-content></ng-content>
        <ng-container *ngIf="!hideErrorMessages">
            <div
                class="invalid-feedback position-absolute bg-white"
                *ngFor="let message of iws.getControlErrorMessages(inputControl)"
            >
                {{ message }}
            </div>
        </ng-container>
    </div>
</label>
