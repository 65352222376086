import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleModel } from 'src/app/modules/auth/_models/role.model';
import { BaseUserModel } from 'src/app/modules/auth/_models/user.model';
import { PermissionService } from '../../../../auth/_services/permission.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { SelectRolesStandaloneComponent } from '../../../_components/select-roles-standalone/select-roles-standalone.component';
import { RoleSource } from '../../../../auth/_models/user-entity-roles.model';
import { ModalDismissService } from '../../../../services/modal-dismiss.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-edit-user-roles-modal-standalone-modal',
    templateUrl: './edit-user-roles-modal-standalone.component.html',
    styleUrls: ['./edit-user-roles-modal-standalone.component.scss'],
})
export class EditUserRolesModalStandaloneComponent implements OnInit, OnDestroy {
    savingChanges = new BehaviorSubject(false);
    @Input() user: BaseUserModel;
    @Input() entityType: 'Organization' | 'Group' | 'Service';
    @Input() entityId: string;
    @Output() saveUserRoles = new EventEmitter<RoleModel[]>();
    dataLoaded = false;

    availableRoles: RoleModel[] = [];
    selectedRoleIds: string[] = [];
    otherRoleSources: RoleSource[] = [];
    unsubscribe$ = new Subject<void>();
    @ViewChild(SelectRolesStandaloneComponent) selectRolesComponent: SelectRolesStandaloneComponent;

    constructor(
        public activeModal: NgbActiveModal,
        private permissionService: PermissionService,
        private modalDismissService: ModalDismissService,
    ) {}
    save() {
        this.savingChanges.next(true);
        const roles = this.selectRolesComponent.getSelectedRoles();
        this.saveUserRoles.next(roles);
    }

    ngOnInit(): void {
        this.permissionService
            .getUserEntityRoles(this.user.id, this.entityId, this.entityType)
            .subscribe((roles) => {
                this.availableRoles = roles.availableRoles;
                this.selectedRoleIds = roles.entityRoleIds;
                this.otherRoleSources = roles.otherRoleSources;
                this.dataLoaded = true;
            });
        this.modalDismissService.redirect$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.activeModal.dismiss();
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
