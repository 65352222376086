import { AbstractControl } from '@angular/forms';
export class WhitespaceValidator {
    public static noTrimWhiteSpace(control: AbstractControl) {
        if (control && control.value) {
            /**regex matches strings without spaces on beginning and end any character in middle is accepted */
            const regExp = new RegExp(/^[^ \n]+(\s+[^ \n]+)*$/);
            if (!regExp.test(control.value)) {
                return { trimWhitespace: true };
            }
        }
        return null;
    }

    public static noWhiteSpace(control: AbstractControl) {
        if (control && control.value) {
            /**no whitespace is allowed */
            const regExp = new RegExp(/\s/);
            if (regExp.test(control.value)) {
                return { noWhitespace: true };
            }
        }
        return null;
    }
}
