import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UpperCaseConverter } from '../upper-case-converter/upper-case-converter';

@Injectable({
    providedIn: 'root',
})
export class ValidationErrorsService {
    defaultTranslatePath = 'VALIDATION';
    constructor(private translateService: TranslateService) {}

    getGenericErrorMessages(
        control: AbstractControl,
        prefix?: string,
        errorsToPrefix?: string[],
    ): string[] {
        const translatedErrors: string[] = [];
        if (control.errors == null) {
            return translatedErrors;
        }
        Object.keys(control.errors).forEach((error) => {
            if (prefix && (errorsToPrefix.length === 0 || errorsToPrefix?.includes(error))) {
                translatedErrors.push(
                    this.errorToGenericTranslation(error, control.errors[error], prefix),
                );
            } else {
                translatedErrors.push(this.errorToGenericTranslation(error, control.errors[error]));
            }
        });
        return translatedErrors;
    }

    private errorToGenericTranslation(error: string, params: Object, prefix?: string): string {
        error = UpperCaseConverter.convertToUppercase(error);
        if (prefix) {
            return this.translateService.instant(
                `${this.defaultTranslatePath}.${prefix}.${error}`,
                params,
            );
        }
        return this.translateService.instant(`${this.defaultTranslatePath}.${error}`, params);
    }
}
