import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PasswordValidator } from '../../validators/password.validator';
import { AuthService } from '../_services/auth.service';

enum ErrorStates {
    NoError,
    BadToken,
    CommunicationError,
}

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    /** Is something loading, used for loading bar */
    isLoading = new BehaviorSubject(false);
    /** Controls which alert should be shown */
    errorState: ErrorStates = undefined;
    /** Holds enum ErrorStates for use in confirm-email html */
    errorStates = ErrorStates;
    /** Reset password form */
    resetPasswordForm = new FormGroup(
        {
            password: new FormControl('', PasswordValidator.isValid()),
            cPassword: new FormControl('', Validators.required),
            agree: new FormControl(false, Validators.required),
        },
        PasswordValidator.MatchPassword,
    );

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
    ) {}

    ngOnInit(): void {}

    /** Submit function, resets password */
    resetPasswordSubmit() {
        const fv = this.resetPasswordForm.value;
        this.errorState = undefined;

        const userId = this.route.snapshot.queryParamMap.get('uid');
        const passResetToken = this.route.snapshot.queryParamMap.get('code');

        if (userId != null && passResetToken != null) {
            this.isLoading.next(true);
            this.authService
                .resetPassword(userId, passResetToken, fv.password, fv.cPassword)
                .pipe(finalize(() => this.isLoading.next(false)))
                .subscribe({
                    next: () => {
                        this.router.navigate(['/auth/login'], {
                            queryParams: { resetPassword: true },
                        });
                    },
                    error: (error) => {
                        switch (error.error) {
                            case 'Wrong credentials': {
                                this.errorState = this.errorStates.BadToken;
                                break;
                            }
                            case 'Bad token': {
                                this.errorState = this.errorStates.BadToken;
                                break;
                            }
                            default: {
                                this.errorState = this.errorStates.CommunicationError;
                            }
                        }
                    },
                });
        }
    }
}
