import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/** General validator used for easier creating of validators */
export class GeneralValidator {
    public static isValid(
        required: boolean = false,
        maxLength?: number,
        minLength?: number,
        validateFunctions?: Array<(control: AbstractControl) => any>,
    ): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const result = {} as ValidationErrors;
            const value = control.value;

            if (value && value !== '') {
                // Maximum length
                if (maxLength && value.length > maxLength) {
                    result.maxlength = { requiredLength: maxLength, actualLength: value.length };
                }
                // Minimum length
                if (minLength && value.length < minLength) {
                    result.minlength = { requiredLength: minLength, actualLength: value.length };
                }
                // Custom functions
                if (validateFunctions) {
                    validateFunctions.forEach((func) => Object.assign(result, func(control)));
                }
            } else {
                if (required) {
                    result.required = true;
                }
            }
            // if object is empty, then it's valid
            return Object.keys(result).length === 0 ? null : result;
        };
    }
}
