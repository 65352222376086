import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BaseRoleModel, RoleWithSourcesModel } from 'src/app/modules/auth/_models/role.model';
import { getContrastColor } from 'src/app/modules/_helpers/getContrastColor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewRoleModalComponent } from '../view-role-modal/view-role-modal.component';
import { AuthService } from '../../../../auth/_services/auth.service';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'app-roles-labels',
    templateUrl: './roles-labels.component.html',
    styleUrls: ['./roles-labels.component.scss'],
})
export class RolesLabelsStandaloneComponent implements OnInit {
    @Input() roles: BaseRoleModel[] = [];
    getContrastColor = getContrastColor;
    constructor(private modalService: NgbModal, private authService: AuthService) {}

    ngOnInit(): void {}

    showRole(role: BaseRoleModel) {
        const modalRef = this.modalService.open(ViewRoleModalComponent, { size: 'lg' });
        const component = modalRef.componentInstance as ViewRoleModalComponent;
        component.role = role as RoleWithSourcesModel;
        this.authService.getRole(role.id).subscribe((role) => {
            component.role = role;
        });
    }
}
