import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleSourceModel, RoleWithSourcesModel } from 'src/app/modules/auth/_models/role.model';
import { getContrastColor } from 'src/app/modules/_helpers/getContrastColor';
import { Permission } from '../../../../auth/_services/permission.service';
import { ModalDismissService } from '../../../../services/modal-dismiss.service';

@Component({
    selector: 'app-view-role-modal',
    templateUrl: './view-role-modal.component.html',
    styleUrls: ['./view-role-modal.component.scss'],
})
export class ViewRoleModalComponent implements OnInit {
    @Output() redirected$ = new EventEmitter<void>();
    @Input() role: RoleWithSourcesModel;
    getContrastColor = getContrastColor;

    constructor(
        public activeModal: NgbActiveModal,
        private modalDismissService: ModalDismissService,
    ) {}

    ngOnInit(): void {}

    redirected() {
        this.activeModal.dismiss();
        this.modalDismissService.redirect$.next();
    }

    getSourcePermission(source: RoleSourceModel): Permission {
        if (source.sourceType === 'Organization') {
            return 'Identity_Organizations_Read_Users';
        }
        return 'Identity_Groups_Edit_Users';
    }

    getSourceRoute(source: RoleSourceModel): string {
        if (source.sourceType === 'Organization') {
            return `/user-management/organizations/edit/${source.sourceId}/access/users`;
        }
        return `/user-management/edit-group/${source.sourceId}/users`;
    }
}
