import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-add-whatever-with-name-modal',
    templateUrl: './add-whatever-with-name-modal.component.html',
    styleUrls: ['./add-whatever-with-name-modal.component.scss'],
})
export class AddWhateverWithNameModalComponent implements OnInit {
    form = new FormGroup({
        name: new FormControl('', Validators.required),
    });
    @Input() title: string;
    @Output() mySubmit = new EventEmitter<string>();

    constructor(public modal: NgbActiveModal) {}

    ngOnInit(): void {}
}
