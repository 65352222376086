import { AbstractControl, ValidatorFn } from '@angular/forms';
import { GeneralValidator } from './general.validator';

export class EmailValidator {
    /** Validates email */
    public static isValid(required: boolean): ValidatorFn {
        const validateFunctions = [
            // Angular email validator doesn't validate email endings (.cz, .com, ...)
            (control: AbstractControl) => {
                return new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$').test(control.value)
                    ? null
                    : { email: true };
            },
        ];
        return GeneralValidator.isValid(required, 320, null, validateFunctions);
    }

    public static areCopiesValid(required: boolean): ValidatorFn {
        const validateFunctions = [
            (control: AbstractControl) => {
                const emailList = control.value.trim().split('\n');
                for (const email of emailList) {
                    if (!new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$').test(email)) {
                        return { copiesEmail: true };
                    }
                }
                return null;
            },
        ];
        return GeneralValidator.isValid(required, 320, null, validateFunctions);
    }
}
