export const DOMAINS = {
    LIST: {
        TITLE: 'Seznam domén',
        IN_DOMAIN_NAMES: 'názvu domény',
        IN_CONTACTS: 'kontaktu',
        IN_DNS: 'DNS',
        NO_DNS: 'Žádná',
        NO_ORGANIZATION: 'Žádná organizace',
        BUTTONS: {
            REGISTER: 'Registrovat doménu',
            TRANSFER: 'Převést doménu',
            SHOW_CONTACTS: 'Kontakty',
            GENERATE_AUTH_ID: 'Vygenerovat AUTH-ID',
            ORDERS: 'Objednávky',
            NSSETS: 'Nssety',
            CATCH_DOMAINS: 'Zachytávání domén',
        },
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst seznam domén',
        },
    },
    REGISTER: {
        TITLE: 'Registrace domény',
        DOMAIN_IS_NOT_AVAILABLE: 'Doména není volná, nebo je zakázaná',
        DOMAIN_IS_AVAILABLE: 'Doména je volná',
        SIMILAR_AVAILABLE_DOMAINS: 'Volné podobné domény: ',
        TLD_IS_NOT_SUPPORTED: 'Toto TLD není podporováno',
        ENTER_NSSET: 'Zadat NSSET ručně',
        ENTER_NAMESERVERS: 'Zadat nameservery ručně',
        VERIFYING: 'Ověřování',
        CONTACTS: {
            registrant: 'Registrátor',
            adminContact: 'Administrátor',
            techContact: 'Technický kontakt',
            billingContact: 'Fakturační kontakt',
        },
        WIZARD: {
            STEPS: {
                1: 'Doména',
                2: 'Nastavení',
                3: 'Fakturační údaje',
                4: 'Dokončení',
            },
        },
        SUMMARY: {
            DOMAIN_NAME: 'Doména',
            PERIOD: 'Počet let',
            REGISTRANT: 'Registrátorský kontakt',
            ADMIN_CONTACT: 'Administrátorský kontakt',
            TECH_CONTACT: 'Technický kontakt',
            BILLING_CONTACT: 'Fakturační kontakt',
            NSSET: 'NSSET',
            HOSTS: 'Nameservery',
            EU_LANGUAGE: 'EU jazyk',
            WHO_IS_PROXY: 'Skrýt kontakt ve Whois',
            VAT_ID_PASSPORT: 'DÍČ/Pas',
            AUTH_ID: 'AUTH-ID',
        },
        BUTTONS: {
            VERIFY_AVAILABILITY: 'Ověřit dostupnost',
            CREATE_CONTACT: 'Vytvořit nový',
            DELETE_NS_ROW: 'Odebrat',
            ADD_NS_ROW: 'Přidat NS server',
            REGISTER: 'Registrovat',
            DELETE_ADMIN_ROW: 'Odebrat',
            ADD_ADMIN_ROW: 'Přidat administrační kontakt',
            SHOW_DOMAIN: 'Zobrazit doménu',
            REGISTER_NEXT_DOMAIN: 'Registrovat další doménu',
            ADVANCED_SETTINGS: 'Pokročilé nastavení',
        },
        MESSAGES: {
            LOADING: 'Právě se provádí registrace domény',
            UNABLE_TO_VERIFY: 'Nepodařilo se ověřit dostupnout domény',
            SUCCESS_REGISTER: 'Objednávka na registraci domény byla úspěšně vytvořena',
            ERROR_REGISTER: 'Nepodařilo se vytvořit objednávku na registraci domény',
            SUCCESS: 'Doména byla úspěšně registrována',
            SUCCESS_DESC:
                'Doména {{domain}} byla úspěšně registrována. Objednávka byla vytvořena. ',
            ERROR: 'Nepodařilo se registrovat doménu',
            ERROR_DESC: 'Nepodařilo se registrovat doménu {{domain}}',
        },
    },
    VIEW: {
        TITLE: 'Detail domény',
        TITLE_WITH_NAME: 'Detail domény {{domainName}}',
        MESSAGES: {
            ERROR_LOAD_DOMAIN: 'Nepodařilo se načíst doménu',
        },
        TABS: {
            DETAILS: 'Detaily',
            EDIT: 'Editace',
            ACCESS: 'Přístupy',
        },
        SEND_AUTH_ID: {
            TITLE: 'Zaslání AUTH-ID',
            TEXT: 'Chcete zaslat email s autorizačním heslem majiteli domény?',
            BUTTONS: {
                SEND: 'Poslat',
            },
            MESSAGES: {
                SUCCESS: 'Úspěšně jsme zaslali AUTH-ID',
                ERROR: 'Nepodařilo se zaslat AUTH-ID',
            },
        },
    },
    EDIT: {
        TITLE: 'Úprava domény',
        TITLE_WITH_NAME: 'Úprava domény {{domainName}}',
        OTHER_SETTINGS: 'Nastavení',
        MESSAGES: {
            ERROR_LOAD_DOMAIN: 'Nepodařilo se načíst doménu',
            ERROR_EDIT_DOMAIN: 'Nepodařilo se vytvořit objednávku na úpravu domény',
            SUCCESS_EDIT_DOMAIN: 'Objednávka na úpravu domény byla úspěšně vytvořena',
        },
    },
    ACCESS: {
        TITLE: 'Uživatelé s přístupem k doméně',
        GROUPS: {
            TITLE: 'Skupiny, ve kterých je doména',
        },
    },
    ADD_USER: {
        TITLE: 'Přidat uživatele k doméně',
        SUCCESS: 'Uživatel byl úspěšně přidán k doméně',
        ERROR: 'Nepodařilo se přidat uživatele k doméně',
    },
    EDIT_USER: {
        TITLE: 'Uživatel s přístupem k doméně',
    },
    TRANSFER: {
        TITLE: 'Převod domény',
        BUTTONS: {
            TRANSFER: 'Převést',
        },
        MESSAGES: {
            ERROR_GET_PRICES:
                'Nepodařilo se vytvořit objednávku pro převod domény z důvodu nepovedení načtení ceny',
            ERROR_TRANSFER: 'Nepodařilo se vytvořit objednávku pro převod domény',
            ERROR_LOAD_CONTACTS: 'Nepodařilo se načíst kontakty',
            SUCCESS_TRANSFER: 'Objednávka na převod domény byla úspěšně vytvořena.',
            LOADING: 'Právě se provádí převod domény',
        },
        CONFIRM_MODAL: {
            TITLE: 'Převod domény',
            DO_YOU_WANT:
                'Společně s převodem je nutné prodloužit doménu o 1 rok, což bude stát {{price}} KČ. Chcete přesto převést doménu?',
        },
        WIZARD: {
            STEPS: {
                1: 'Doména',
                2: 'Nastavení',
                3: 'Fakturační údaje',
                4: 'Dokončení',
            },
            CONTEXT: {
                SUCCESS: 'Byla vytvořena objednávka na převod domény',
                SUCCESS_DESC: 'Můžete si ji zobrazit',
                ERROR: 'Nepodařilo se vytvořit objednávku na převod domény',
                ERROR_DESC: '',
                SHOW_ORDER: 'Zobrazit objednávku',
                TRANSFER_NEXT: 'Převést další doménu',
            },
        },
    },
    GENERATE_AUTH_ID: {
        TITLE: 'Vygenerovat AUTH-ID',
        TEXT_AREA_LABEL: 'Seznam domén, pro které vygenerovat AUTH-ID',
        EVERY_DOMAIN_ON_OWN_ROW: 'Každou doménu zapište na vlastní řádek',
        INVALID: 'Nezadali jste doménu nebo nějaká doména není validní',
        BUTTONS: {
            SEND_AUTH_ID: 'Zaslat AUTH-ID',
        },
        MESSAGES: {
            ERROR_SEND: 'Nepodařilo se zaslat AUTH-ID pro domény: "{{domainNames}}"',
            SUCCESS_SEND: 'Všechna AUTH-ID byla úspěšně poslána',
        },
        VALIDATION: {
            DOMAINS: {
                REQUIRED: '',
                INVALID: 'Některá z domén není správně zapsána',
                ALLOWED_TLD: 'Pro zadané TLD nelze zaslat AUTH-ID',
            },
        },
    },
    RENEW: {
        TITLE: 'Prodloužení domény',
        MESSAGES: {
            SUCCESS_RENEW: 'Objednávka na prodloužení domény byla vytvořena',
            ERROR_RENEW: 'Nepodařilo se vytvořit objednávku na prodloužení domény',
        },
    },
};
