import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ForbiddenHttpInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 403) {
                    if (this.bypass(req)) {
                        return throwError(() => error);
                    }
                    console.log('403', req.url);
                    this.router.navigate(['/error/error-1'], { replaceUrl: true });
                    return EMPTY;
                }
                return throwError(() => error);
            }),
        );
    }
    bypass(request: HttpRequest<any>): boolean {
        let isBypass = false;
        const exceptions = [
            '/console/getConsole',
            '/vm/getTasks',
            '/mysql/getAllContainerDbSize',
            '/hosting/ftp/getFtpDirectories/',
            '/users/email',
        ];
        const url = request.url;
        exceptions.forEach((exception) => {
            if (url.includes(exception)) {
                console.log('Bypassing request: ' + url);
                isBypass = true;
            }
        });
        return isBypass;
    }
}
