import { Injectable, Injector } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../auth/_services/auth.service';
import { BehaviorSubject, concat, delayWhen, EMPTY, forkJoin, Observable } from 'rxjs';
import {
    CreateOrganizationModel,
    OrganizationListModel,
    OrganizationModel,
} from '../_models/organization.model';
import { ListModel } from '../_models/list.model';
import { SortLimitModel } from '../_models/sort-limit.model';
import { UserModel } from '../../auth/_models/user.model';
import { PermissionService } from '../../auth/_services/permission.service';
import { ITableFiltration } from '../../ansea-table/_models/table.model';

const API_ORGANIZATIONS_URL = `${environment.apiUrl}/organizations`;
const API_USER_ORGANIZATIONS_URL = `${environment.apiUrl}/userOrganizations`;

@Injectable({
    providedIn: 'root',
})
export class OrganizationService {
    public editedOrganization = new BehaviorSubject<OrganizationModel>(undefined);

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private injector: Injector,
    ) {}

    getOrganizationsList(
        sortLimitModel?: SortLimitModel,
        filtration?: ITableFiltration,
    ): Observable<ListModel<OrganizationListModel>> {
        let params = {} as any;
        if (filtration) {
            params = Object.assign(
                {
                    nameFilter: filtration.name,
                },
                params,
            ) as any;
        }
        if (sortLimitModel) {
            params = Object.assign(sortLimitModel, params);
        }
        return this.http.get<ListModel<OrganizationListModel>>(`${API_ORGANIZATIONS_URL}`, {
            params,
            headers: this.getHttpHeaders(),
        });
    }
    getOrganization(id: string): Observable<OrganizationModel> {
        return this.http.get<OrganizationModel>(`${API_ORGANIZATIONS_URL}/${id}`, {
            headers: this.getHttpHeaders(),
        });
    }

    createOrganization(organization: CreateOrganizationModel) {
        const permissionService = this.injector.get(PermissionService);
        const body = {
            ...organization,
        };
        return this.http
            .post(`${API_ORGANIZATIONS_URL}`, body, { headers: this.getHttpHeaders() })
            .pipe(
                // requests are cancelled when there is a redirection after observable completes so it has to wait for them
                delayWhen(() =>
                    forkJoin([
                        this.authService.getCurrentlyLoggedUser(),
                        permissionService.refreshOrganizations(),
                    ]),
                ),
            ) as Observable<OrganizationModel>;
    }

    updateOrganization(organization: OrganizationModel): Observable<any> {
        return this.http.put<OrganizationModel>(
            `${API_ORGANIZATIONS_URL}/${organization.id}`,
            organization,
            {
                headers: this.getHttpHeaders(),
            },
        );
    }

    getUserOrganizations(userId: string): Observable<Array<OrganizationModel>> {
        return this.http.get<Array<OrganizationModel>>(
            `${API_USER_ORGANIZATIONS_URL}/organizations/${userId}`,
            {
                headers: this.getHttpHeaders(),
            },
        );
    }

    getOrganizationUsers(
        organizationId: string,
        sortLimitModel: SortLimitModel,
        filterValue: ITableFiltration,
    ): Observable<ListModel<UserModel>> {
        const params = Object.assign(
            {
                filtration: filterValue.filtration,
            },
            sortLimitModel,
        ) as any;
        return this.http.get<ListModel<UserModel>>(
            `${API_USER_ORGANIZATIONS_URL}/users/${organizationId}`,
            {
                params,
                headers: this.getHttpHeaders(),
            },
        );
    }

    getOrganizationOption(organization: Pick<OrganizationModel, 'id' | 'name'>) {
        if (!organization) {
            return '';
        }
        if (!organization?.name) {
            organization.name = 'N/A';
        }
        return `${organization.name} - ${organization.id.slice(0, 6)}`;
    }

    public changeUserOrganization(
        userId: string,
        startingOrganizations: Array<string>,
        organizations: Array<string>,
    ): Observable<any> {
        if (!startingOrganizations) {
            startingOrganizations = new Array<string>();
        }
        if (!organizations) {
            organizations = new Array<string>();
        }
        // Gets difference between startingOrganizations and new organizations
        const organizationsToAdd = organizations.filter(
            (item) => startingOrganizations.indexOf(item) < 0,
        );
        const organizationsToRemove = startingOrganizations.filter(
            (item) => organizations.indexOf(item) < 0,
        );

        const observables = new Array<Observable<any>>(); // Array of observables for sync of requests

        // Adds requests for adding organizations from user
        for (const r of organizationsToAdd) {
            const body = {
                userId,
                organizationId: r,
            };
            observables.push(
                this.http.post(`${API_USER_ORGANIZATIONS_URL}`, body, {
                    headers: this.getHttpHeaders(),
                }),
            );
        }

        // Adds requests for removing organizations from user
        for (const r of organizationsToRemove) {
            const options = {
                headers: this.getHttpHeaders(),
            };
            observables.push(
                this.http.delete(`${API_USER_ORGANIZATIONS_URL}/${userId}/${r}`, options),
            );
        }

        if (observables.length === 0) {
            return EMPTY;
        }
        return concat(...observables); // Call all requests
    }

    changeUserOrganizationRoles(userId: string, organizationId: string, roleIds: string[]) {
        return this.http.put(
            `${API_USER_ORGANIZATIONS_URL}/${userId}/${organizationId}`,
            { roleIds },
            { headers: this.getHttpHeaders() },
        );
    }

    private getHttpHeaders(): HttpHeaders {
        return new HttpHeaders({
            accept: 'application/json',
            Authorization: 'Bearer ' + this.authService.getToken(),
        });
    }

    deleteUserFromOrganization(userId, organizationId) {
        return this.http.delete(`${API_USER_ORGANIZATIONS_URL}/${userId}/${organizationId}`, {
            headers: this.getHttpHeaders(),
        });
    }

    deleteOrganization(organizationId: string) {
        return this.http.delete(`${API_ORGANIZATIONS_URL}/${organizationId}`, {
            headers: this.getHttpHeaders(),
        });
    }

    getUserFromOrganization(userId: string, organizationId?: string) {
        let route = `${API_USER_ORGANIZATIONS_URL}/user/${userId}`;
        if (organizationId) {
            route = `${API_USER_ORGANIZATIONS_URL}/${organizationId}/${userId}`;
        }
        return this.http.get<UserModel>(route);
    }
}
