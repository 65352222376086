<div class="modal-header flex-column align-content-center">
    <button class="icon-button align-self-end" (click)="modal.dismiss()">
        <i class="flaticon2-cross"></i>
    </button>
    <i class="fa fa-6x mx-auto my-8" [ngClass]="classes"></i>
    <div class="text-center" [innerHtml]="message"></div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-light btn-elevate mr-1" (click)="this.modal.dismiss()">
        {{ "GENERAL.BUTTONS.OK" | translate }}
    </button>
</div>
