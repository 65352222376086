import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { InputWrapperService } from '../../_services/input-wrapper.service';

@Component({
    selector: 'app-datepicker-input',
    templateUrl: './datepicker-input.component.html',
    styleUrls: ['./datepicker-input.component.scss'],
})
export class DatepickerInputComponent implements OnInit {
    model: NgbDateStruct;
    /** Placeholder changes input placeholder*/
    @Input() placeholder: string = '';
    /** Input type (text,password,number,etc...) */
    @Input() inputControl: FormControl;
    /** wide change input behavior on big screens */
    @Input() wide: boolean = false;
    /** Label for input */
    @Input() label: string = '';
    /** Disable input */
    @Input() disabled: boolean = false;
    /** Emit date select event */
    @Output() dateSelect = new EventEmitter<NgbDateStruct>();
    /**Styling input using angular [style] */
    @Input() inputStyle: Record<string, string | undefined | null>;
    @Input() labelStyle: Record<string, string | undefined | null>;
    /**Class inputs for customization  */
    @Input() inputClass: 'string';
    @Input() labelClass: 'string';

    constructor(public iws: InputWrapperService) {}

    ngOnInit(): void {}

    onDateSelect(event: NgbDateStruct): void {
        this.dateSelect.emit(event);
    }
}
