<div class="card-header d-flex justify-content-between">
    <div class="align-items-start flex-column w-100">
        <h3 class="card-label font-weight-bolder text-dark">
            {{ "USER_MANAGEMENT.ORGANIZATIONS.EDIT.ROLES.MODAL.SHOW_ROLE_TITLE" | translate }}
        </h3>
        <span
            class="label label-inline label-xl"
            [ngStyle]="{
                'background-color': '#' + (role?.color || '3699FF'),
                color: getContrastColor(role?.color)
            }"
        >
            {{ role?.name }}
        </span>
    </div>
    <button class="icon-button align-self-start mt-4">
        <i class="flaticon2-cross" (click)="activeModal.dismiss()"></i>
    </button>
</div>
<div
    *ngIf="role?.sources && role?.sources.length > 0"
    class="w-100 d-flex flex-column align-items-stretch card-header border-bottom-0"
>
    <h5>
        {{ "COMPONENTS.MODALS.VIEW_ROLE.SOURCES" | translate }}
    </h5>
    <div
        *ngFor="let s of role?.sources"
        class="d-flex flex-row p-2"
        style="border: 1px lightgray dashed; border-radius: 5px"
    >
        <span [ngSwitch]="s.sourceType" class="align-self-center">
            <span
                *ngSwitchCase="'Organization'"
                [inlineSVG]="'assets/media/svg/icons/Home/Building.svg'"
                [cacheSVG]="true"
                class="svg-icon svg-icon-2x"
            >
            </span>
            <span
                *ngSwitchDefault
                [inlineSVG]="'assets/media/svg/icons/General/Group.svg'"
                [cacheSVG]="true"
                class="svg-icon svg-icon-2x"
            >
            </span>
        </span>
        <span class="ml-4">
            <div>
                <h5 class="p-0 m-0">
                    {{ s.sourceName }}
                </h5>
                <span>
                    {{ "GENERAL.INPUT." + s.sourceType.toUpperCase() | translate }}
                </span>
            </div>
        </span>
        <div class="ml-auto align-self-center">
            <button
                *appIfPerm="getSourcePermission(s)"
                class="btn btn-sm btn-secondary"
                (click)="redirected()"
                [routerLink]="getSourceRoute(s)"
            >
                {{ "COMPONENTS.MODALS.VIEW_ROLE.MANAGE_ACCESS" | translate }}
            </button>
        </div>
    </div>
</div>
<app-view-role *ngIf="role?.permissions; else loading" [role]="role"></app-view-role>
<ng-template #loading>
    <div class="mx-5">
        <app-skeleton-loading [count]="20"></app-skeleton-loading>
    </div>
</ng-template>
