export const HOSTINGS = {
    LIST: {
        TITLE: 'Seznam hostingů',
        IN_HOSTING_NAMES: 'názvu hostingu',
        NEW_HOSTING_BUTTON: 'Vytvořit hosting',
        BUTTONS: {
            RENEW_HOSTING: 'Prodloužit',
        },
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst seznam hostingů',
            ERROR_LOAD_HOSTING: 'Nepodařilo se načíst hosting',
        },
    },
    INPUT: {
        NAME: 'Název hostingu',
        CREATE: 'Datum vytvoření',
        CREATED: 'Vytvořeno',
        EXPIRE: 'Expirace',
        PHP_VERSION: 'Verze PHP',
    },
    CREATE: {
        TITLE: 'Vytvořit hosting',
        ERROR_LOAD: 'Nepodařilo se získat data pro vytvoření hostingu',
        WIZARD: {
            STEPS: {
                1: 'Doména',
                2: 'Nastavení',
                3: 'Parametry',
                4: 'Fakturační údaje',
                5: 'Dokončení',
            },

            FILL_DETAILS: 'Zadejte pokročilé údaje',
            HOSTING_DETAILS: 'Zadejte údaje k hostingu',
            PACKAGES: 'Balíčky',
            PACKAGE_NAME: 'Název balíčku',
            REVIEW_AND_SUBMIT: 'Zkontrolujte své zadané údaje',
            YES: 'Ano',
            NO: 'Ne',
            TO_PAY: 'Celkem k zaplacení',
            INPUT: {
                DOMAIN_NAME: 'Název domény',
                KEY: 'Public key',
                WANT_SSH: 'Chci SSH',
                IDENTITY_GROUP: 'Skupina',
                PHP_VERSION: 'Verze PHP',
                YEARS: 'Počet let',
                SSH: 'SSH',
            },
            PRICING: {
                MAX_SIZE: 'Maximální velikost',
                PHP_MEMORY_LIMIT: 'PHP memory limit',
                BACKUP_INTERVAL: 'Četnost záloh',
                DAILY: 'Denní',
                WEEKLY: 'Týdenní',
                BACKUP_RECOVERY_FREE: 'Obnova ze zálohy zdarma',
                MAX_DB_NUM: 'Maximální počet databází',
                DB_SIZE: 'Velikost databází',
                MAX_FTP_NUM: 'Počet FTP účtů',
                QUOTA_FILES: 'Počet souborů naráz',
                ACTIVATE_PACKAGE: 'Aktivovat balíček',
                ACTUAL_PACKAGE: 'Aktuální balíček',
                SELECT_PACKAGE: 'Vyberte balíček',
            },
        },
        BUTTONS: {
            CREATE_HOSTING: 'Vytvořit',
            NEXT_STEP: 'Další krok',
            PREVIOUS: 'Předchozí krok',
            SHOW_HOSTING: 'Zobrazit hosting',
            CREATE_NEXT_HOSTING: 'Vytvořit další hosting',
        },
        MESSAGES: {
            LOADING: 'Vyčkejte prosím, probíhá vytváření hostingu',
            SUCCESS: 'Hosting byl úspěšně vytvořen',
            CREATED: 'Hosting bude brzy vytvořen',
            CREATE_FINISHED: 'Nový hosting pro doménu "{{domain}}" byl úspěšně vytvořen',
            ERROR: 'Nepodařilo se vytvořit hosting',
            ERROR_DESC:
                ' {{ domain }} hosting se nepodařilo vytvořit, kontaktujte prosím administrátora',
        },
    },
    VIEW: {
        TABS: {
            DETAILS: 'Přehled',
            PACKAGES: 'Balíčky',
            FTP: 'FTP',
            PHP: 'PHP',
            SSH: 'SSH',
            MYSQL: 'MySQL',
            SSL: 'SSL',
            EMAIL: 'Email',
            CRONY: 'Crony',
            WEB_ACCESS: 'Přístup z webu',
            BACKUP: 'Zálohy',
            LOGS: 'Logy',
            ACCESS_AND_SECURITY: 'Přístup a zabezpečení',
            EDITING: 'Úpravy',
            ACCESS: 'Přístupy',
        },
        DETAILS: {
            DOMAIN: 'Název domény',
            DOMAIN_EXPIRE: 'Datum expirace domény',
            EXPIRE_DATE: 'Datum expirace',
            CREATE_DATE: 'Datum vytvoření',
            IDENTITY_GROUP: 'Skupina',
            TOTAL_HOSTING_SIZE: 'Celková velikost hostingu',
            TOTAL_DB_SIZE: 'Celková velikost databází',
            MESSAGES: {
                ERROR_LOAD_GROUP: 'Nepodařilo se načíst skupinu',
            },
        },
        CHANGE_PACKAGE: {
            TITLE: 'Změnit balíček hostingu',
            MYSQL_PACKAGE_UPGRADE: 'Navýšit limity MySQL',
            FTP_PACKAGE_UPGRADE: 'Navýšit limity FTP',
            ERROR_LOAD: 'Nepodařilo se načíst balíčky hostingu',
            BUTTONS: {
                CHANGE_DB_PACKAGE: 'Změnit balíček databáze',
                CHANGE_FTP_PACKAGE: 'Změnit FTP balíček',
            },
            MESSAGES: {
                SUCCESS: 'Balíček hostingu změněn',
                ERROR: 'Nepodařilo se změnit balíček hostingu',
                LOADING: 'Probíhá změna balíčku hostingu',
            },
            MODAL: {
                TITLE_MYSQL: 'Upravit MySQL balíček',
                TITLE_FTP: 'Upravit FTP balíček',
                BUTTONS: {
                    SAVE: 'Uložit',
                },
                MESSAGES: {
                    SUCCESS: 'Balíček aktualizován',
                    ERROR: 'Balíček se nepodařilo aktualizovat',
                    ERROR_LOAD: 'Nepodařilo se načíst data',
                },
            },
        },
    },
    RENEW: {
        TITLE: 'Prodloužení hostingu',
        YEARS: 'Počet let',
        BUTTONS: {
            RENEW: 'Prodloužit',
        },
        MESSAGES: {
            ERROR: 'Nepodařilo se prodloužit hosting "{{name}}"',
            SUCCESS: 'Hosting "{{name}}" byl prodloužen',
        },
    },
    FTP: {
        TITLE: 'Seznam FTP',
        INPUT: {
            FTP_LOGIN: 'FTP login',
            DIRECTORY: 'Adresář',
            STATUS: 'Povolen',
            ENABLED: 'Povoleno',
            DISABLED: 'Zakázáno',
        },
        BUTTONS: {
            NEW_FTP: 'Vytvořit FTP',
        },
        IN_FTP_LOGIN: 'FTP loginu',
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst seznam FTP',
            FTP_DELETE_SUCCESS: 'FTP úspěšně smazáno',
            FTP_DELETE_ERROR: 'FTP se nepodařilo smazat',
        },
        CREATE: {
            TITLE: 'Přidat FTP účet',
            INPUT: {
                LOGIN: 'Login',
                DIRECTORY: 'Adresář',
                CHANGE_PASSWORD: 'Změnit heslo',
                FTP_PACKAGE: 'Balíček FTP',
                ENABLE: 'Povolit',
            },
            MESSAGES: {
                CREATED: 'FTP účet vytvořen',
                ERROR: 'Nepodařilo se vytvořit FTP účet',
            },
        },
        UPDATE: {
            TITLE: 'Upravit FTP účet',
            MESSAGES: {
                UPDATED: 'FTP účet upraven',
                ERROR: 'Nepodařilo se upravit FTP účet',
            },
        },
        DELETE: {
            TITLE: 'Smazat FTP účet',
            MESSAGE: 'Opravdu si přejete smazat FTP účet "{{name}}"?',
        },
    },
    PHP: {
        TITLE: 'PHP',
        BUTTONS: {
            DEFAULT: 'Výchozí nastavení',
            SAVE: 'Uložit nastavení',
        },
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst PHP verze a moduly',
            UPDATED: 'PHP verze a moduly upraveny',
            ERROR: 'Nepodařila se upravit PHP verze s moduly',
        },
        VERSION: 'Verze PHP',
        MODULES: 'Dostupné moduly',
    },
    MYSQL: {
        TITLE: 'MySQL',
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst MySql databáze',
            MYSQL_DELETE_SUCCESS: 'Databáze smazána',
            MYSQL_DELETE_ERROR: 'Nepodařilo se smazat databázi',
        },
        INPUT: {
            DB_NAME: 'Jméno databáze',
            DB_SIZE: 'Velikost databáze',
        },
        IN_MYSQL_NAME: ' jména databáze',
        BUTTONS: {
            NEW_MYSQL: 'Vytvořit databázi',
        },
        CREATE: {
            TITLE: 'Vytvořit databázi',
            INPUT: {
                NAME: 'Jméno databáze',
                MYSQL_PACKAGE: 'Balíček MySQL',
                CHANGE_PASSWORD: 'Změnit heslo',
            },
            MESSAGES: {
                CREATED: 'Databáze vytvořena',
                ERROR: 'Nepodařilo se vytvořit databázi',
            },
        },
        CHANGE_PASSWORD: {
            TITLE: 'Změnit heslo pro databázi "{{dbName}}"',
            MESSAGES: {
                CHANGED: 'Heslo bylo změněno',
                ERROR: 'Nepodařilo se změnit heslo',
            },
        },
        DELETE: {
            TITLE: 'Smazat MySQL databázi',
            MESSAGE: 'Opravdu si přejete smazat databázi "{{name}}"?',
        },
    },
    SSL: {
        TITLE: 'SSL certifikáty',
        SSL_SUBDOMAIN: 'SSL certifikát pro subdomény',
        INPUT: {
            URL: 'Url',
            SUBDOMAIN: 'Subdoména',
        },
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst SSL certifikáty',
            NO_CERTIFICATES: 'Nemáte žádné certifikáty',
            CERTIFICATE_CREATED: {
                SUCCESSFUL: 'Certifikát vytvořen',
                ERROR: 'Nepodařilo se vytvořit certifikát',
            },
            SUBDOMAIN_CERTIFICATE_CREATED: {
                SUCCESSFUL: 'Certifikát pro subdoménu vytvořen',
                ERROR: 'Nepodařilo se vytvořit certifikát pro subdoménu',
            },
        },
        IN_SSL_NAME: 'názvu SSL certifikátu',
        NO_CERTIFICATES: 'Nemáte žádné certifikáty',
        BUTTONS: {
            MAKE_NEW_CERTIFICATE: 'Vystavit SSL certifikát',
            MAKE_NEW_CERTIFICATE_FOR: 'Pro {{url}} a {{wwwUrl}}',
        },
    },
    EMAIL: {
        INPUT: {
            NAME: 'Název Mailhostingu',
        },
        TABS: {
            EMAIL_DETAILS: 'Přehled',
            EMAIL_MAILBOXES: 'Schránky',
            EMAIL_ALIASES: 'Aliasy',
            EMAIL_COPIES: 'Kopie',
            EMAIL_ACCESS: 'Přístupy',
        },
        EMAIL_DETAILS: {
            TOTAL_HOSTING_SIZE: 'Celková velikost mailhostingu',
        },
        EMAIL_BOXES: {
            TABLE: {
                EMAIL: 'E-mail',
                SIZE: 'Velikost',
                MESSAGES: {
                    ERROR_LOAD: 'Nepodařilo se načíst emailové schránky',
                    SUCCESS: 'Schránka byla smazána',
                    ERROR: 'Nepodařilo se smazat schránku',
                },
            },
            CREATE: {
                TITLE: 'Upravit e-mail {{email}}',
                NOT_UPDATE_TITLE: 'Přidat e-mail',
                EMAIL: 'E-mail',
                MESSAGES: {
                    SUCCESS: 'Schránka vytvořena',
                    ERROR: 'Nepodařilo se vytvořit schránku',
                    UPDATE_SUCCESS: 'Schránka upravena',
                    UPDATE_ERROR: 'Nepodařilo se upravit schránku',
                },
            },
            DELETE: {
                TITLE: 'Smazat schránku',
                MESSAGE: 'Opravdu chcete smazat schránku "{{name}}"?',
            },
        },
        EMAIL_ALIASES: {
            TABLE: {
                EMAIL: 'E-mail',
                FORWARD_TO: 'Přeposílat na',
                MESSAGES: {
                    ERROR_LOAD: 'Nepodařilo se načíst aliasy',
                    SUCCESS: 'Alias smazán',
                    ERROR: 'Nepodařilo se smazat alias',
                },
            },
            CREATE: {
                TITLE: 'Přidat alias',
                WANT_EMAIL: 'Chci e-mail',
                FORWARD_TO: 'Přeposílat na',
                MESSAGES: {
                    SUCCESS: 'Alias vytvořen',
                    ERROR: 'Nepodařilo se vytvořit alias',
                },
            },
            DELETE: {
                TITLE: 'Smazat alias',
                MESSAGE: 'Opravdu chcete smazat alias z "{{source}}" na "{{destination}}"?',
            },
        },
        EMAIL_COPIES: {
            TABLE: {
                EMAIL: 'E-mail',
                SEND_COPY_TO: 'Zasílat kopii na',
                MESSAGES: {
                    ERROR_LOAD: 'Nepodařilo se načíst kopie',
                    SUCCESS: 'Kopie smazána',
                    ERROR: 'Nepodařilo se smazat kopii',
                },
            },
            CREATE: {
                TITLE: 'Upravit e-mail {{email}}',
                NOT_UPDATE_TITLE: 'Přidat kopie',
                FROM_EMAIL: 'Z e-mailu',
                SEND_TO: 'Zasílat na',
                EVERY_EMAIL_ON_OWN_ROW: 'Každý e-mail zapište na vlastní řádek',
                MESSAGES: {
                    SUCCESS: 'Kopie vytvořeny',
                    ERROR: 'Nepodařilo se vytvořit kopie',
                    UPDATE_SUCCESS: 'Kopie upraveny',
                    UPDATE_ERROR: 'Nepodařilo se upravit kopie',
                },
            },
            DELETE: {
                TITLE: 'Smazat kopii',
                MESSAGE: 'Opravdu chcete smazat kopie z "{{source}}"?',
            },
        },
        LIST: {
            TITLE: 'Seznam mailhostingů',
            IN_HOSTING_NAMES: 'názvu mailhostingu',
            MAIL_CLIENT: {
                TITLE: 'Mailový klient',
                BUTTON: 'Webový mailklient',
                BODY: "Pro prohlížení schránek využijte webový klient. Jak nastavit vlastní naleznete v manuálu <a href='https://anafra.cz/sluzby/hostingove-sluzby' target='_blank'>zde</a>.",
            },
            MAILHOSTING: {
                TITLE: 'Mailhosting',
                BODY: "Využijte vlastní privátní mailhosting. Emaily bez reklam. Nad vašimi maily nejsou prováděny žádné analytické práce. <a href='https://anafra.cz/sluzby/hostingove-sluzby/mailhosting' target='_blank'>Více informací zde</a>.",
                BUTTON: 'Přidat mailhosting',
                FROM: 'Od',
            },
        },
        CREATE: {
            TITLE: 'Vytvořit mailhosting',
            SUBMIT: 'Vytvořit mailhosting',
            MESSAGES: {
                ERROR: 'Nepodařilo se vytvořit mailhosting',
                SUCCESS: 'Mailhosting úspěšně vytvořen',
                SUCCESS_DESC:
                    'Mailhosting {{name}} byl úspěšně vytvořen. Nyní můžete přidat schránky a aliasy.',
                ERROR_DESC:
                    'Nepodařilo se vytvořit mailhosting {{name}}. Zkuste to prosím později.',
                LOADING: 'Vytvářím mailhosting',
            },
            BUTTONS: {
                SHOW_MAILHOSTING: 'Zobrazit mailhosting',
                CREATE_NEXT_MAILHOSTING: 'Vytvořit další mailhosting',
            },
            WIZARD: {
                SUMMARY: 'Shrnutí',
                MAILHOSTING_DETAILS: 'Zadejte údaje k mailhostingu',
                ORGANIZATION: 'Vyberte organizaci',
                TOTAL: 'Celkem k zaplacení',
                INPUT: {
                    MAILHOSTING_NAME: 'Doména mailhostingu',
                    SIZE: 'Maximální velikost',
                },
                STEPS: {
                    1: 'Základní údaje',
                    2: 'Výběr organizace',
                    3: 'Shrnutí',
                },
            },
        },
        DELETE: {
            SUCCESS: 'Mailhosting byl smazán',
            ERROR: 'Nepodařilo se smazat mailhosting',
            TITLE: 'Smazat mailhosting',
            MESSAGE: 'Opravdu chcete smazat mailhosting "{{name}}"?',
        },
        ACCESS: {
            USERS_TITLE: 'Uživatelé s přístupem k mailhostingu',
            GROUPS_TITLE: 'Skupiny, ve kterých je mailhosting',
        },
        ADD_USER: {
            TITLE: 'Přidat uživatele k mailhostingu',
        },
        EDIT_USER: {
            TITLE: 'Uživatel s přístupem k mailhostingu',
        },
        MIGRATION: {
            TITLE: 'Migrace mailhostingu',
            MESSAGES: {
                SUCCESS: 'Migrace úspěšně provedena',
                ERROR: 'Nepodařilo se provést migraci',
            },
            TABLE: {
                HOSTING_NAME: 'Název mailhostingu',
                MIGRATION_STATE: 'Stav migrace',
            },
            BUTTONS: {
                MIGRATE: 'Migrovat',
                GENERATE_PASSWORD: 'Vygenerovat heslo',
                GENERATE_PASSWORD_FOR_ALL: 'Vygenerovat heslo pro všechny',
            },
            WIZARD: {
                MESSAGES: {
                    MAILBOX_MIGRATION_INFO:
                        'lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam, quidem, quisquam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quidem, quisquam.',
                },
                BUTTONS: {
                    SUBMIT: 'Zahájit migraci',
                },
                MIGRATION_STATE: {
                    EVERYTHING_WILL_BE_MIGRATED: 'Vše bude migrováno',
                    MAILBOX_WILL_BE_DELETED: 'Schránka bude smazána',
                    READY_TO_MIGRATE: 'Připraveno k migraci',
                    MIGRATION_NOT_READY: 'Migrace není připravena',
                    MIGRATION_IN_PROGRESS: 'Migrace probíhá',
                    PASSWORD_INVALID: 'Heslo je neplatné',
                    ALIAS_WILL_BE_DELETED: 'Alias bude smazán',
                    COPIES_WILL_BE_DELETED: 'Nastavení kopii bude smazáno',
                },
                INPUTS: {
                    DELETE_MAILBOX_CONTENT_LABEL: 'Smazat obsah schránek?',
                    MIGRATE_MAILBOX_CONTENT: 'Migrovat obsah schránek',
                    DELETE_MAILBOX_CONTENT: 'Smazat obsah schránek',
                    MIGRATION_DATE: 'Datum migrace',
                    MIGRATION_DURATION_ESTIMATE: 'Odhadovaná doba migrace',
                    WILL_MIGRATE: 'Bude migrováno',
                    WILL_NOT_MIGRATE: 'Nebude migrováno',
                },
                TITLE: 'Migrace mailhostingu',
                STEPS: {
                    '1': 'Základní informace',
                    '2': 'Emailové schránky',
                    '3': 'Nastavené Aliasy',
                    '4': 'Přeposílání kopíí',
                    '5': 'Nastavení',
                    '6': 'Závěrečné shrnutí',
                },
            },
        },
    },
    CRONY: {
        TITLE: 'Seznam cronů',
        ADD_NEW: 'Přidat nový',
        INPUT: {
            NAME: 'Název',
            LAST_RUN: 'Poslední spuštění',
            NEXT_RUN: 'Další spuštění',
            SCRIPT: 'Skript',
        },
        MODAL: {
            TITLE: 'Přidat cron',
            NAME: 'Název cronu',
            RUN_ONCE_PER: 'Spouštět 1x za',
            MINUTES: 'minut',
            ONE_HOUR: 'hodina',
            HOURS_TO_FIVE: 'hodiny',
            HOURS_MORE_THAN_FIVE: 'hodin',
            WEEK_DAYS: 'Dny v týdnu',
            DAYS: {
                MON: 'Po',
                TUE: 'Út',
                WED: 'St',
                THU: 'Čt',
                FRI: 'Pá',
                SAT: 'So',
                SUN: 'Ne',
            },
            SCRIPT_URL: 'URL skriptu',
            MESSAGES: {
                SUCCESS: 'Cron vytvořen',
                ERROR: 'Cron se nepodařilo vytvořit',
                UPDATE_SUCCESS: 'Cron upraven',
                UPDATE_ERROR: 'Cron se nepodařilo upravit',
                SCRIPT_INFO: 'Script adresa musí být umístěna na stejné doméně',
            },
        },
        MESSAGES: {
            SUCCESS: 'Cron byl smazán',
            ERROR: 'Nepodařilo se smazat cron',
            ERROR_LOAD: 'Nepodařilo se načíst crony',
            DELETE_TITLE: 'Smazat cron',
            DELETE_CONFIRM: 'Opravdu chcete smazat cron "{{name}}"?',
        },
    },
    WEB_ACCESS: {
        TITLE: 'Přístup z webu',
        ADD_HEADER: 'Přidat pravidlo',
        INPUT: {
            PATH: 'Adresář',
            ATTRIBUTES: 'Vlastnosti',
            VIEW: 'Viditelné',
            HIDE: 'Neviditelné',
            LOCKED: 'Uzamčené',
            LIST_ENABLED: 'Umožnit listování souborů',
        },
        DELETE_MODAL: {
            TITLE: 'Odebrat pravidlo',
            MESSAGE: 'Opravdu chcete odebrat pravidlo?',
        },
        BUTTONS: {
            SHOW_USERS: 'Spravovat uživatele',
        },
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst definované adresáře',
            SUCCESS_ADD: 'Pravidlo bylo úspěšně přidáno',
            ERROR_ADD: 'Nepodařilo se přidat pravidlo přístupu z webu',
            SUCCESS_DELETE: 'Pravidlo bylo úspěšně odebráno',
            ERROR_DELETE: 'Nepodařilo se odebrat pravidlo',
        },
        ACCESS_MODE_TITLE: 'Přístupnost z webu',
        USERS: {
            MESSAGES: {
                ERROR_LOAD: 'Nepodařilo se načíst uživatele',
                DELETE_TITLE: 'Odebrání uživatele',
                DELETE_MESSAGE: 'Opravdu chcete odebrat uživatele "{{name}}" ?',
                SUCCESS_ADD: 'Uživatel byl úspěšně přidán',
                ERROR_ADD: 'Nepodařilo se přidat uživatele',
                SUCCESS_DELETE: 'Uživatel byl úspěšně odebrán',
                ERROR_DELETE: 'Nepodařilo se odebrat uživatele',
            },
        },
    },
    BACKUP: {
        TITLE: 'Obnovení souborů',
        BUTTONS: {
            RESTORE: 'Obnovit',
        },
        MESSAGES: {
            SUCCESS: 'Soubory budou brzy obnoveny',
            ERROR: 'Obnova souborů se nezdařila',
            NO_BACKUPS: 'Nejsou žádné zálohy',
        },
    },
    LOGS: {
        TITLE: 'Logy',
        TABS: {
            ACCESS: 'Přístupové logy',
            ERROR: 'Chybové logy',
        },
        NO_LOGS: 'Logy hostingu jsou prázdné',
        INFO_ALL_LOGS: 'Ke komplentím historickým logům se dostanete přes SSH',
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst logy hostingu',
        },
    },
    SSH: {
        TITLE: 'SSH',
        INPUT: {
            PUBLIC_KEY: 'Veřejný klíč',
        },
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst veřejné klíče',
            SUCCESS_ADD: 'Veřejný klíč byl úspěšně přidán',
            ERROR_ADD: 'Nepodařilo se přidat veřejný klíč',
            SUCCESS_DELETE: 'Veřejný klíč byl úspěšně odebrán',
            ERROR_DELETE: 'Nepodařilo se odebrat veřejný klíč',
        },
        DELETE: {
            TITLE: 'Smazat veřejný klíč',
            MESSAGE: 'Opravdu si přejete smazat veřejný klíč "{{name}}"?',
        },
    },
    ACCESS: {
        USERS_TITLE: 'Uživatelé s přístupem k hostingu',
        GROUPS_TITLE: 'Skupiny, ve kterých je hosting',
    },
    ADD_USER: {
        TITLE: 'Přidat uživatele k hostingu',
    },
    EDIT_USER: {
        TITLE: 'Uživatel s přístupem k hostingu',
    },
};
