export const TICKET_SYSTEM = {
    // translations for model fields
    INPUT: {
        NAME: 'Název',
        DESCRIPTION: 'Popis',
        TYPE: 'Typ',
        PRIORITY: 'Priorita',
        SOLVER: 'Řešitel',
        STATUS: 'Stav',
        CONTRACTOR: 'Zadavatel',
        CREATED: 'Vytvořeno',
        UPDATED: 'Upraveno',
        DUE_DATE: 'Předpokládaný datum splnění',
        SOLVED: 'Vyřešeno',
        SOLUTION: 'Návrh řešení',
        AUTHOR: 'Autor',
        TEXT: 'Text',
        ATTACHMENTS: 'Přílohy',
        WAITING_FOR_CONTRACTOR: 'Čeká se na zadavatele',
        ATTACHMENT_SIZE: 'Velikost přílohy',
        HOUR_ESTIMATE: 'Předpokládáná časová náročnost (hod)',
        SOLVER_SPENT_HOURS: 'Čas strávený řešením ticketu (hod)',
    },
    VIEW_TICKET: {
        DESCRIPTION: 'Popis (od zadavatele)',
        SOLUTION: 'Návrh řešení (od řešitele)',
    },
    TITLES: {
        DETAIL_SPECIFICATION: 'Specifikace detailu',
        ATTACHMENTS: 'Přílohy',
        ACCESS: 'Uživatelé s přístupem k ticketu',
        ACCESS_GROUPS: 'Skupiny, ve kterých je ticket',
    },
    BUTTONS: {
        CREATE: 'Vytvořit tiket',
        SEND_RESPONSE: 'Odeslat odpověď',
        ADD_ATTACHMENTS: 'Přidat přílohy',
        MARK_AS_SOLVED: 'Označit ticket jako vyřešený',
    },
    MESSAGES: {
        WRITE_RESPONSE: 'Napište odpověď',
        RESPONSE_SUCCESS: 'Odpověď byla odeslána',
        RESPONSE_ERROR: 'Odpověď se nepodařilo odeslat',
        LOAD_ERROR: 'Nepodařilo se načíst tickety',
        NOT_SURE_LEAVE_EMPTY: 'Pokud si nejste jistí, nechte toto pole prázdné',
        SUCCESS: {
            FILES_UPLOADED: 'Soubory byly nahrány',
            TICKET_CLOSE: 'Ticket byl uzavřen',
        },
        ERROR: {
            FILES_UPLOADED: 'Soubory se nepodařilo nahrát',
            TICKET_CLOSE: 'Ticket se nepodařilo uzavřít',
        },
    },
    TICKET_TYPES: {
        ERROR: 'Chyba',
        QUESTION: 'Dotaz',
        FEATURE_OR_CHANGE: 'Rozvoj nebo změna',
    },
    TICKET_PRIORITIES: {
        LOW: 'Nízká',
        MEDIUM: 'Střední',
        HIGH: 'Vysoká',
        CRITICAL: 'Kritická',
    },
    TICKET_STATES: {
        ZRU: 'Zrušeno',
        PRO: 'Probíhá',
        PKT: 'Předáno zadavateli k testování',
        ODL: 'Odloženo',
        NOV: 'Nový',
        END: 'Dokončeno',
        CNS: 'Čeká se na schválení',
        ANA: 'Analýza',
    },
    WIZARD: {
        LOADING: 'Vyčkejte prosím, ticket se vytváří...',
        SUCCESS: {
            TITLE: 'Ticket byl vytvořen',
            DESC: 'Váš ticket jsme zařadili do systému',
            CREATE_NEXT: 'Vytvořit další ticket',
            VIEW: 'Zobrazit ticket',
        },
        ERROR: {
            TITLE: 'Ticket se nepodařilo vytvořit',
            DESC: 'Něco se pokazilo, zkuste to prosím později',
        },

        STEPS: {
            1: 'Výběr organizace',
            2: 'Detaily ticketu',
        },
    },
    CLOSE_TICKET: {
        TITLE: 'Uzavřít ticket',
        MESSAGE: 'Opravdu chcete uzavřít tento ticket? Tato akce již nepůjde vzít zpět.',
    },
};
