import { Injectable } from '@angular/core';
import { ServiceModel } from '../user-management/_models/service.model';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/_services/auth.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

const API_SERVICES_URL = environment.apiUrl + '/services';
// TODO: Remove this or move to file with constants
export type ServiceType =
    | 'DOMAIN'
    | 'DOMAIN_CONTACT'
    | 'HOSTING'
    | 'VPS'
    | 'MAILHOSTING'
    | 'DNS'
    | 'TICKET';
export const serviceTypes = [
    // 'DOMAIN',
    // 'DOMAIN_CONTACT',
    // 'HOSTING',
    // 'VPS',
    'MAILHOSTING',
    // 'TICKET',
    // 'DNS',
] as ServiceType[];

// TODO: Remove this or move to file with constants
export const servicePermissionsPrefixes = [
    'Hosting',
    'Subreg',
    'Mailhosting',
    'Proxmox',
    'Dns',
    'TicketSystem',
];
type ServiceTypeMap = { [key in ServiceType]: ServiceModel[] };

@Injectable({
    providedIn: 'root',
})
export class ServiceService {
    private _cachedServices = new BehaviorSubject<ServiceTypeMap | undefined>(undefined);
    servicePermissionGroupNames = new BehaviorSubject<string[]>([]);
    get cachedServices() {
        return this._cachedServices.pipe(filter((services) => !!services));
    }

    constructor(private authService: AuthService, private http: HttpClient) {}

    getServices(type: ServiceType, organizationId?: string) {
        let params = {};
        if (organizationId) {
            params = { organizationId };
        }
        return this.http.get<ServiceModel[]>(`${API_SERVICES_URL}/${type}`, {
            params,
            headers: this.authService.getDefaultAuthHeaders(),
        });
    }

    getServiceLink(serviceId: string) {
        const type = serviceId.slice(0, 2);
        const id = serviceId.slice(2);
        switch (type) {
            case 'DO':
                return '/domain-management/view/' + serviceId;
            case 'VP':
                return '/vps/view/' + serviceId;
            case 'HO':
                return '/hostings/view/' + serviceId;
            case 'MA':
                return '/mailhosting/view/' + serviceId;
            case 'DN':
                return '/dns/view/' + serviceId;
            case 'TI':
                return '/ticket-system/view/' + serviceId;
        }
        return '.';
    }

    getServiceQueryParams(serviceId: string) {
        // TODO: remove this
        const parts = serviceId.split(/-(.*)/s);
        const type = parts[0];
        const id = parts[1];
        return {};
    }

    getServiceType(serviceId: string) {
        return serviceId.slice(0, 2);
    }

    updateCachedServices(type: ServiceType, organizationId?: string) {
        const types = this._cachedServices.value || ({} as ServiceTypeMap);
        if (!types?.[type]) {
            this.getServices(type, organizationId).subscribe((services) => {
                const types = this._cachedServices.value || ({} as ServiceTypeMap);
                types[type] = services;
                this._cachedServices.next(types);
            });
        } else {
            this._cachedServices.next(types);
        }
    }
    clearCachedServices() {
        this._cachedServices.next(undefined);
    }
}
