import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EMPTY } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BackNavigationService {
    public history: string[] = [];
    public navigationHistory: string[] = [];
    public backButtonCount: number = 0;

    constructor(private router: Router, private location: Location) {}

    back(
        url: string,
        forceUrl: boolean,
        queryParamsHandling: 'preserve' | 'merge' | '',
        queryParams: any,
    ): void {
        if (forceUrl) {
            this.router.navigate([url], {
                relativeTo: this.getActivatedRoute(),
                queryParamsHandling,
                queryParams,
            });
            return;
        }
        this.history.pop();

        if (this.history.length > 0) {
            this.location.back();
        } else {
            this.router.navigate([url], {
                relativeTo: this.getActivatedRoute(),
                queryParamsHandling,
                queryParams,
            });
        }
    }

    init() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects);
                if (this.backButtonCount === 0) {
                    this.navigationHistory.push(event.urlAfterRedirects);
                }
            }
        });
        return EMPTY;
    }

    private getActivatedRoute(): ActivatedRoute {
        let route = this.router.routerState.root;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }
}
