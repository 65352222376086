<app-text-input
    [inputControl]="filterControl"
    [type]="'text'"
    [placeholder]="!searchingIn ? (placeholder | translate) : ''"
    [disableWhitespaceValidator]="true"
    class="d-inline-block"
    style="max-width: 200px"
>
    <ng-template appInnerIcon>
        <i class="flaticon2-search-1 icon-md"></i>
    </ng-template>
    <ng-container *ngIf="searchingIn">
        <ng-template #bottomContent>
            <small class="form-text text-muted"
                ><b>{{ "GENERAL.INPUT.SEARCHING" | translate }}</b>
                {{ searchingIn | translate }}</small
            >
        </ng-template>
    </ng-container>
</app-text-input>
