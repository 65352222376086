<div class="card">
    <div class="card-header d-flex justify-content-between border-bottom-0 pb-0">
        <div class="card-title">
            <h5 class="card-label font-weight-bolder text-dark mb-2">Upravit přístup pro</h5>
        </div>
        <div class="card-toolbar">
            <button class="icon-button">
                <i class="flaticon2-cross" (click)="activeModal.dismiss()"></i>
            </button>
        </div>
    </div>

    <div class="card-body pt-0">
        <div class="d-flex align-items-center mb-4" *ngIf="user">
            <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
                <span class="symbol-label font-size-h5 font-weight-bold">
                    {{
                        ((user.firstName ? user.firstName : "U") | firstLetter) +
                            ((user.lastName ? user.lastName : "") | firstLetter)
                    }}
                </span>
            </span>
            <div class="d-flex flex-column ml-2">
                <span class="font-weight-bolder font-size-h6">
                    {{ user.firstName }} {{ user.lastName }}
                </span>
                <span class="text-muted font-size-sm">
                    {{ user.email }}
                </span>
            </div>
        </div>
        <app-select-roles-standalone
            *ngIf="dataLoaded"
            [availableRoles]="availableRoles"
            [otherRoleSources]="otherRoleSources"
            [selectedRoleIds]="selectedRoleIds"
        ></app-select-roles-standalone>
        <app-skeleton-loading *ngIf="!dataLoaded" [count]="10"></app-skeleton-loading>
        <div class="d-flex justify-content-between mt-6">
            <button class="btn btn-secondary" (click)="activeModal.dismiss()">
                {{ "GENERAL.BUTTONS.CANCEL" | translate }}
            </button>
            <button class="btn btn-primary" (click)="save()" [disabled]="savingChanges | async">
                <span *ngIf="savingChanges | async" class="d-flex align-items-center">
                    <app-loading-circle [size]="'inline'" class="mr-2"> </app-loading-circle>
                    {{ "GENERAL.INPUT.SAVING" | translate }}
                </span>
                <span *ngIf="(savingChanges | async) === false">{{
                    "GENERAL.BUTTONS.SAVE" | translate
                }}</span>
            </button>
        </div>
    </div>
</div>
