<app-input-wrapper
    [inputControl]="inputControl"
    [label]="label"
    [wide]="wide"
    [labelStyle]="labelStyle"
    [labelClass]="labelClass"
>
    <div class="input-group">
        <input
            class="form-control"
            [value]="color"
            [style.background]="color"
            [cpAlphaChannel]="'disabled'"
            [style.color]="getContrastColor(color)"
            cpPosition="auto"
            [(colorPicker)]="color"
            (colorPickerChange)="setColorInput($event)"
            [cpPresetColors]="['#3699FF', '#E4E6EF', '#1BC5BD', '#8950FC', '#FFA800', '#F64E60']"
            [class]="inputClass"
            [style]="inputStyle"
        />
    </div>
</app-input-wrapper>
