export const BREADCRUMBS = {
    DASHBOARD: 'Přehled',
    DOMAINS_MANAGEMENT: 'Správa domén',
    REGISTER_DOMAIN: 'Registrace domény',
    TRANSFER_DOMAIN: 'Převod domény',
    GENERATE_AUTH_ID: 'Vygenerovat autorizační ID',
    DETAILS: 'Detaily',
    EDIT: 'Editace',
    ACCESS: 'Přístupy',
    LIST: 'Seznam',
    CONTACTS: 'Kontakty',
    CREATE_CONTACT: 'Vytvořit kontakt',
    TRANSFER_IMPORT_CONTACT: 'Převod a import kontaktu',
    ORDERS: 'Objednávky',
    NSSETS: 'NSSETy',
    CATCH_DOMAINS: 'Zachytávání domén',
    CATCH_DOMAIN_ADD: 'Přidat zachytávání domény',
    DNS: 'DNS',
    HOSTINGS: 'Hosting',
    HOSTING_PHP: 'PHP',
    HOSTING_MYSQL_LIST: 'Seznam MySQL',
    HOSTING_MYSQL_CREATE: 'Vytvořit MySQL',
    HOSTING_MYSQL_EDIT: 'Upravit MySQL',
    HOSTING_CRONS: 'Crony',
    HOSTING_CHANGE_PACKAGE: 'Balíčky',
    HOSTING_ACCESS_AND_SECURITY: 'Přístup a zabezpečení',
    HOSTING_SSH: 'SSH',
    HOSTING_FTP_LIST: 'Seznam FTP',
    HOSTING_FTP_CREATE: 'Vytvořit FTP',
    HOSTING_FTP_EDIT: 'Upravit FTP',
    HOSTING_WEB_ACCESS: 'Přístup z webu',
    HOSTING_SSL: 'SSL',
    HOSTING_BACKUPS: 'Zálohy',
    HOSTING_LOGS: 'Logy',
    HOSTING_LOGS_ACCESS: 'Přístupové logy',
    HOSTING_LOGS_ERROR: 'Chybové logy',
    HOSTING_CREATE: 'Vytvořit hosting',
    MAILHOSTING: 'Mailhosting',
    MAILHOSTING_MAILBOXES: 'Schránky',
    MAILHOSTING_ALIASES: 'Aliasy',
    MAILHOSTING_COPIES: 'Kopie',
    ADD_USER: 'Přidat uživatele',
    EDIT_USER_ACCESS: 'Upravit přístupy uživatele',
    MAILHOSTING_CREATE: 'Vytvořit mailhosting',
    MAILHOSTING_MIGRATION: 'Migrace',
    VPS: 'Virtuální servery',
    VPS_EDIT: 'Upravit',
    CREATE_VPS: 'Vytvořit virtuální server',
    CREATE_VPS_AF: 'Vytvořit VPS ANAFRA',
    VPS_SUMMARY: 'Přehled',
    VPS_STATS: 'Statistiky',
    VPS_BACKUPS: 'Zálohy',
    USER_MANAGEMENT: 'Správa účtů',
    ORGANIZATIONS: 'Organizace',
    BILLING_INFORMATION: 'Fakturační údaje',
    CONTACT_PERSON: 'Kontaktní osoba',
    PAYMENT_METHODS: 'Platební metody',
    USERS: 'Uživatelé',
    USER: 'Uživatel',
    ROLES: 'Role',
    GROUPS: 'Skupiny',
    ORGANIZATION_GROUPS: 'Skupiny organizací',
    SERVICES: 'Služby',
    EDIT_USER: 'Uživatelé',
    PERSONAL_INFORMATION: 'Osobní informace',
    CONTACT_ADDRESS: 'Kontaktní adresa',
    CHANGE_PASSWORD: 'Změna hesla',
    OTHER_SETTINGS: 'Ostatní nastavení',
    EDIT_ROLES: 'Role',
    CREATE_ORGANIZATION: 'Vytvořit organizaci',
    CREATE_DNS: 'Vytvořit DNS',
    ADD_SERVICE: 'Přidat služby',
    CONTACT_DOMAINS: 'Domény kontaktu',
    TICKET_SYSTEM: 'Tikety',
    CREATE_TICKET: 'Vytvořit tiket',
    TICKET_SUMMARY: 'Shrnutí tiketu',
};
