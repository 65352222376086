import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingCircleComponent } from './loading-circle/loading-circle.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';

@NgModule({
    declarations: [LoadingCircleComponent, LoadingOverlayComponent],
    exports: [LoadingCircleComponent, LoadingOverlayComponent],
    imports: [CommonModule],
})
export class LoadingModule {}
