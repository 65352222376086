export const DASHBOARD = {
    TITLE: 'Vítejte v administraci ANSEA',
    CARD_NAVIGATION: {
        REFERRAL_PROGRAM: 'Referral program',
        ACCESS_MANAGEMENT: 'Správa přistupu',
        ORDERS: 'Objednávky',
        EMAIL_CLIENT: 'Email client',
        WEBHOSTING: 'Webhosting',
        VPS: 'Virtuální servery',
        MAILHOSTING: 'Mailhosting',
    },
    SERVICES_SUMMARY: {
        TITLE: 'Zakoupených služeb',
        ACTIVE: 'Aktivní služby',
        ORDERS: 'Objednávky',
        IN_PROGRESS: 'In Progress',
    },
    CREDIT_SUMMARY: {
        TITLE: 'Výše kreditu',
        IN_MATURITY: 'Ve splatnosti',
        PAY_SUMMARY: 'Celkem k platbě',
        INCOMING_RENEW: 'BLížíci se prodloužení',
    },
};
