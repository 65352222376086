import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { RoleModel } from 'src/app/modules/auth/_models/role.model';
import { transformPermissionsToTree } from 'src/app/modules/auth/_services/permission.service';
import {
    servicePermissionsPrefixes,
    ServiceService,
} from 'src/app/modules/services/service.service';
import { PermissionNode } from '../../_models/tree-permission.model';

@Component({
    selector: 'app-view-role',
    templateUrl: './view-role.component.html',
    styleUrls: ['./view-role.component.scss'],
})
export class ViewRoleComponent implements OnInit {
    @Input() roleId: string;
    @Input() role: RoleModel;
    constructor(public serviceService: ServiceService) {}

    public treeControl = new NestedTreeControl<PermissionNode>((node) => node.children);

    public dataSource = new MatTreeNestedDataSource<PermissionNode>();

    toggleAllPermissions = new FormControl(false);
    public hasChild = (_: number, node: PermissionNode) =>
        !!node.children && node.children.length > 0;

    ngOnInit(): void {
        const groupNames = this.serviceService.servicePermissionGroupNames;
        if (
            groupNames.value.some((value) => {
                return servicePermissionsPrefixes.includes(value);
            })
        ) {
            // TODO: check this
            // This permissions are valid for all services (Identity_Groups_Edit_Users for editing user access, Identity_ShowAccess for viewing user access)
            groupNames.next([
                ...groupNames.value,
                'Identity_Groups_Edit_Users',
                'Identity_ShowAccess',
            ]);
        }
        this.dataSource.data = transformPermissionsToTree(this.role.permissions);
        this.treeControl.dataNodes = this.dataSource.data;
        this.treeControl.expandAll();
        this.toggleAllPermissions.valueChanges.subscribe((val) => {
            this.dataSource.data = transformPermissionsToTree(this.role.permissions);
            if (val) {
                this.dataSource.data = transformPermissionsToTree(
                    this.role.permissions,
                    groupNames.value,
                );
            } else {
                this.dataSource.data = transformPermissionsToTree(this.role.permissions);
            }
            this.treeControl.dataNodes = this.dataSource.data;
            this.treeControl.expandAll();
        });
    }
}
