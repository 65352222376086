import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth/_services/auth.service';
import { ListModel } from '../_models/list.model';
import { GroupModel } from '../_models/group.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { SortLimitModel } from '../_models/sort-limit.model';

const API_GROUPS_URL = environment.apiUrl + '/groups';
const API_USER_GROUP_URL = environment.apiUrl + '/userGroups';
const API_GROUP_SERVICE_URL = environment.apiUrl + '/groupServices';

@Injectable({
    providedIn: 'root',
})
export class GroupService {
    public editedGroup$ = new BehaviorSubject<GroupModel>(undefined);

    constructor(private authService: AuthService, private http: HttpClient) {}

    getGroups(
        params: { organizationId?: string; singleService?: boolean; serviceId?: string },
        sortLimitModel?: SortLimitModel,
    ) {
        let sort = {};
        if (sortLimitModel) {
            sort = Object.assign(sortLimitModel) as any;
        }
        const p = Object.assign(sort, params);
        return this.http.get<ListModel<GroupModel>>(`${API_GROUPS_URL}`, {
            params: p,
            headers: this.authService.getDefaultAuthHeaders(),
        });
    }

    getGroup(id: string): Observable<GroupModel> {
        return this.http
            .get<any>(`${API_GROUPS_URL}/${id}`, {
                headers: this.authService.getDefaultAuthHeaders(),
            })
            .pipe(
                tap((group) => {
                    this.editedGroup$.next(group);
                }),
            );
    }

    deleteUserFromGroup(userId, groupId) {
        return this.http.delete(`${API_USER_GROUP_URL}/${userId}/${groupId}`, {
            headers: this.authService.getDefaultAuthHeaders(),
        });
    }

    addUserToGroup(userId, groupId, roleIds) {
        return this.http.post(
            `${API_USER_GROUP_URL}`,
            {
                userId,
                groupId,
                roleIds,
            },
            { headers: this.authService.getDefaultAuthHeaders() },
        );
    }

    deleteServiceFromGroup(groupId, serviceId) {
        return this.http.delete(`${API_GROUP_SERVICE_URL}/${groupId}/${serviceId}`, {
            headers: this.authService.getDefaultAuthHeaders(),
        });
    }

    addServiceToGroup(groupId, serviceId) {
        return this.http.post(
            `${API_GROUP_SERVICE_URL}`,
            {
                groupId,
                serviceId,
            },
            { headers: this.authService.getDefaultAuthHeaders() },
        );
    }

    deleteGroup(id: string) {
        return this.http.delete(`${API_GROUPS_URL}/${id}`, {
            headers: this.authService.getDefaultAuthHeaders(),
        });
    }

    addGroup(name: string, organizationId: string, singleService = false, services = []) {
        return this.http
            .post(
                `${API_GROUPS_URL}`,
                {
                    name,
                    organizationId,
                    singleService,
                    services,
                },
                { headers: this.authService.getDefaultAuthHeaders() },
            )
            .pipe(
                tap({
                    next: () => {
                        this.authService.getCurrentlyLoggedUser().subscribe();
                    },
                }),
            );
    }

    changeUserGroupRoles(userId: string, groupId: string, roles: string[]) {
        // if group is service group
        if (roles.length === 0 && !groupId.startsWith('GROUP')) {
            return this.deleteUserFromGroup(userId, groupId);
        }
        return this.http.put(
            `${API_USER_GROUP_URL}/${userId}/${groupId}`,
            { roleIds: roles },
            {
                headers: this.authService.getDefaultAuthHeaders(),
            },
        );
    }
}
