import { EnvironmentVariables } from './environment.model';

export const environment: EnvironmentVariables = {
    ENVIRONMENT_IDENTIFIER: 'development',
    USERDATA_KEY: 'auth-development',
    BUILD_ID: 'dev_v1',
    ENABLED_MODULES: [
        'subregConnector',
        'proxmoxConnector',
        'hostingConnector',
        'rmaConnector',
        'businessLogic',
        'mailhostingConnector',
        'dnsConnector',
        'ticketSystemConnector',
    ],
    apiUrl: 'https://apidev.ansea.ace.anafra.net/v1/identityserver',
    dnsConnector: 'https://apidev.ansea.ace.anafra.net/v1/dnsconnector/api',
    subregConnector: 'https://apidev.ansea.ace.anafra.net/v1/domainservice',
    hostingConnector: 'https://apidev.ansea.ace.anafra.net/v1/hosting',
    mailHostingConnector: 'https://apidev.ansea.ace.anafra.net/v1/mailhosting',
    // mailHostingConnector: 'http://localhost:6004/api',
    vpsConnector: 'https://apidev.ansea.ace.anafra.net/v1/proxmoxservice',
    businessLogicConnector: 'https://apidev.ansea.ace.anafra.net/v1/businesslogic',
    emailSender: 'https://apidev.ansea.ace.anafra.net/v1/emailsender',

    basePath: 'https://apidev.ansea.ace.anafra.net',
};
