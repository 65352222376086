export const COMPONENTS = {
    MODALS: {
        CONFIRMATION: {
            RETYPE: 'Zadejte <b>{{ retype }}</b> pro potvrzení',
        },
        CONFIRM_CHANGES: {
            TITLE: 'Potvrďte změny',
            FIELD: 'Položka',
            OLD_VALUE: 'Stará hodnota',
            NEW_VALUE: 'Nová hodnota',
        },
        EDIT_ROLES: {
            TITLE: 'Upravit role uživatele {{ firstName }} {{ lastName }}',
        },
        ADD_CUSTOM_ROLE: {
            TITLE: 'Přidat roli',
            ROLE_NAME: 'Název role',
            COLOR: 'Barva',
        },
        INVITE_USER: {
            TITLE: 'Pozvat uživatele',
            INVITE: 'Pozvat',
            INVITING: 'Odesílání',
        },
        VIEW_ROLE: {
            SOURCES: 'Zdroje',
            MANAGE_ACCESS: 'Nastavení přístupů',
        },
        SELECT_ROLES: {
            SEARCHING: 'Vyhledat roli',
        },
    },
    WIZARD: {
        STEP: 'Krok',
        OF: 'z',
        RESTORE: {
            MODAL: {
                TITLE: 'Obnovit uložený průběh',
                MESSAGE:
                    'Průběh byl uložen v minulosti. Chcete jej obnovit? Naposledy byl uložen před {{ minutes }} minutami.',
                WITH_DATE_MESSAGE:
                    'Průběh byl uložen v minulosti. Chcete jej obnovit? Naposledy byl uložen {{ date }}.',
            },
            MESSAGES: {
                SUCCESS: 'Průběh byl obnoven.',
            },
        },
        PROGRESS_WONT_BE_LOST: 'Váš postup nebude ztracen.',
    },
    CHANGE_ORGANIZATION: {
        ERROR: 'Nepodařilo se změnit organizaci',
        SUCCESS: 'Organizace se změnila',
        CHANGE: 'Změnit organizaci',
    },
    ADD_USER_TO: {
        SUCCESS: 'Uživatel byl úspěšně přidán',
        ERROR: 'Uživatele se nepodařilo přidat',
    },
    NO_ENTITIES_FOUND: {
        HOSTING: {
            TITLE: 'Nemáté žádný hosting',
            DESC: 'Vytvořte si hosting zde',
            LINK_TEXT: 'Vytvořit hosting',
        },
        DOMAIN: {
            TITLE: 'Nemáte žádnou doménu',
            DESC: 'Vytvořte si doménu zde',
            LINK_TEXT: 'Vytvořit doménu',
        },
        MAILHOSTING: {
            TITLE: 'Nemáte žádný mailhosting',
            DESC: 'Vytvořte si mailhosting zde',
            LINK_TEXT: 'Vytvořit mailhosting',
        },
        DNS: {
            TITLE: 'Nemáte žádné DNS',
            DESC: 'Vytvořte si DNS zde',
            LINK_TEXT: 'Vytvořit DNS',
        },
    },
    FILE_UPLOADER: {
        DROP_ZONE_TITLE: '<b>Přetáhněte</b> zde soubory,<br />nebo <b>klikněte</b> pro výběr.',
        ONLY_CLICK_HERE: '<b>Klikněte</b> zde pro<br/>výběr souborů.',
        ERRORS: {
            SIZE_LIMIT: 'Maxmimální povolená velikost všech souborů je:',
            FILE_TYPE: 'Povolené typy souborů jsou:',
            FILES_LIMIT: 'Maximální povolený počet souborů je:',
        },
    },
};
