import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-loading-circle',
    templateUrl: './loading-circle.component.html',
    styleUrls: ['./loading-circle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingCircleComponent implements OnInit {
    @Input() loading = true;
    @Input() size: 'lg' | 'md' | 'sm' | 'inline' = 'lg';

    constructor() {}

    ngOnInit(): void {}
}
