export const LAYOUT = {
    HEADER: {
        SUPPORT_TICKETS: 'Support tickety',
        ORDERS: 'Objednávky',
    },
    TOPBAR: {
        SEARCH: {
            SEARCHING: 'Vyhledávání...',
        },
        CART: {
            TITLE: 'Košík',
            NO_ITEMS: 'Košík je prázdný',
            SUM: 'Celkem',
            CURRENCY: '{{amount}} Kč',
            BUTTON_TO_CART: 'Přejít do košíku',
        },
        NOTIFICATIONS: {
            TITLE: 'Notifikace',
            ALERTS: 'Upozornění',
            NO_ALERTS: 'Žádná upozornění',
        },
    },
    FOOTER: {
        CONTACT: 'Kontakt',
        ABOUT_US: 'O nás',
        TERMS_AND_CONDITIONS: 'Obchodní podmínky',
    },
    USER_PROFILE: {
        SIGN_OUT: 'Odhlásit se',
        CREDIT: 'Kredit',
        CURRENCY: '{{amount}} Kč',
        BUTTON_TOP_UP_CREDIT: 'Dobít kredit',
        TITLE: 'Váš profil',
        NAV: {
            PROFILE: 'Můj profil',
            ORDERS: 'Mé objednávky',
            ACCESS: 'Přístupy',
        },
    },
};
