<app-text-input
    [inputControl]="inputControl"
    [placeholder]="placeholder | translate"
    [type]="type"
    [wide]="wide"
    [label]="label"
    [disabled]="disabled"
    [disableWhitespaceValidator]="disableWhitespaceValidator"
    [autocomplete]="autocomplete"
    [labelStyle]="labelStyle"
    [labelClass]="labelClass"
    [inputStyle]="inputStyle"
    [inputClass]="inputClass"
    [variant]="variant"
>
    <ng-template #rightContent>
        <button
            type="button"
            class="btn btn-light-info btn-icon"
            (click)="togglePasswordVisibility()"
        >
            <i
                class="far"
                [ngClass]="{ 'fa-eye ': type === 'password', 'fa-eye-slash ': type === 'text' }"
            ></i>
        </button>
    </ng-template>
    <ng-template #bottomContent *ngIf="bottomContentPassword">
        <ng-container *ngTemplateOutlet="bottomContentPassword"></ng-container>
    </ng-template>
</app-text-input>
