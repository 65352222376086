<div class="modal-header">
    <div class="modal-title h4">
        {{ "COMPONENTS.MODALS.ADD_SERVICE_TO_GROUP.TITLE" | translate }}
    </div>
    <button class="icon-button">
        <i class="flaticon2-cross" (click)="modal.dismiss()"></i>
    </button>
</div>
<div class="modal-body"></div>
<div class="modal-footer"></div>
