import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ModalDismissService {
    redirect$ = new Subject<void>();

    constructor() {}
}
