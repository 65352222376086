import { Component, Input, OnInit } from '@angular/core';
import { LoadingService } from '../../loading-bar/loading.service';

@Component({
    selector: 'app-loading-overlay',
    templateUrl: './loading-overlay.component.html',
    styleUrls: ['./loading-overlay.component.scss'],
})
export class LoadingOverlayComponent implements OnInit {
    constructor(public loadingService: LoadingService) {}

    ngOnInit(): void {}
}
