<div class="modal-header">
    <div class="modal-title h4">
        {{ title | translate }}
    </div>
    <button class="icon-button">
        <i class="flaticon2-cross" (click)="modal.dismiss()"></i>
    </button>
</div>
<div class="modal-body">
    <app-text-input [inputControl]="form.controls.name" label="GENERAL.INPUT.NAME"></app-text-input>
    <button
        type="submit"
        class="btn btn-primary"
        [disabled]="form.invalid"
        (click)="mySubmit.emit(form.value.name); modal.dismiss()"
    >
        {{ "GENERAL.BUTTONS.ADD" | translate }}
    </button>
</div>
