<app-input-wrapper
    [inputControl]="inputControl"
    [label]="label"
    [wide]="wide"
    [labelStyle]="labelStyle"
    [labelClass]="labelClass"
>
    <ng-select
        [clearable]="clearable"
        [formControl]="inputControl"
        [searchable]="searchable"
        [placeholder]="placeholder | translate"
        notFoundText="{{ 'GENERAL.SELECT.NO_ITEMS_FOUND' | translate }}"
        [ngClass]="{ 'is-invalid': iws.isControlInvalidAndTouched(inputControl) }"
        [style]="inputStyle"
        [class]="inputClass"
        [disabled]="disabled"
        (focus)="focusEvent.emit()"
    >
        <ng-option *ngFor="let option of selectOptions" [value]="option.value">
            <ng-container
                *ngTemplateOutlet="
                    optionTemplate ? optionTemplate : textOption;
                    context: { option: option }
                "
            >
            </ng-container>
        </ng-option>
    </ng-select>
</app-input-wrapper>

<ng-template #textOption let-option="option">
    {{ option.label | translate: option?.translateParams }}
</ng-template>
