<app-input-wrapper
    [inputControl]="inputControl"
    [label]="label"
    [wide]="wide"
    [labelStyle]="labelStyle"
    [labelClass]="labelClass"
>
    <div class="input-group">
        <input
            class="form-control"
            [attr.disabled]="disabled ? '' : null"
            [formControl]="inputControl"
            [placeholder]="placeholder | translate"
            ngbDatepicker
            #d="ngbDatepicker"
            (dateSelect)="onDateSelect($event)"
            [ngClass]="{ 'is-invalid': iws.isControlInvalidAndTouched(inputControl) }"
            [style]="inputStyle"
            [class]="inputClass"
        />
        <div class="input-group-append">
            <span class="input-group-text" (click)="d.toggle()">
                <i class="fas fa-calendar"></i>
            </span>
        </div>
    </div>
</app-input-wrapper>
