export const PERMISSIONS = {
    Identity: 'Identity',
    Users: 'Uživatelé',
    Create: 'Vytváření',
    Read: 'Čtení',
    Edit: 'Úpravy',
    Delete: 'Mazaní',
    Organizations: 'Organizace',
    ChangeOrganization: 'Změnit organizaci',
    Organization: 'Organizace',
    Groups: 'Skupiny',
    Group: 'Skupina',
    Services: 'Služby',
    Roles: 'Role',
    UserRoles: 'Uživatelské role',
    ShowAccess: 'Zobrazit přístupy',
    Subreg: 'Domény',
    Domains: 'Domény',
    Transfer: 'Převod',
    SetAutoRenew: 'Nastavit automatické obnovení',
    Renew: 'Obnovit',
    SendAuthId: 'Odeslat autorizační ID',
    GetAuthId: 'Získat autorizační ID',
    Contacts: 'Kontakty',
    Import: 'Importy',
    CatchDomains: 'Zachytávat domény',
    Credit: 'Kredit',
    CreditAlertLimit: 'Limit upozornění kreditu',
    Nssets: 'Nssety',
    Orders: 'Objednávky',
    Hostings: 'Hostingy',
    Hosting: 'Hosting',
    Logs: 'Logy',
    Backups: 'Zálohy',
    Restore: 'Obnovy',
    Crontab: 'Crony',
    Ftp: 'Ftp',
    Mysql: 'Mysql',
    Ssh: 'Ssh',
    Ssl: 'Ssl',
    WebAccess: 'Webový přístup',
    Mailhosting: 'Mailhosting',
    Mailboxes: 'Mailové schránky',
    Aliases: 'Aliasy',
    Forwards: 'Přesměrování',
    Proxmox: 'Virtuální servery',
    Vms: 'Virtuální servery',
    Power: 'Zapnutí',
    Console: 'Konzole',
    Tasks: 'Úkoly',
    CreateAdmin: 'Vytvořit administrátora',
    BusinessLogic: 'Obchodní logika',
    Currencies: 'Měny',
    Countries: 'Země',
    EmailSender: 'Odesílání emailů',
    SendAnyEmail: 'Odeslat jakýkoli email',
    SendServiceRequest: 'Odeslat žádost o službu',
    Dns: 'DNS',
    Zones: 'Zóny',
    Records: 'Záznamy',
    Billing: 'Účtování',
    Invoices: 'Faktury',
    TicketSystem: 'Ticketový systém',
    Tickets: 'Tickety',
    DetailSpecifications: 'Specifikace detailu',
    Documents: 'Přílohy',
};
