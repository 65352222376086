import { HOSTING_INFO } from './hostingInfo';
import { COMPARE_INFRASTRUCTURES } from './compareInfrastructures';
import { HYPER_V_INFO } from './hyperVInfo';

export const VPS = {
    INPUT: {
        VPS_NAME: 'Název VPS',
        VPS: {
            NAME: 'Název',
            DOMAIN: 'Doména',
            CORES_COUNT: 'Počet jader CPU',
            CORES: 'CPU',
            MEMORY: 'RAM',
            RAM: 'RAM',
            DISK: 'Disk',
            STATUS: 'Status',
            STATUSES: {
                UNKNOWN: 'Neznámý stav',
                stopped: 'Pozastaveno',
                running: 'Spuštěno',
                starting: 'Spouští se',
                stopping: 'Vypíná se',
            },
            IP_ADDRESS: 'IP adresa',
            OS: 'Operační systém',
            TYPE: 'Typ',
            SERVER_TYPE: 'Typ serveru',
        },
        TASK: {
            START_TIME: 'Začátek',
            END_TIME: 'Konec',
            TYPE: 'Typ',
            TYPES: {
                START: 'Zapínání',
                SHUTDOWN: 'Vypínání',
                REBOOT: 'Restartování',
                STOP: 'Zastavení',
                RESET: 'Resetování',
                VZDUMP: 'Zálohování',
                RESTORE: 'Obnova ze zálohy',
                UNKNOWN: 'Jiný úkon',
                CONSOLE: 'Konzole',
                CREATE: 'Vytvoření',
            },
            STATUS: 'Status',
            STARTED_BY: 'Spuštěno uživatelem {{user}}',
        },
        PENDING_TOOLTIP:
            'Parametr byl změněn, ale pro jeho projevení je server potřeba <b>vypnout</b> nebo <b>zastavit</b> a poté ho znovu zapnout.',
        ROOT_PASSWORD: 'Heslo root uživatele',
    },
    LIST: {
        TITLE: 'Seznam virtuálních serverů',
        CREATE_VPS: 'Vytvořit server',
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst seznam serverů',
        },
        WELCOME: {
            TITLE: 'Vítejte ve správě virtuálních serverů',
            DESC: 'Zatím nemáte žádný virtuální server.<br>Vytvořte si svůj první virtuální server právě teď',
        },
    },
    CREATE_AF: {
        TITLE: 'Vytvořit VPS',
        BUTTONS: {
            CREATE: 'Vytvořit',
        },
        MESSAGES: {
            SUCCESS: 'VPS byla úspěšně vytvořena',
            ERROR: 'Nepodařilo se vytvořit VPS',
        },
    },
    CREATE: {
        TITLE: 'Vytvořit server',
        MORE_INFO: 'Více informací',
        OWN_CONFIGURATION: 'Vlastní konfigurace',
        PACKAGE: 'Balíček',
        PREINSTALL_SYSTEM: 'Předinstalovat operační systém',
        PREINSTALL_SYSTEM_TOOLTIP:
            'Pokud si nebudete přát systém předinstalovat, bude vám namountován ISO soubor s příslušným OS a následně si můžete pomocí konzole systém sami nainstalovat.',
        ROOT_PASSWORD_TOOLTIP: 'Heslo bylo vygenerované automaticky. Můžete si jej změnit.',
        BUTTONS: {
            SHOW_VPS: 'Zobrazit VPS',
            CREATE_NEXT_VPS: 'Vytvořit další VPS',
            ADMIN_FORM: 'Admin rozhraní',
        },
        STEPS: {
            1: 'Systém',
            2: 'Parametry',
            3: 'Nastavení',
            4: 'Fakturační údaje',
            5: 'Dokončení',
        },
        INFRASTRUCTURE: {
            COMPARE_INFRASTRUCTURES: 'Porovnání infrastruktur',
            FOCUS: 'Zaměření',
            VPS: 'VPS',
            CLOUD: 'Cloud VPS',
            HYPER_V: 'VPS Hyper-V',
            LINUX: 'Linux',
            WINDOWS: 'Windows',
            HOSTING: 'VPS pro Hosting',
            VPS_STRUCTURE: 'Blokové schéma zapojení varianty VPS',
            CLOUD_VPS_STRUCTURE: 'Blokové schéma zapojení varianty Cloud VPS',
        },
        PACKAGES: {
            SELECT_PAYMENT_PLAN: 'Zvolte plán platby',
            ACTUAL_PACKAGE: 'Aktuální balíček',
            ACTIVATE_PACKAGE: 'Aktivovat balíček',
        },
        FINISH: {
            SALES: {
                1: 'Ročně',
                2: 'Kvartálně',
                3: 'Měsíčně',
            },
        },
        HOSTING_INFO,
        COMPARE_INFRASTRUCTURES,
        HYPER_V_INFO,
        MESSAGES: {
            SUCCESS: 'Server byl úspěšně vytvořen',
            ERROR: 'Nepodařilo se vytvořit server',
            ERROR_DESC: 'Bohužel, nepodařilo se vytvořit virtuální server.',
            LOADING: 'Vyčkejte prosím, vytváří se virtuální server',
            LOADING2: 'Tato operace může trvat několik minut',
            LOADING3: 'Toto je těžké, ale vydržte, prosím',
        },
        CREATING: 'Vytváření...',
    },
    VIEW: {
        SUBHEADER_TITLE: 'Detail serveru',
        MODAL: {
            TITLE: 'Potvrzení akce',
            MESSAGE: 'Opravdu chcete {{action}} virtuální server s názvem "{{vmName}}"?',
        },
        CONSOLE_VM_STOPPED_TOOLTIP: 'Pro připojení ke konzoli musíte nejdříve server zapnout.',
        BUTTONS: {
            CONSOLE: 'Konzole',
        },
        TABS: {
            SUMMARY: 'Přehled',
            STATS: 'Statistiky',
            BACKUPS: 'Zálohy',
            EDIT: 'Upravit',
        },
        MESSAGES: {
            ERROR_LOAD: 'Nepodařilo se načíst informace o serveru',
        },
        SUMMARY: {
            HEADERS: {
                BASIC_INFO: 'Základní informace',
                PARAMETERS: 'Parametry',
            },
            UPTIME: 'Doba běhu',
            ACTUAL_CPU_USAGE: 'Aktuální využití CPU',
            ACTUAL_MEMORY_USAGE: 'Aktuální využití paměti',
            DOMAIN: 'Přidělená doména',
            NETWORK_CARD: 'Síťová karta',
            BUTTONS: {
                CHANGE: 'Změnit',
                INCREASE: 'Navýšit',
            },
        },
        TASKS: {
            TITLE: 'Úkony',
        },
        STATS: {
            TIMEFRAME: {
                HOUR: 'Hodina',
                DAY: 'Den',
                WEEK: 'Týden',
                MONTH: 'Měsíc',
                YEAR: 'Rok',
            },
            CONSOLIDATION: {
                AVERAGE: 'Průměr',
                MAX: 'Maximum',
            },
            HEADERS: {
                CPU: 'Využití CPU',
                MEMORY: 'Využití paměti',
                NETWORK: 'Síťový provoz',
                DISK: 'Disk I/O',
            },
            LABELS: {
                CPU_USAGE: 'Využití CPU',
                MEMORY_TOTAL: 'Celková paměť',
                MEMORY_USAGE: 'Využití paměti',
                NET_IN: 'Net in',
                NET_OUT: 'Net out',
                DISK_READ: 'Disk read',
                DISK_WRITE: 'Disk write',
            },
            MESSAGES: {
                ERROR_LOAD: 'Nepodařily se načíst data grafu',
            },
        },
        BACKUPS: {
            NO_BACKUPS: 'Váš server zatím nemá žádné zálohy',
            CREATE: {
                TITLE: 'Vytvořit zálohu',
                MESSAGE: 'Opravdu chcete vytvořit zálohu serveru?"',
            },
            CONFIRMATION: {
                TITLE: 'Obnova zálohy',
                MESSAGE:
                    'Opravdu chcete obnovit server ze zálohy {{date}}?<br><span class="text-danger">Tímto příjdete o všechny akutální data na serveru</span>',
            },
            BUTTONS: {
                RESTORE: 'Obnovit',
                CREATE: 'Zálohovat server',
            },
            MESSAGES: {
                ERROR_LOAD: 'Nepodařilo se načíst zálohy',
                SUCCESS_RESTORE: 'Obnovení zálohy bylo úspěšně spuštěno',
                ERROR_RESTORE: 'Nepodařilo se spustit obnovu',
                SUCCESS_CREATE: 'Úspěšně byla zahájena záloha serveru',
                ERROR_CREATE: 'Nepodařilo se zahájit zálohu serveru',
            },
            TIME_CREATED: 'Čas vytvoření',
            SIZE: 'Velikost',
        },
        EDIT: {
            HEADERS: {
                HARDWARE_PARAMETERS: 'Parametry Hardware',
                DISKS: 'Disky',
                ADVANCED: 'Pokročilé',
            },
            NEW_DISK: 'Nový',
            DISKS_SUPPORT: 'Jestliže potřebujete smazat nebo zmenšit disk, kontaktujte podporu',
            BUTTONS: {
                ADD_DISK: 'Přidat disk',
                REMOVE_DISK: 'Odebrat disk',
                DISK_LIMIT_EXCEEDED: 'Maximální počet disků je 10',
            },
            MESSAGES: {
                SAVE_SUCCESS: 'Změny byly úspěšně uloženy',
                SAVE_ERROR: 'Nepodařilo se uložit změny',
            },
            DELETE: {
                MODAL: {
                    TITLE: 'Smazání virtuálního serveru',
                    MESSAGE:
                        'Opravdu chcete smazat VPS s názvem <b>{{name}}</b> na doméně {{domain}}?<br><span class="text-danger"><b>Tímto ztratíte všechna data na tomto serveru!</b></span>',
                },
                BUTTONS: {
                    DELETE: 'Smazat server',
                },
                MESSAGES: {
                    SUCCESS: 'Server byl úspěšně smazán',
                    ERROR: 'Nepodařilo se smazat server',
                },
            },
        },
        ACCESS: {
            TITLE: 'Uživatelé s přístupem k serveru',
            GROUPS: {
                TITLE: 'Skupiny, ve kterých je server',
            },
        },
        ADD_USER: {
            TITLE: 'Přidat uživatele k VPS',
            SUCCESS: 'Uživatel byl úspěšně přidán k VPS',
            ERROR: 'Nepodařilo se přidat uživatele k VPS',
        },
        EDIT_USER: {
            TITLE: 'Uživatel s přístupem k serveru',
        },
    },
    POWER_BUTTON: {
        STATES: {
            start: 'Zapnout',
            shutdown: 'Vypnout',
            stop: 'Zastavit',
            reset: 'Resetovat',
            reboot: 'Restartovat',
        },
        TOOLTIPS: {
            stop: 'Vytáhne VPS z elektriky',
            reset: 'Vytáhne VPS z elektriky s následným zapnutím',
            reboot: 'Restartuje VPS',
        },
    },
};
