import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-switch-input',
    templateUrl: './switch-input.component.html',
    styleUrls: ['./switch-input.component.scss'],
})
export class SwitchInputComponent implements OnInit {
    /** inputControl */
    @Input() inputControl: FormControl;
    /** Label for input */
    @Input() label: string = '';
    /** Disable input */
    @Input() disabled: boolean = false;
    /** wide change input behavior on big screens */
    @Input() wide: boolean = false;
    /**Styling input using angular [style] */
    @Input() labelStyle: Record<string, string | undefined | null>;
    /**Class inputs for customization  */
    @Input() labelClass: 'string';

    constructor() {}

    ngOnInit(): void {}
}
