import { Component } from '@angular/core';

/**
 * Main layout component for auth module
 */
@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {}
