import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GroupModel } from '../../../user-management/_models/group.model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { GroupService } from '../../../user-management/_services/group.service';
import { TableColumn } from '../../../ansea-table/_models/table.model';
import { CustomDataSource } from '../../../table/data-source';

@Component({
    selector: 'app-service-groups-table',
    templateUrl: './service-groups-table.component.html',
    styleUrls: ['./service-groups-table.component.scss'],
})
export class ServiceGroupsTableComponent implements OnInit {
    @Input() serviceId: string;
    dataSource: CustomDataSource<GroupModel> = new CustomDataSource<GroupModel>({
        clientSideMode: true,
    });
    columns: TableColumn[] = [
        {
            id: 'name',
            hideOrder: 0,
            label: 'AUTH.SERVICE_GROUPS.GROUP_NAME',
        },
        {
            id: 'actions',
            hideOrder: 1,
            label: 'GENERAL.INPUT.ACTIONS',
            disableSort: true,
        },
    ];
    filteredColumns = ['name'];

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    constructor(private groupService: GroupService) {}

    ngOnInit(): void {
        this.dataSource.createFetcher((f) =>
            this.groupService.getGroups({
                singleService: false,
                serviceId: this.serviceId,
            }),
        );
        this.dataSource.loadData();
    }
}
