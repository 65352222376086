export const USER_PROFILE = {
    SUBHEADER: 'Úprava profilu',
    NAV: {
        PERSONAL_INFORMATION: 'Osobní informace',
        CONTACT_ADDRESS: 'Kontaktní adresa',
        CHANGE_PASSWORD: 'Změna hesla',
        OTHER_SETTINGS: 'Ostatní nastavení',
        ACCESS: 'Přístupy',
    },
    MESSAGES: {
        SAVE_ERROR: 'Nepodařilo se uložit změny!',
    },
    PROFILE_CARD: {
        INPUT: {
            PHONE_NUMBER: 'Telefon',
            EMAIL: 'Email',
        },
    },
    PERSONAL_INFORMATION: {
        TITLE: 'Osobní informace',
        DESC: 'Upravte své osobní informace',
        HEADER_CONTACT: 'Kontakt',
        INPUT: {
            PHONE_NUMBER: 'Telefon',
            EMAIL: 'Email',
        },
    },
    CONTACT_ADDRESS: {
        TITLE: 'Kontaktní adresa',
        DESC: 'Upravte svoji kontaktní adresu',
    },
    CHANGE_PASSWORD: {
        TITLE: 'Změna hesla',
        DESC: 'Změňte si heslo účtu',
        BUTTONS: {
            CHANGE: 'Změnit',
            FORGOT_PASSWORD: 'Zapomněli jste heslo?',
        },
        INPUT: {
            CURRENT_PASSWORD: 'Aktuální heslo',
            NEW_PASSWORD: 'Nové heslo',
            C_NEW_PASSWORD: 'Nové heslo znovu',
        },
        CONFIRMATION: {
            TITLE: 'Potvrzení změny hesla',
            MESSAGE: 'Opravdu chcete změnit heslo?',
        },
        MESSAGES: {
            UNABLE_CHECK_CURRENT_PASSWORD: 'Nepodařilo se ověřit aktuální heslo!',
            SUCCESS_CHANGE_PASSWORD: 'Heslo bylo úspěšně změněno',
            FORM_NOT_VALID: 'Formulář není validní',
        },
    },
    OTHER_SETTINGS: {
        TITLE: 'Ostatní nastavení',
        DESC: 'Ostatní nastavení',
        EMAIL_ALREADY_CONFIRMED: 'Email uživatele je už potvrzen',
        INPUT: {
            BLOCKED: 'Uživatel zablokován',
            EMAIL_CONFIRMED: 'Email potvrzen',
            SEND_VERIFICATION_EMAIL: 'Znovu poslat ověřovací email',
            ROLES: 'Globální role',
        },
        MESSAGES: {
            VERIFICATION_EMAIL_RESENT: 'Ověřovací email byl znovu poslán.',
            UNABLE_RESEND_VERIFICATION_EMAIL: 'Nepodařilo se znovu poslat ověřovací email!',
            ERROR_LOAD_ROLES: 'Nepodařilo se načíst role',
            ERROR_SAVE_ROLES: 'Nepodařilo se uložit role',
            ERROR_LOAD_ORGANIZATIONS: 'Nepodařilo se načíst organizace',
            ERROR_SAVE_ORGANIZATIONS: 'Nepodařilo se uložit organizace',
        },
    },
    ACCESS: {
        TITLES: {
            ORGANIZATIONS: 'Práva uživatele k organizaci',
            GROUPS: 'Práva uživatele ke skupině',
            SERVICES: 'Služby vztažené k uživateli',
            IN_ORGANIZATION: 'V organizaci',
        },
        MESSAGES: {
            ROLE_SUCCESS: 'Podařilo se aktualizovat role uživatele',
            ROLE_ERROR: 'Nepodařilo se aktualizovat role uživatele',
            NO_GROUPS: 'Uživatel není člen žádné skupiny',
            NO_ORGANIZATIONS: 'Uživatel není člen žádné organizace',
        },
        NAV: {
            GROUPS: 'Skupiny',
            SERVICES: 'Služby',
        },
        SERVICES: {
            TABLE: {
                NAME: 'Název služby',
                TYPE: 'Typ služby',
                ROLES: 'Role',
            },
            DELETE: {
                TITLE: 'Smazat práva',
                MESSAGE: 'Opravdu chcete smazat práva uživatele ke službě "{{serviceName}}"?',
                SUCCESS: 'Práva uživatele byla smazána',
                ERROR: 'Nepodařilo se smazat práva uživatele',
            },
            EDIT: {
                SUCCESS: 'Práva byly aktualizovány',
                ERROR: 'Nepodařilo se aktualizovat práva',
            },
        },
        GROUPS: {
            TABLE: {
                GROUP: 'Skupina',
            },
        },
    },
};
