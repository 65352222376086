export const VALIDATION = {
    REQUIRED: 'Povinné pole',
    MAXLENGTH: 'Maximální délka je {{requiredLength}}',
    MINLENGTH: 'Minimální délka je {{requiredLength}}',
    MAX: 'Maximum je {{max}}',
    MIN: 'Minimum je {{min}}',
    FORMAT: 'Špatný formát',
    IS_VALID_DOMAIN: 'Špatný formát domény',
    EMAIL: 'Neplatný formát emailu',
    EXIST: 'Zadaný údaj již existuje',
    HAS_NUMBER: 'Musí obsahovat číslici',
    HAS_UPPER: 'Musí obsahovat velké písmeno',
    HAS_LOWER: 'Musí obsahovat malé písmeno',
    NO_PERMISSION: 'Vyberte alespoň jedno oprávnění',
    NO_ROLE: 'Vyberte alespoň jednu roli',
    NO_WHITESPACE: 'Nesmí obsahovat mezery',
    TRIM_WHITESPACE: 'Nesmí začínat nebo končit mezerou',
    CONFIRM_PASSWORD: 'Hesla se neshodují',
    EXISTS: 'Zadaný údaj již existuje',
    TYPE_REQUIRED: 'Musíte vybrat typ záznamu',
    CONTENT_REQUIRED: 'Zadejte obsah záznamu',
    NOT_VALID_IP: 'Nevalidní IP adresa',
    NOT_VALID_IPV6: 'Nevalidní IPv6 adresa',
    NOT_VALID_MX_RECORD:
        'MX záznam nebyl zadán správně. Správný formát je priorita (1 - 99), mezera a poté hostname',
    NOT_VALID_CNAME_RECORD: 'Neplatný obsah CNAME záznamu',
    NOT_VALID_ALIAS_RECORD: 'Neplatný obsah ALIAS záznamu',
    NOT_VALID_PTR_RECORD: 'Neplatný obsah PTR záznamu',
    NOT_VALID_SRV_RECORD: 'Neplatný obsah SRV záznamu',
    NOT_VALID_TXT_RECORD: 'Neplatný obsah TXT záznamu',
    IS_CHECKED: 'Toto pole musí být zaškrtnuto', //TODO: better translation
    INVALID: 'Neplatný údaj',
    ALLOWED_TLD: 'Toto TLD není povoleno',
    COPIES_EMAIL: 'Emaily musí být správně zapsány',
    EMAIL_USER_NOT_FOUND: 'Uživatel s tímto emailem neexistuje',
};
