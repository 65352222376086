import { MENU } from './menu';
import { AUTH } from './auth';
import { USER_MANAGEMENT } from './user-management';
import { USER_PROFILE } from './user-profile';
import { DOMAIN_MANAGEMENT } from './domain-management/domain-management';
import { DNS } from './dns';
import { LAYOUT } from './layout';
import { GENERAL } from './general';
import { HOSTINGS } from './hostings';
import { VPS } from './vps/vps';
import { COMPONENTS } from './components/components';
import { VALIDATION } from './validation';
import { DASHBOARD } from './dashboard';
import { PERMISSIONS } from './permissions';
import { BREADCRUMBS } from './breadcrumbs';
import { DATE_AND_TIME } from './date-and-time';
import { TICKET_SYSTEM } from './ticket-system';

export const locale = {
    lang: 'cs',
    data: {
        GENERAL,
        MENU,
        LAYOUT,
        AUTH,
        USER_MANAGEMENT,
        USER_PROFILE,
        DOMAIN_MANAGEMENT,
        DNS,
        HOSTINGS,
        VPS,
        COMPONENTS,
        VALIDATION,
        DASHBOARD,
        PERMISSIONS,
        BREADCRUMBS,
        DATE_AND_TIME,
        TICKET_SYSTEM,
    },
};
