import { Component, Input, OnInit } from '@angular/core';
import { CustomDataSource } from '../../../table/data-source';
import { ITableFiltration } from '../../_models/table.model';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-filtering',
    templateUrl: './filtering.component.html',
    styleUrls: ['./filtering.component.scss'],
})
export class FilteringComponent implements OnInit {
    @Input() dataSource: CustomDataSource<any>;
    @Input() filteredColumns: string[] | string = [];
    @Input() searchingIn: string = '';
    @Input() placeholder = 'GENERAL.INPUT.SEARCHING';
    filterControl: FormControl<string>;

    constructor() {}

    ngOnInit(): void {
        this.filterControl = new FormControl<string>('');
        this.filterControl.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
            this.applyFilter(value);
        });
    }

    applyFilter(value: string) {
        const filtration = {} as ITableFiltration;
        if (this.filteredColumns instanceof Array) {
            this.filteredColumns?.forEach((key) => {
                filtration[key] = value;
            });
        } else {
            filtration[this.filteredColumns] = value;
        }
        this.dataSource?.applyFilter(filtration);
    }
}
