export const COMPARE_INFRASTRUCTURES = {
    TITLE: 'Porovnání infrastruktur',
    COMPARE: `
    <table class='table'>
        <tbody>
        <tr>
            <th>Parametry VPS</th>
            <th>VPS</th>
            <th>Cloud VPS</th>
            <th>VPS HyperV</th>
        </tr>
        <tr>
            <td>Frekvence CPU</td>
            <td><strong>2,1 GHZ</strong></td>
            <td><strong>2,6 GHZ</strong></td>
            <td><strong>2,6 GHZ</strong></td>
        </tr>
        <tr>
            <td>KVM virtualizace na technologii Proxmox</td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
        </tr>
        <tr>
            <td>Garantovaná dostupnost 99,95%</td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
        </tr>
        <tr>
            <td>
                Garantovaná dostupnost 99,99% při monitoringu<br />
                a vybrané variantě SLA
            </td>
            <td><i class='flaticon2-cross text-danger'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
        </tr>
        <tr>
            <td>VPS řízeny HA clusterem – cloudová infrastruktura</td>
            <td><i class='flaticon2-cross text-danger'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
        </tr>
        <tr>
            <td>SSD disky</td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
        </tr>
        <tr>
            <td>HDD disky s CacheCade pro velkokapacitní obsah</td>
            <td><i class='flaticon2-cross text-danger'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
        </tr>
        <tr>
            <td>Hardwarová Anti DDoS ochrana</td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
        </tr>
        <tr>
            <td>Vytvoření snapshotu</td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
        </tr>
        <tr>
            <td>Zálohování dat do jiné lokality</td>
            <td><strong>3x týdně</strong></td>
            <td><strong>každý den</strong></td>
            <td><strong>každý den</strong></td>
        </tr>
        <tr>
            <td>1x IPv4 a /64 rozsah IPv6</td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
        </tr>
        <tr>
            <td>Konektivita</td>
            <td><strong>1 Gbps</strong></td>
            <td><strong>10 Gbps</strong></td>
            <td><strong>10 Gbps</strong></td>
        </tr>
        <tr>
            <td>Možnost správy VPS administrátory ANAFRA</td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
            <td><i class='flaticon2-check-mark text-success'></i></td>
        </tr>
        </tbody>
    </table>`,
};
