import {
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { WhitespaceValidator } from 'src/app/modules/validators/whitespace.validator';
import { InnerIconDirective } from '../../_directives/inner-icon.directive';
import { InputWrapperService } from '../../_services/input-wrapper.service';

@Component({
    selector: 'app-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit {
    /**Input variant */
    @Input() variant: 'default' | 'solid' = 'default'; // TODO implement variant to other inputs
    /** Hide error message, remove input bottom margin */
    @Input() hideErrorMessages: boolean = false;
    /** Placeholder changes input placeholder*/
    @Input() placeholder: string = '';
    /** Input type (text,password,number,etc...) */
    @Input() type: HTMLInputElement['type'] = 'text';
    /** wide change input behavior on big screens */
    @Input() wide: boolean = false;
    /** inputControl */
    @Input() inputControl: FormControl;
    /** Label for input */
    @Input() label: string = '';
    /** Disable input */
    @Input() disabled: boolean = false;
    /** Label for step */
    @Input() step: number;
    /** Label for min value */
    @Input() min: number;
    /** Label for max value */
    @Input() max: number;
    /** Disable whitespace validator */
    @Input() disableWhitespaceValidator: boolean = false;
    /** Disable Autocompletion
     * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
     */
    @Input() autocomplete: string | null | 'off' | 'new-password' = null;
    /**Styling input using angular [style] */
    @Input() inputStyle: Record<string, string | undefined | null>;
    @Input() labelStyle: Record<string, string | undefined | null>;
    /**Class inputs for customization  */
    @Input() inputClass: string;
    @Input() labelClass: string;

    @Output() focusEvent = new EventEmitter<void>();

    /** Optional input content on both sides of input */
    @ContentChild('leftContent', { read: TemplateRef }) leftContent: TemplateRef<any>;
    @ContentChild('rightContent', { read: TemplateRef }) rightContent: TemplateRef<any>;
    @ContentChild('bottomContent', { read: TemplateRef }) bottomContent: TemplateRef<any>;
    /**Directive for inner icon */
    @ContentChild(InnerIconDirective) innerIcon!: InnerIconDirective;

    constructor(public iws: InputWrapperService) {}

    ngOnInit(): void {
        if (this.inputControl && !this.disableWhitespaceValidator) {
            this.inputControl.addValidators(WhitespaceValidator.noTrimWhiteSpace);
            this.inputControl.updateValueAndValidity();
        }
    }
}
