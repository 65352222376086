import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { getContrastColor } from 'src/app/modules/_helpers/getContrastColor';
import { AnseaTableModule } from '../../../ansea-table/ansea-table.module';
import { RoleModel, RoleWithSourcesModel } from '../../../auth/_models/role.model';
import { RoleSource } from '../../../auth/_models/user-entity-roles.model';
import { InputWrappersModule } from '../../../forms/input-wrappers.module';
import { SearchFilterPipe } from '../../../pipes/search-filter.pipe';
import { ViewRoleModalComponent } from '../../roles/_components/view-role-modal/view-role-modal.component';

interface SelectionRoleModel {
    selected: boolean;
    disabled: boolean;
    role: RoleWithSourcesModel;
}

@Component({
    selector: 'app-select-roles-standalone',
    standalone: true,
    imports: [
        CommonModule,
        MatCheckboxModule,
        InputWrappersModule,
        SearchFilterPipe,
        NgbTooltipModule,
        TranslateModule,
        InlineSVGModule,
        AnseaTableModule,
    ],
    templateUrl: './select-roles-standalone.component.html',
    styleUrls: ['./select-roles-standalone.component.scss'],
})
export class SelectRolesStandaloneComponent implements OnInit {
    @Input() availableRoles: RoleModel[] = [];
    @Input() selectedRoleIds: string[] = [];
    @Input() otherRoleSources: RoleSource[] = [];
    searchControl: FormControl = new FormControl<string>('');
    selectionRoles: SelectionRoleModel[] = [];
    getContrastColor = getContrastColor;
    constructor(private modalService: NgbModal) {}

    ngOnInit(): void {
        this.initRoles();
    }

    initRoles() {
        this.selectionRoles = this.availableRoles.map((role) => {
            const otherSources = this.otherRoleSources.filter((rs) => rs.roleIds.includes(role.id));
            return {
                selected: otherSources.length > 0 || this.selectedRoleIds.includes(role.id),
                disabled: otherSources.length > 0,
                role: {
                    ...role,
                    sources: otherSources,
                },
            };
        });
    }

    toggleRole(role: RoleModel) {
        const index = this.selectionRoles.findIndex((selectionRole) => selectionRole.role === role);
        this.selectionRoles[index].selected = !this.selectionRoles[index].selected;
    }

    showRole(role: RoleModel) {
        const modalRef = this.modalService.open(ViewRoleModalComponent, {
            size: 'lg',
        });

        modalRef.componentInstance.role = role;
    }

    getSelectedRoles() {
        return this.selectionRoles
            .filter(
                (selectionRole) =>
                    selectionRole.selected &&
                    (!selectionRole.disabled ||
                        this.selectedRoleIds.includes(selectionRole.role.id)),
            )
            .map((selectionRole) => selectionRole.role);
    }
}
