<div class="d-flex flex-column flex-root h-100" id="kt_login_wrapper">
    <!--begin::Login-->
    <div
        class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        id="kt_login"
    >
        <!--begin::Aside-->
        <div class="login-aside d-flex flex-column flex-row-auto" style="background-color: #fff">
            <!--begin::Aside Top-->
            <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
                <!--begin::Aside header-->
                <a href="#" class="text-center mb-10">
                    <img
                        src="./assets/media/logos/logo-anafra-dark.png"
                        class="max-h-70px"
                        alt=""
                    />
                </a>
                <!--end::Aside header-->
            </div>
            <!--end::Aside Top-->

            <!--begin::Aside Bottom-->
            <div
                class="aside-img d-none flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center d-lg-flex"
                style="background-image: url('./assets/media/svg/illustrations/login-visual-1.svg')"
            ></div>
            <!--end::Aside Bottom-->
        </div>
        <!--begin::Aside-->

        <!--begin::Content-->
        <div
            class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
        >
            <!--begin::Content body-->
            <div class="d-flex flex-column-fluid flex-center">
                <router-outlet></router-outlet>
            </div>
            <!--end::Content body-->

            <!--begin::Content footer-->
            <div
                class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
            >
                <a
                    href="{{ 'GENERAL.LINKS.TERMS_AND_CONDITIONS' | translate }}"
                    target="_blank"
                    class="text-primary font-weight-bolder font-size-h5"
                    >{{ "AUTH.LAYOUT.TERMS_AND_CONDITIONS" | translate }}</a
                >
                <a
                    href="{{ 'GENERAL.LINKS.CONTACT_US' | translate }}"
                    target="_blank"
                    class="text-primary ml-10 font-weight-bolder font-size-h5"
                    >{{ "AUTH.LAYOUT.CONTACT" | translate }}</a
                >
            </div>
            <!--end::Content footer-->
        </div>
        <!--end::Content-->
    </div>
    <!--end::Login-->
</div>
