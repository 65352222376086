export const USER_MANAGEMENT = {
    INPUT: {
        FIRST_NAME: 'Křestní jméno',
        NAME: 'Jméno',
        LAST_NAME: 'Příjmení',
        EMAIL: 'Email',
        ROLES: 'Role',
        PHONE_NUMBER: 'Telefon',
        EMAIL_CONFIRMED: 'Ověření emailu',
        EMAIL_CONFIRMED_VALUES: {
            CONFIRMED: 'Ověřeno',
            NOT_CONFIRMED: 'Neověřeno',
        },
        STATUS: 'Status',
        STATUS_VALUES: {
            ACTIVE: 'Aktivní',
            BANNED: 'Zablokován',
        },
        ORGANIZATION: {
            NAME: 'Název',
        },
        ADD_USER: 'Přidat uživatele',
    },
    USERS: {
        SEARCHING: 'Vyhledávání',
        IN_SELECTED_COLUMNS: 've vybraných sloupcích',
        NEW_USER_BUTTON: 'Nový uživatel',
        ALL_COLUMNS: 'Všechny sloupce',
        MESSAGES: {
            ERROR_LOAD_USERS: 'Nepodařilo se načíst seznam uživatelů',
        },
    },
    ADD_USER: {
        TITLE: 'Přidání uživatele',
        DESC: 'Vytvořte nového uživatele',
        INPUT: {
            EDIT_USER_AFTER_SUBMIT: 'Pokračovat v editaci uživatele',
        },
        BUTTONS: {
            ADD_USER: 'Přidat uživatele',
            ADD_AND_EDIT: 'Přidat a zadat ostatní údaje',
        },
        MESSAGES: {
            CREATING_USER: 'Vytváření uživatele',
            UNABLE_TO_CREATE: 'Nepodařilo se vytvořit uživatele',
            CREATED: 'Uživatel vytvořen',
        },
    },
    EDIT_USER: {
        SUBHEADER: 'Úprava účtu',
        STEP_1: {
            TITLE: 'Profil',
            DESC: 'Osobní informace',
        },
        BUTTON_ADD: 'Přidat',
        BUTTON_EDIT: 'Upravit',
    },
    DELETE_USER: {
        TITLE: 'Smazání účtu',
        ARE_YOU_SURE: 'Opravdu chcete smazat "{{name}}" ? ',
        DELETING: 'Mazání účtu...',
        BUTTONS: {
            CANCEL: 'Zrušit',
            DELETE: 'Smazat',
        },
        MESSAGES: {
            DELETED: 'Účet smazán',
            UNABLE_TO_DELETE: 'Nepodařilo se smazat účet',
        },
    },
    ORGANIZATIONS: {
        NO_ORGANIZATION_FOUND_LABEL: 'Nebyly nalezeny žádné organizace',
        NO_ORGANIZATION_FOUND_MESSAGE: 'Z důvodu fakturace je třeba vytvořit organizaci',
        CONTACT_PERSON: {
            INFO: 'Kontakt organizace slouží pro spojení v případě problémů s fakturací či provozem služeb. Abychom mohli ještě rychleji reagovat, prosíme také o telefonní číslo, které můžete doplnit ',
            HERE: 'zde.',
            EMAIL: 'Email existujícího uživatele',
            LOAD_USER: 'Načíst uživatele',
            LOADED: 'Uživatel byl nahrán',
        },
        LEGAL_FORMS: {
            PrivatePerson: 'Soukromá osoba',
            NaturalPerson: 'Fyzická osoba',
            LegalPerson: 'Právnická osoba',
            NotDefined: 'Nedefinováno',
        },
        LIST: {
            SEARCHING: 'Vyhledávání',
            IN_ID: 'v ID',
            IN_NAME: 'v názvech',

            BUTTONS: {
                CREATE: 'Vytvořit organizaci',
            },
            MESSAGES: {
                ERROR_LOAD: 'Nepodařilo se načíst organizace',
            },
            DELETE: {
                TITLE: 'Smazat organizaci',
                MESSAGE: 'Opravdu chcete smazat organizaci "{{name}}"',
                SUCCESS: 'Organizace se smazala',
                ERROR: 'Nepodařilo se smazat organizaci',
            },
        },
        EDIT: {
            TITLE: 'Úprava organizace',
            BUTTONS: {
                LOAD_INFO_FROM_BUSINESS_REGISTER: 'Načíst data',
            },
            LOAD_INFO_FROM_BUSINESS_REGISTER_TOOLTIP: 'Načte data z obchodního rejstříku',

            MESSAGES: {
                ERROR_LOAD: 'Nepodařilo se načíst organizaci',
                ERROR_SAVE: 'Nepodařilo se uložit změny',
                SUCCESS: 'Organizace byla úspěšně upravena',
                ERROR_COMPANY_INFO: 'Nepodařilo se načíst informace o firmě',
            },
            SUBHEADER: 'Úprava organizace',
            NAV: {
                BILLING_INFORMATION: 'Fakturační údaje',
                CONTACT_PERSON: 'Kontaktní osoba',
                PAYMENT_METHODS: 'Platební metody',
                ORGANIZATION_USERS: 'Přístupy',
                ORGANIZATION_GROUPS: 'Skupiny',
                ROLES: 'Role',
                ORDERS: 'Objednávky',
                SERVICES: 'Služby',
            },
            BILLING_INFORMATION: {
                TITLE: 'Fakturační údaje',
                DESC: 'Upravte fakturační údaje',
                HEADERS: {
                    VAT_DATA: 'Daňové údaje - Česká republika',
                    BILLING_ADDRESS: 'Fakturační adresa',
                    ADVANCED: 'Pokročilé',
                },
                INPUT: {
                    NAME: 'Název společnosti',
                    PERSON: 'Jméno a příjmení',
                    LEGAL_FORM: 'Typ organizace',
                    COUNTRY: 'Země fakturace',
                    CURRENCY: 'Měna fakturace',
                    REGISTRATION_ID: 'IČO',
                    VAT_ID: 'DIČ',
                    CITY: 'Město (místo sídla)',
                    LANGUAGE: 'Komunikační jazyk',
                    LEGAL_FORMS: {
                        PRIVATE_PERSON: 'Soukromá osoba',
                        NATURAL_PERSON: 'Fyzická osoba',
                        LEGAL_PERSON: 'Právnická osoba',
                        NOT_DEFINED: 'Nedefinováno',
                    },
                },
            },
            CONTACT_PERSON: {
                TITLE: 'Kontaktní osoba',
                DESC: 'Upravte informace o kontaktní osobě',
                HEADERS: {
                    CONTACT: 'Kontakt',
                },
                INPUT: {
                    FIRST_NAME: 'Křestní jméno',
                    LAST_NAME: 'Příjmení',
                    PHONE: 'Telefon',
                    EMAIL: 'Email',
                },
            },
            PAYMENT_METHODS: {
                TITLE: 'Platební metody',
                DESC: 'Upravte platební metody',
            },
            ORGANIZATION_USERS: {
                TITLE: 'Přístupy',
                DESC: 'Mějte kontrolu nad tím, kdo může provádět akce nad vaší organizací',
                MESSAGES: {
                    USER_DELETED_SUCCESS: 'Uživatel byl úspěšně smazán z organizace',
                    USER_DELETED_ERROR: 'Uživatele se nepodařilo smazat z organizace',
                },
                NAV: {
                    ROLES: 'Role',
                },
                DELETE: {
                    TITLE: 'Smazat uživatele',
                    MESSAGE:
                        'Opravdu chcete smazat uživatele "{{firstName}} {{lastName}}" z organizace?',
                },
                ADD: {
                    TITLE: 'Přidat uživatele do organizace',
                    SUCCESS: 'Uživatel byl úspěšně přidán do organizace',
                    ERROR: 'Uživatele se nepodařilo přidat do organizace nebo v ní již existuje',
                },
                EDIT_ROLES: 'Spravovat role',
            },
            ROLES: {
                TITLE: 'Role',
                DESC: 'Upravte a nebo vytvořte nové role',
                MODAL: {
                    CREATE_ROLE_TITLE: 'Nová role',
                    CREATE_ROLE_DESC: 'Vytvořte novou roli',
                    EDIT_ROLE_TITLE: 'Úprava role',
                    EDIT_ROLE_DESC: 'Upravte roli',
                    SHOW_ROLE_TITLE: 'Detail role',
                    SHOW_ROLE_DESC: 'Zobrazuje všechny oprávnění role',
                },
                MESSAGES: {
                    ROLE_CREATED_SUCCESS: 'Role byla úspěšně vytvořena',
                    ROLE_CREATED_ERROR: 'Role se nepodařilo vytvořit',
                    ROLE_UPDATED_SUCCESS: 'Role byla úspěšně upravena',
                    ROLE_UPDATED_ERROR: 'Role se nepodařilo upravit',
                    ROLE_PERMISSIONS_REQUIRED: 'Vyberte prosím alespoň jednu oprávnění',
                },
                LIST: {
                    DELETE: {
                        TITLE: 'Opravdu chcete smazat roli?',
                        MESSAGE: 'Opravdu chcete smazat roli "{{name}}"?',
                        SUCCESS: 'Role byla úspěšně smazána',
                        ERROR: 'Roli se nepodařilo smazat',
                    },
                },
                TREE: {
                    ROLE_NAME: 'Název role',
                    COLOR: 'Barva',
                    PERMISSIONS: 'Oprávnění',
                    PERMISSIONS_DESC: 'Vyberte oprávnění, která chcete přidat do role',
                    SHOW_PERMISSIONS_RELATED_TO_SERVICE:
                        'Zobrazit oprávnění, která jsou související s službou',
                },
                BUTTONS: {
                    CREATE_ROLE: 'Vytvořit roli',
                    EDIT_ROLE: 'Upravit roli',
                },
            },
            ORGANIZATION_GROUPS: {
                TITLE: 'Skupiny',
                DESC: 'Spravujte skupiny v organizaci',
                MANAGE_GROUPS: 'Spravovat skupiny',
                NAV: {
                    SERVICES: 'Služby',
                },
                TABLE: {
                    NAME: 'Název skupiny',
                },
                DELETE: {
                    TITLE: 'Smazat skupinu',
                    MESSAGE: 'Opravdu chcete smazat skupinu "{{groupName}}"?',
                    SUCCESS: 'Skupina byla úspěšně smazána',
                    ERROR: 'Skupinu se nepodařilo smazat',
                },
                ADD: {
                    TITLE: 'Vytvořit skupinu',
                    SUCCESS: 'Skupina byla úspěšně přidána',
                    ERROR: 'Skupinu se nepodařilo přidat',
                },
            },
            GROUP_USERS: {
                TITLE: 'Uživatelé ve skupině',
                TABLE: {
                    FIRST_NAME: 'Jméno',
                    LAST_NAME: 'Příjmení',
                    ORGANIZATION_ROLE_NAMES: 'Role v organizaci',
                    GROUP_ROLE_NAMES: 'Role ve skupině',
                },
                DELETE_USER: {
                    TITLE: 'Smazat uživatele',
                    MESSAGE:
                        'Opravdu chcete smazat uživatele "{{firstName}} {{lastName}}" ze skupiny "{{groupName}}"?',
                    SUCCESS: 'Uživatel byl úspěšně odstraněn ze skupiny',
                    ERROR: 'Uživatele se nepodařilo odstranit ze skupiny',
                },
                EDIT_USER_ROLES: {
                    SUCCESS: 'Role byly upraveny',
                    ERROR: 'Role se nepodařilo upravit',
                    TITLE: 'Upravit role uživatele "{{firstName}} {{lastName}}" ve skupině "{{groupName}}"',
                },
                ADD: {
                    TITLE: 'Přidat uživatele do skupiny',
                    SUCCESS: 'Uživatel byl úspěšně přidán do skupiny',
                    ERROR: 'Uživatele se nepodařilo přidat do skupiny nebo v ní již existuje',
                },
            },
            GROUP_SERVICES: {
                TITLE: 'Služby vztažené ke skupině',
                TABLE: {
                    NAME: 'Název služby',
                    TYPE: 'Typ služby',
                },
                DELETE_SERVICE: {
                    TITLE: 'Smazat službu',
                    MESSAGE:
                        'Opravdu chcete smazat službu "{{serviceName}}" ze skupiny "{{groupName}}?',
                    SUCCESS: 'Služba byla úspěšně odstraněna ze skupiny',
                    ERROR: 'Služby se nepodařilo odstranit ze skupiny',
                },
                ADD: {
                    BUTTON: 'Přidat služby',
                    TITLE: 'Přidat služby do skupiny',
                    SERVICE_TYPE: 'Typ služby',
                    SUCCESS: 'Služby byly přidány do skupiny',
                    ERROR: 'Služby se nepodařilo přidat do skupiny',
                },
            },
            ORGANIZATION_ROLES: {
                TITLE: 'Vlastní role',
                DESC: 'Spravute role v organizaci',
                DELETE: {
                    TITLE: 'Smazat roli',
                    MESSAGE: 'Opravdu chcete smazat roli "{{roleName}}"?',
                    SUCCESS: 'Role byla úspěšně smazána',
                    ERROR: 'Roli se nepodařilo smazat',
                },
                SAVE: {
                    SUCCESS: 'Změny byly uloženy',
                    ERROR: 'Některé změny se nepodařilo uložit',
                },
            },
            ORGANIZATION_SERVICES: {
                TITLE: 'Služby',
                DESC: 'Spravujte služby organizace',
            },
        },
        ORDERS: {
            TITLE: 'Objednávky',
            DESC: 'Spravujte objednávky',
            TABLE: {
                ID: 'Číslo objednávky',
                ESTABLISHED: 'Datum vytvoření',
            },
        },
        CREATE: {
            TITLE: 'Vytvořit organizaci',
            DESC: 'Vytvořte novou organizaci',
            MESSAGES: {
                LOADING: 'Organizace se vytváří',
                SUCCESS: 'Organizace úspěšně vytvořena',
                CREATE_FINISHED: 'Organizace {{name}} byla vytvořena',
                ERROR: 'Nepodařilo se vytvořit organizaci',
                ERROR_DESC: 'Organizace {{name}} nebyla vytvořena.',
            },
            BUTTONS: {
                SHOW_ORGANIZATION: 'Zobrazit organizaci',
            },
            STEPS: {
                1: 'Subjekt',
                2: 'Fakturační adresa',
                3: 'Kontaktní osoba',
                4: 'Dokončení',
            },
        },
    },
    EDIT_ROLES: {
        TITLE: 'Upravit role',
        DESC: 'Upravte globální a defaultní role',
    },
    LIST_GROUPS: {
        TITLE: 'Skupiny',
        ERROR_LOAD: 'Nepodařilo se načíst skupiny.',
    },
};
