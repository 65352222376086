import { DOMAINS } from './domains';
import { CONTACTS } from './contacts';
import { ORDERS } from './orders';
import { CATCH_DOMAINS } from './catch-domains';
import { NSSETS } from './nssets';
import { CREDIT_ALERT_LIMIT } from './credit-alert-limit';

export const DOMAIN_MANAGEMENT = {
    SEARCHING: 'Vyhledávání podle',
    IN_ALL_COLUMNS: 'všech sloupců',
    BUTTONS: {
        RENEW: 'Prodloužit',
        RENEW_DOMAINS: 'Prodloužit zvolené domény',
        RELOAD_DATA: 'Obnovit data',
    },
    INPUT: {
        DOMAIN: {
            NAME: 'Název domény',
            EXPIRE: 'Expirace',
            CONTACT: 'Kontakt',
            REGISTRANT_CONTACT: 'Držitel domény',
            ADMIN_CONTACT: 'Administrační kontakt',
            YEARS_COUNT: 'Počet let',
            NAME_SERVERS: 'Nameservery',
            NSSET: 'NSSET',
            DNS: 'DNS',
            HOSTING: 'Hosting',
            DATE_OF_REGISTRATION: 'Datum registrace',
            DATE_OF_LAST_CHANGE: 'Datum poslední změny',
            DATE_OF_TRANSFER: 'Datum převodu',
            DATE_OF_EXPIRATION: 'Datum expirace',
            AUTO_RENEW: 'Automatické prodloužení',
            AUTH_PASSWORD: 'Autorizační heslo',
            DOMAIN_LANGUAGE: 'Jazyk domény',
            WHOIS_PROXY: 'Skrýt kontakt ve whois',
            VAT_ID_PASSPORT: 'DIČ/Číslo pasu',
        },
        CONTACT: {
            ID: 'ID',
            NAME: 'Jméno',
            SURNAME: 'Příjmení',
            ORGANIZATION: 'Subreg organizace',
            CITY: 'Město',
            STREET: 'Ulice a ČP',
            STATE: 'Stát',
            EMAIL: 'Email',
            PHONE: 'Telefon',
            NIC_ID: 'NIC ID',
            NOTIFICATION_EMAIL: 'Email pro oznámení',
            VAT: 'DIČ',
            ID_TYPE: 'Typ ID',
            ID_TYPES: {
                OP: 'Občanka',
                PASSPORT: 'Cestovní pas',
                MPSV: 'Ministerstvo práce a sociálních věcí',
                ICO: 'IČO',
                BIRTHDAY: 'Datum narození',
            },
            ID_NUMBER: 'Číslo ID',
            PUBLIC_DATA: 'Veřejná data',
            PUBLIC_DATA_CHECKBOXES: {
                VOICE: 'Telefon',
                EMAIL: 'Email',
                VAT: 'Dič',
                IDENT: 'ID',
                NOTIFY_EMAIL: 'Email pro oznámení',
            },
        },
        ORDER: {
            ORDER_ID: 'ID objednávky',
            DATE: 'Datum',
            CREATE_DATE: 'Datum vytvoření',
            LAST_CHANGE_DATE: 'Datum poslední změny',
            SUBJECT: 'Subjekt',
            PAYED: 'Zaplacená',
            TYPE: 'Typ',
            TYPE_VALUES: {
                Create_Domain: 'Registrace domény',
                Modify_Domain: 'Úprava domény',
                Renew_Domain: 'Prodloužení domény',
                Transfer_Domain: 'Převod domény',
                Delete_Domain: 'Smazání domény',
                Restore_Domain: 'Obnovení domény',
                Transfer_Object: 'Převod objektu',
                Import_Object: 'Import objektu',
            },
            STATUS: 'Status',
            STATUS_VALUES: {
                NEW: 'Nová',
                COMPLETED: 'Dokončená',
                FAILED: 'Nepodařená',
                PROCESSING: 'Zpracovávání',
                PENDING: 'Čekání na zpracování',
                PENDING_AUTHORIZATION: 'Čekání na potvrzení emailu',
                PENDING_REGISTRY: 'Čekání na zpracování v registru',
                WAIT_FOR_PAYMENT: 'Čekání na platbu',
                WAIT_FOR_DOCUMENTS: 'Čekání na dokumenty',
            },
            MESSAGE: 'Zpráva',
        },
        NSSET: {
            NAME: 'Jméno',
            HOSTNAMES: 'Nameservery',
        },
        CATCH_DOMAINS: {
            NAME: 'Název domény',
            INTERVAL: 'Interval',
            CREATED: 'Datum vytvoření',
            ACTIVE: 'Aktivní',
        },
        AUTH_ID: 'AUTH-ID',
        CREDIT: 'Kredit',
        CREDIT_THRESHOLD: 'Blokováno',
    },
    DOMAINS,
    CONTACTS,
    ORDERS,
    CATCH_DOMAINS,
    NSSETS,
    CREDIT_ALERT_LIMIT,
    TRANSFER_IMPORT_FORM: {
        TRANSFER: 'Převést',
        IMPORT: 'Importovat',
    },
};
